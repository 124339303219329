
import React, { useEffect, useRef, useState } from "react";
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import VuiBox from "components/VuiBox";
import HitchTypography from "components/HitchTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import { useHubConnections } from "context/HubConnectionsProvider";
import VuiButton from "components/VuiButton";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import GitHubRuleConditions from "./GitHubRuleConditions";
import GitHubRuleActions from "./GitHubRuleActions";
import GitHubRuleDetailsHeader from "./GitHubRuleDetailsHeader";
import GitHubRuleDefinition from "./GitHubRuleDefinition";

function GitHubRuleDetails() {
    const { ruleId } = useParams();
    const navigate = useNavigate();
    const [rule, setRule] = useState({});
    const [conditions, setConditions] = useState([]);
    const [actions, setActions] = useState([]);
    const [features, setFeatureList] = useState([]);
    const [operations, setOperationList] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [currentCondition, setCurrentCondition] = useState({});
    const [editMode, setEditMode] = useState(false);
    const gitHubService = 'c5e92791-b9e7-4d7e-ae22-90cce4ec0b43';
    const [selectedFeatureId, setSelectedFeatureId] = useState('');

    const { dashboardConnection, hitchDashCommand, hitchUser } = useHubConnections();
    const formikRef = useRef(null);

    function saveRule(values) {
        if (values) {
            const updatedRule =
            {
                ruleId: ruleId,
                theRule: {
                    ...rule,
                    name: values.name,
                    featureId: values.featureId,
                    operationId: values.operationId,
                    isActive: values.isActive,
                    ruleExpression: values.ruleExpression,
                    conditions,
                    actions,
                }
            };

            console.group("!!!!!!! SAVING !!!!!!!");

            console.log('saving', updatedRule);

            console.groupEnd();

            hitchDashCommand('github', 'webhook', 'update-rule', updatedRule, () => {
                setEditMode(false);
            });
        } else {
            const updatedRule =
            {
                ruleId: ruleId,
                theRule: {
                    ...rule,
                    conditions,
                    actions,
                }
            };

            console.group("!!!!!!! SAVING !!!!!!!");

            console.log('saving', updatedRule);

            console.groupEnd();

            hitchDashCommand('github', 'webhook', 'update-rule', updatedRule, () => {
                setEditMode(false);
            });
        }
    }


    useEffect(() => {
        if(conditions && conditions.length > 0) {
            saveRule(null);
        }
    }, [conditions]);

    useEffect(() => {
        if (ruleId === "new") {
            const newRuleId = uuidv4();
            navigate(`/third/HC5E92791_B9E7_4D7E_AE22_90CCE4EC0B43/GitHubRules/${newRuleId}`);
        }
    }, [ruleId, navigate]);

    useEffect(() => {
        if (rule) {
            console.log('rule', rule);
        }
    }, [rule]);

    useEffect(() => {
        if (dashboardConnection && ruleId !== "new") {
            const subscribeToEvents = () => {
                dashboardConnection.on("51b13dd8-3c42-43ec-9dcc-f90504dfab90", incomingRule);
                dashboardConnection.on("f3f9e1a1-6d9c-4010-9424-dc63a42f1762", incomingRule);
                dashboardConnection.on("72d23de7-efb6-45c1-b67a-e06bd00d4739", incomingRoutes);

                hitchDashCommand("github", "webhook", "get-rule", { ruleId });

                hitchDashCommand('account', 'connection', 'route', {
                    theAccountId: hitchUser?.accountId,
                    theServiceId: gitHubService,
                });
            };

            subscribeToEvents();

            return () => {
                dashboardConnection.off("51b13dd8-3c42-43ec-9dcc-f90504dfab90", incomingRule);
                dashboardConnection.off("f3f9e1a1-6d9c-4010-9424-dc63a42f1762", incomingRule);
                dashboardConnection.off("72d23de7-efb6-45c1-b67a-e06bd00d4739", incomingRoutes);
            };
        }
    }, [dashboardConnection, ruleId, hitchDashCommand]);

    function incomingRule(data) {
        if (data === "Not Found" || data === "An Error has occurred") {
            const newRule = {
                id: ruleId,
                accountId: hitchUser?.accountId,
                serviceId: gitHubService,
                isActive: false,
                ruleExpression: '',
                ruleX: 0,
                ruleY: 0,
                conditions: [],
                actions: [],
            };
            setRule(newRule);
            if (conditions.length === 0) setConditions([]);
            if (actions.length === 0) setActions([]);
            return;
        }

        setRule(data);
        if (conditions.length === 0) setConditions(data.conditions || []);
        if (actions.length === 0) setActions(data.actions || []);
    }

    function incomingRoutes(data) {
        const uniqueFeatures = [
            ...new Map(data.map((item) => [item.featureId, item])).values(),
        ];

        setFeatureList(
            uniqueFeatures.map((feature) => ({
                label: feature.operationType,
                value: feature.featureId,
            }))
        );

        if (selectedFeatureId) {
            const operationsForCurrentFeature = data.filter(
                (operation) => operation.featureId === selectedFeatureId
            );

            setOperationList(
                operationsForCurrentFeature.map((operation) => ({
                    label: operation.operationSubtype || "Unnamed Operation",
                    value: operation.operationId,
                }))
            );
        } else {
            setOperationList([]);
        }
    }

    useEffect(() => {
        if (rule?.featureId) {
            setSelectedFeatureId(rule.featureId);

            hitchDashCommand('account', 'connection', 'route', {
                theAccountId: hitchUser?.accountId,
                theServiceId: gitHubService,
                theFeatureId: rule.featureId,
            });
        } else {
            hitchDashCommand('account', 'connection', 'route', {
                theAccountId: hitchUser?.accountId,
                theServiceId: gitHubService,
            });
        }
    }, [rule?.featureId, hitchUser?.accountId, gitHubService, hitchDashCommand]);

    function handleAddCondition() {
        const newCondition = {
            id: uuidv4(),
            accountId: rule.accountId,
            ruleId: rule.id,
            name: "",
            conditionExpression: "",
        };

        setConditions((prev) => [...prev, newCondition]);
        setCurrentCondition(newCondition);
        setModalOpen(true);
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <VuiBox pt={6} pb={3}>
                <VuiBox mb={3}>
                    <Card>
                        <GitHubRuleDetailsHeader />
                        
                        <GitHubRuleDefinition
                            editMode={editMode}
                            formikRef={formikRef}
                            gitHubService={gitHubService}
                            rule={rule}
                            saveRule={saveRule}
                            setEditMode={setEditMode}
                            setSelectedFeatureId={setSelectedFeatureId}
                        />
                    </Card>
                </VuiBox>

                <GitHubRuleConditions
                    conditionList={conditions}
                    setConditionList={setConditions}
                    accountId={rule?.accountId}
                    ruleId={rule?.id}
                />

                <GitHubRuleActions
                    actionList={actions}
                    setActionList={setActions}
                    accountId={rule?.accountId}
                    ruleId={rule?.id}
                />

                {modalOpen && (
                    <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
                        <Box sx={{ p: 4, background: "white", margin: "auto", width: "50%" }}>
                            <HitchTypography variant="h5">
                                {currentCondition?.name ? "Edit Condition" : "Add Condition"}
                            </HitchTypography>
                            <VuiButton
                                onClick={() => setModalOpen(false)}
                                variant="contained"
                                color="primary"
                            >
                                Close
                            </VuiButton>
                        </Box>
                    </Modal>
                )}
            </VuiBox>
        </DashboardLayout>
    );
}

export default GitHubRuleDetails;

import VuiBox from "components/VuiBox";
import HitchTypography from "components/HitchTypography";
import VuiButton from "components/VuiButton";

function Header() {
  return (
    <VuiBox
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      sx={({ breakpoints }) => ({
        flexDirection: "column",
        alignItems: "start",
        [breakpoints.up("md")]: {
          flexDirection: "row",
          alignItems: "center",
        },
      })}
    >
      <VuiBox
        sx={({ breakpoints }) => ({
          mb: "20px",
          [breakpoints.up("md")]: {
            mb: "0px",
          },
        })}
      >
        <VuiBox mb={1}>
          <HitchTypography variant="h4" fontWeight="bold" color="white">
            Order Details
          </HitchTypography>
        </VuiBox>
        <HitchTypography
          component="p"
          variant="button"
          fontWeight="regular"
          color="text"
          display="flex"
        >
          Order no.
          <HitchTypography
            mx="5px"
            component="p"
            variant="button"
            fontWeight="regular"
            color="white"
            sx={{ fontWeight: "bold" }}
          >
            241342
          </HitchTypography>{" "}
          from
          <HitchTypography
            ml="5px"
            component="p"
            variant="button"
            fontWeight="regular"
            color="white"
            sx={{ fontWeight: "bold" }}
          >
            23.02.2021
          </HitchTypography>{" "}
        </HitchTypography>
        <HitchTypography
          component="p"
          variant="button"
          fontWeight="regular"
          color="text"
          sx={{ display: "flex" }}
        >
          Code:{" "}
          <HitchTypography
            ml="5px"
            component="p"
            variant="button"
            fontWeight="regular"
            color="white"
            sx={{ fontWeight: "bold" }}
          >
            {" "}
            KF332
          </HitchTypography>
        </HitchTypography>
      </VuiBox>
      <VuiButton
        variant="outlined"
        color="white"
        width="100px"
        sx={({ palette: { white } }) => ({
          border: `1px solid ${white.main}`,
          color: white.main,
        })}
      >
        invoice
      </VuiButton>
    </VuiBox>
  );
}

export default Header;

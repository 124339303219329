
// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/components/HitchRadioGroup/index.js

import PropTypes from "prop-types";
import { Field, ErrorMessage } from "formik";
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import VuiBox from "components/VuiBox";
import HitchTypography from "components/HitchTypography";

function HitchRadioGroup({ label, name, options, ...rest }) {
  return (
    <VuiBox mb={1.5}>
      <FormControl component="fieldset">
        <FormLabel component="legend">
          <HitchTypography
            component="label"
            variant="caption"
            color="white"
            fontWeight="bold"
            textTransform="capitalize"
          >
            {label}
          </HitchTypography>
        </FormLabel>
        <Field name={name}>
          {({ field }) => (
            <RadioGroup
              {...field}
              {...rest}
              aria-label={label}
              name={name}
            >
              {options.map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio />}
                  label={option.label}
                />
              ))}
            </RadioGroup>
          )}
        </Field>
        <VuiBox mt={0.75}>
          <HitchTypography component="div" variant="caption" color="error">
            <ErrorMessage name={name} />
          </HitchTypography>
        </VuiBox>
      </FormControl>
    </VuiBox>
  );
}

HitchRadioGroup.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default HitchRadioGroup;


// FILE: /UI/hitch-ai-ui-2/src/examples/Tables/DataTable/DataTableBodyCell.js

import PropTypes from "prop-types";
import VuiBox from "components/VuiBox";
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";

function DataTableBodyCell({ noBorder = false, align = "left", children }) {
  const { light, grey } = colors;
  const { size } = typography;
  const { borderWidth } = borders;

  return (
    <VuiBox
      component="td"
      textAlign={align}
      fontSize={size.sm}
      borderBottom={noBorder ? "none" : `${borderWidth[1]} solid ${grey[700]}`}
      py={1.5}
      px={3}
    >
      <VuiBox
        display="inline-block"
        width="max-content"
        color="text"
        sx={{ verticalAlign: "middle" }}
      >
        {children}
      </VuiBox>
    </VuiBox>
  );
}

// Typechecking props for the DataTableBodyCell
DataTableBodyCell.propTypes = {
  children: PropTypes.node.isRequired,
  noBorder: PropTypes.bool,
  align: PropTypes.oneOf(["left", "right", "center"]),
};

export default DataTableBodyCell;

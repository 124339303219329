import { Collapse, List, ListItem, ListItemText } from "@mui/material";
import { useState } from "react";
import VuiBox from "components/VuiBox";
import BasicPieChart from "examples/Charts/PieChart/BasicPieChart";
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import IconButton from '@mui/material/IconButton';

function TreeNode({ node, onNodeClick, level = 0 }) {
    const [open, setOpen] = useState(false);

    const handleToggle = () => {
        setOpen(!open);
    };

    // Prepare pie chart data and options
    const pieChartData = [node?.score || 0, (node?.maxScore || 1) - (node?.score || 0)];
    const pieChartOptions = {
        labels: ["Compliant", "Non-Compliant"],
        colors: ["#004545", "#222222"],
        legend: {
            show: false,
        },
        tooltip: {
            enabled: false, // Disable the tooltip
        },
    };

    const handleItemClick = () => {
        onNodeClick(node); // Call the function passed from the parent with the current node
    };

    return (
        <>
            <ListItem 
                button 
                onClick={handleItemClick} // Only call onNodeClick when item is clicked
                sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    paddingLeft: `${level * 20}px` // Indent based on the level
                }}
            >
                {node?.children?.length > 0 && (
                    <IconButton onClick={handleToggle} size="small">
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                )}
                <VuiBox sx={{ 
                    width: "100px", 
                    height: "100px", 
                    minWidth: "50px", 
                    marginRight: "10px", 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center' // Center the pie chart horizontally
                }}>
                    <BasicPieChart
                        pieChartData={pieChartData}
                        pieChartOptions={pieChartOptions}
                    />
                </VuiBox>
                <ListItemText
                    primary={node.name}
                    secondary={`Score: ${node.score}/${node.maxScore}`}
                />
            </ListItem>
            {node.children.length > 0 && (
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {node.children.map(child => (
                            <TreeNode key={child.id} node={child} onNodeClick={onNodeClick} level={level + 1} /> // Increment level for children
                        ))}
                    </List>
                </Collapse>
            )}
        </>
    );
}

export default TreeNode;
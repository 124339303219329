
// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/hitch/hitch-admin/uicomponents/UiComponentDetail/index.js

import { Card, Grid } from "@mui/material";
import HitchDisplayField from "components/HitchDisplayField";
import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";
import HitchTypography from "components/HitchTypography";
import { useHubConnections } from "context/HubConnectionsProvider";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import { Form, Formik } from "formik";
import FormField from "layouts/pages/users/new-user/components/FormField";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

function UiComponentDetail() {

    const { componentId } = useParams();
    const [component, setComponent] = useState({});
    const [editMode, setEditMode] = useState(false);
    const {
        dashboardConnection,
        hitchDashCommand,
    } = useHubConnections();

    var navigate = useNavigate();

    function saveService(values) {
        console.log('save', values);

        const theComponent = {
            theComponent: {
                id: component.id,
                created: component.created,
                createdBy: component.createdBy,
                updated: component.updated,
                updatedBy: component.updatedBy,
                name: values.name,
                path: values.path,
                sourcePath: values.sourcePath,
                routePath: values.routePath
            }
        };

        console.log('Saving the following UI Component Data', theComponent);
        hitchDashCommand('admin', 'uicomponent', 'update', theComponent, () => {
            setEditMode(false);
        });
    }

    useEffect(() => {
        if (dashboardConnection && componentId) {
            const subscribeToEvents = () => {

                console.log('subscribing to events!');

                dashboardConnection.on("1e2fdea1-59c4-42d4-9bb0-f0b6aef7b214", (data) => {
                    if (data === "An Error has occurred.") {
                        setComponent({
                            id: componentId
                        });
                        setEditMode(true);
                    } else {
                        console.log('1e2fdea1-59c4-42d4-9bb0-f0b6aef7b214', data);
                        setComponent(data);
                    }
                    // setService(data);
                });
                // Add other event subscriptions here

                hitchDashCommand('admin', 'uicomponent', 'get', { componentId: componentId });
            };

            subscribeToEvents();

            return () => {
                dashboardConnection.off('1e2fdea1-59c4-42d4-9bb0-f0b6aef7b214');
            };
        }
    }, [dashboardConnection, componentId]);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <VuiBox py={3}>
                <Card
                    sx={({ functions: { linearGradient }, palette: { gradients } }) => ({
                        overflow: "visible",
                        background: linearGradient(
                            gradients.cardDark.main,
                            gradients.cardDark.state,
                            gradients.cardDark.deg
                        ),
                    })}
                >
                    <VuiBox p={3}>
                        <VuiBox mb={5}>
                            <HitchTypography variant="lg" fontWeight="medium" color="white">
                                UI Component: {component?.name}
                            </HitchTypography>
                        </VuiBox>

                        {!editMode ? (
                            <VuiBox mb={2}>
                                <Grid
                                    item
                                    xs={12}
                                    md={5}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        alignItems: "center",
                                    }}
                                >
                                    <VuiButton
                                        variant="outlined"
                                        color="white"
                                        sx={({ palette: { white } }) => ({
                                            border: `1px solid ${white.main}`,
                                            color: white.main,
                                        })}
                                        onClick={() => setEditMode(true)}
                                    >
                                        Edit
                                    </VuiButton>
                                    <VuiButton
                                        variant="contained"
                                        color="white"
                                        sx={({ palette: { white } }) => ({
                                            border: `1px solid ${white.main}`,
                                        })}
                                        onClick={() => navigate('/hitch-admin/uicomponents')}
                                    >
                                        Back to Components
                                    </VuiButton>
                                </Grid>
                                <Grid container alignItems="center" spacing={1}>
                                    <Grid item xs={5}>
                                        <HitchTypography variant="button" fontWeight="regular" color="text">
                                            Component Name:
                                        </HitchTypography>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <HitchTypography variant="body2" color="white" fontWeight="medium">
                                            {component?.name || "N/A"}
                                        </HitchTypography>
                                    </Grid>
                                </Grid>

                                <Grid container alignItems="center" spacing={1}>
                                    <Grid item xs={5}>
                                        <HitchTypography variant="button" fontWeight="regular" color="text">
                                            Path:
                                        </HitchTypography>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <HitchTypography variant="body2" color="white" fontWeight="medium">
                                            {component?.path || "N/A"}
                                        </HitchTypography>
                                    </Grid>
                                </Grid>

                                <Grid container alignItems="center" spacing={1}>
                                    <Grid item xs={5}>
                                        <HitchTypography variant="button" fontWeight="regular" color="text">
                                            Source Path:
                                        </HitchTypography>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <HitchTypography variant="body2" color="white" fontWeight="medium">
                                            {component?.sourcePath || "N/A"}
                                        </HitchTypography>
                                    </Grid>
                                </Grid>

                                <HitchDisplayField label="Route Path:" value={component?.routePath} />
                            </VuiBox>
                        ) : (
                            <>
                                <Formik
                                    initialValues={{
                                        name: component?.name || "",
                                        path: component?.path || "",
                                        sourcePath: component?.sourcePath || "",
                                        routePath: component?.routePath || "",
                                    }}
                                    onSubmit={(values) => saveService(values)}
                                >
                                    {({ values, setFieldValue, handleSubmit }) => (
                                        <Form onSubmit={handleSubmit}>
                                            <VuiBox>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={5}
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "flex-end",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <VuiButton
                                                        type="submit"
                                                        variant="contained"
                                                        color="white"
                                                        sx={({ palette: { white } }) => ({
                                                            border: `1px solid ${white.main}`,
                                                        })}
                                                    >
                                                        save
                                                    </VuiButton>
                                                    <VuiButton
                                                        variant="outlined"
                                                        color="white"
                                                        sx={({ palette: { white } }) => ({
                                                            border: `1px solid ${white.main}`,
                                                            color: white.main,
                                                        })}
                                                        onClick={() => setEditMode(false)}
                                                    >
                                                        cancel
                                                    </VuiButton>
                                                </Grid>
                                                <FormField
                                                    name="name"
                                                    label="Component Name"
                                                    fullWidth
                                                    margin="normal"
                                                />
                                                <FormField
                                                    name="path"
                                                    label="Component Path"
                                                    fullWidth
                                                    margin="normal"
                                                />
                                                <FormField
                                                    name="sourcePath"
                                                    label="Component Path"
                                                    fullWidth
                                                    margin="normal"
                                                />

                                                <FormField
                                                    name="routePath"
                                                    label="Route Path"
                                                    fullWidth
                                                    margin="normal"
                                                />

                                            </VuiBox>
                                        </Form>
                                    )}
                                </Formik>
                            </>
                        )}
                    </VuiBox>
                </Card>
            </VuiBox>
        </DashboardLayout>
    );
}

export default UiComponentDetail;
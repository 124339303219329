
import Card from "@mui/material/Card";
import VuiBox from "components/VuiBox";
import HitchTypography from "components/HitchTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import { useHubConnections } from "context/HubConnectionsProvider";
import { useEffect, useState } from "react";
import { Grid, Tooltip } from "@mui/material";
import VuiButton from "components/VuiButton";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { v4 as uuidv4 } from 'uuid';
import HitchDataTable from "components/HitchDataTable";
import HitchSelectSimple from "components/HitchSelectSimple";

function MenuItems() {

    const cols = [
        { accessor: 'name', Header: 'Name', width: '25%' },
        { accessor: 'type', Header: 'Type', width: '25%' },
        { accessor: 'path', Header: 'Path', width: '35%' },
        { accessor: 'order', Header: 'Order', width: '15%' }
    ];

    const [list, setList] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState(localStorage.getItem('selectedAccount') || null);
    const [selectedRole, setSelectedRole] = useState(localStorage.getItem('selectedRole') || null);
    const [accounts, setAccounts] = useState([]);
    const [roles, setRoles] = useState([]);
    const [dataTableData, setDataTableData] = useState({
        columns: cols,
        rows: []
    });

    const {
        dashboardConnection,
        hitchDashCommand,
    } = useHubConnections();

    const navigate = useNavigate();

    useEffect(() => {
        if (dashboardConnection) {
            const subscribeToEvents = () => {
                dashboardConnection.on("4505b810-80a8-4b8e-8d9f-b8bdd3e063ec", (data) => {
                    setList(data);
                });
                dashboardConnection.on("91d03a0f-4fc1-48ac-a368-e9dd06d23b81", setAccounts);
                dashboardConnection.on("f1483793-2e84-4771-8ec5-7bb827d9d264", setRoles);
                hitchDashCommand('admin', 'menuitem', 'list', {});
                hitchDashCommand('admin', 'account', 'list', {});
            };

            subscribeToEvents();
            return () => {
                dashboardConnection.off('4505b810-80a8-4b8e-8d9f-b8bdd3e063ec');
                dashboardConnection.off("91d03a0f-4fc1-48ac-a368-e9dd06d23b81", setAccounts);
                dashboardConnection.off("f1483793-2e84-4771-8ec5-7bb827d9d264", setRoles);
            };
        }
    }, [dashboardConnection]);

    useEffect(() => {
        if (list) {
            setDataTableData((prevState) => ({
                ...prevState,
                rows: list
            }));
        }
    }, [list]);

    useEffect(() => {
        if (selectedAccount) {
            localStorage.setItem('selectedAccount', selectedAccount);
            if (dashboardConnection) {
                hitchDashCommand('admin', 'account-role', 'list', {
                    theAccountId: selectedAccount
                });
            }
        } else {
            setRoles([]);
        }
    }, [selectedAccount, dashboardConnection]);

    useEffect(() => {
        if (selectedRole) {
            localStorage.setItem('selectedRole', selectedRole);
        } else {
            localStorage.removeItem('selectedRole');
        }
    }, [selectedRole]);

    useEffect(() => {
        if (dashboardConnection) {
            if (selectedAccount) {
                const params = { theAccountId: selectedAccount };
                if (selectedRole) params.roleId = selectedRole;
                hitchDashCommand('admin', 'menuitem', 'list', params);
            }
        }
    }, [selectedAccount, selectedRole, dashboardConnection]);

    function newUiComponent() {
        navigate(`./${uuidv4()}`);
    }

    const handleRowClick = (row) => {
        navigate(`./${row.id}`);
    };

    function onAccountChange(account) {
        setSelectedAccount(account || null);
    }

    function onRoleChange(role) {
        setSelectedRole(role || null);
    }

    const sortedAccounts = [...accounts].sort((a, b) => a.name.localeCompare(b.name));
    const sortedRoles = [...roles].sort((a, b) => a.name.localeCompare(b.name));

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <VuiBox pt={6} pb={3}>
                <VuiBox mb={3}>
                    <Card>
                        <VuiBox p={3} pl={0} lineHeight={1}>
                            <HitchTypography variant="h5" fontWeight="medium" color="white">
                                Hitch.AI Menu Items
                            </HitchTypography>
                            <HitchTypography variant="button" fontWeight="regular" color="text">
                                Managing UI Menu Items on the Platform.
                            </HitchTypography>
                            <Grid
                                item
                                xs={12}
                                md={5}
                                sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                }}
                            >
                                <Tooltip title="New Component">
                                    <VuiButton
                                        variant="outlined"
                                        color="white"
                                        sx={({ palette: { white } }) => ({
                                            border: `1px solid ${white.main}`,
                                            color: white.main,
                                        })}
                                        onClick={() => newUiComponent()}
                                    >
                                        <FontAwesomeIcon icon={faPlus} />
                                    </VuiButton>
                                </Tooltip>
                            </Grid>
                        </VuiBox>
                        <VuiBox p={3} pl={0} lineHeight={1}>
                            <>
                                <HitchSelectSimple
                                    value={selectedAccount}
                                    label="Account"
                                    options={sortedAccounts.map((option) => ({
                                        label: option.name,
                                        value: option.id,
                                    }))}
                                    placeholder="Select account"
                                    onChange={(e) => onAccountChange(e)}
                                    isClearable
                                />

                                <HitchSelectSimple
                                    value={selectedRole}
                                    label="Role"
                                    options={sortedRoles.map((option) => ({
                                        label: option.name,
                                        value: option.id,
                                    }))}
                                    placeholder="Select role"
                                    onChange={(e) => onRoleChange(e)}
                                    isClearable
                                />
                            </>
                        </VuiBox>
                        <HitchDataTable
                            table={dataTableData}
                            canSearch
                            onRowClick={handleRowClick} />
                    </Card>
                </VuiBox>
            </VuiBox>
        </DashboardLayout>
    );
}

export default MenuItems;

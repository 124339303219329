
// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/hitch/hitch-admin/menu-items/MenuItemDetail/index.js

import { Button } from "@mui/material";
import HitchTypography from "components/HitchTypography";

function HitchLinkField({ label, url, newTab = false }) {
    return (
        <Button
            size="small"
            color="primary"
            href={url || "#"}
            target={newTab ? "_blank" : "_self"}
            rel="noopener noreferrer"
        >
            {label}
        </Button>
    );
}

export default HitchLinkField;

const theMarkdown = `
### **Standards Orchestration System (SOS)**
### **UI Plan for Unified Risk Management Feature**

--- 

## **Overview**

The **Unified Risk Management** feature provides a centralized platform for identifying, assessing, and managing risks across multiple standards. The UI is structured into two main pages:

1. **Risk Register Page:** Displays a list of all risks in a searchable and filterable format.
2. **Risk Details Page:** Allows users to view, manage, and track a specific risk in detail.

This structure ensures efficient risk management while enabling users to assess, mitigate, and monitor risks in alignment with compliance requirements.

---

## **UI Components and Pages**

1. **Risk Register Page**
2. **Risk Details Page**
3. **Modals and Dialogs**
   - Create/Edit Risk Modal
   - Risk Assessment Modal
   - Risk Treatment Plan Modal
   - Archive Confirmation Dialog
4. **Notification System**

---

### **1. Risk Register Page**

**Purpose:** Display a comprehensive list of all identified risks, allowing users to search, filter, and manage risks efficiently.

**Layout:**

- **Header:**
  - **Title:** "Risk Register"
  - **Action Buttons:**
    - **Create Risk:** Button to open the Create Risk Modal.

- **Search and Filters:**
  - **Search Bar:** Filter risks by name, ID, or keywords.
  - **Filter Options:**
    - **Risk Status:** Open, Mitigated, Archived
    - **Risk Category:** Operational, Cybersecurity, Compliance, etc.
    - **Impact Level:** Low, Medium, High
    - **Likelihood Level:** Low, Medium, High
    - **Date Range:** Creation or last updated dates.

- **Risk Grid/Table:**
  - **Columns:**
    - **Risk Name**
    - **Risk ID**
    - **Status** (Open, Mitigated, Archived)
    - **Impact Level**
    - **Likelihood Level**
    - **Owner**
    - **Last Updated**
    - **Actions** (View, Edit, Archive/Delete)

- **Pagination:** Navigate through multiple pages of risks.

**User Actions:**

- **View Risks:** Browse through the grid/table of risks.
- **Search and Filter:** Use the search bar and filters to locate specific risks.
- **Create Risk:** Click the "Create Risk" button to open the Create Risk Modal.
- **Quick Actions:** Perform actions like View, Edit, or Archive/Delete directly from the list.

**Wireframe Concept:**

\`\`\`
-----------------------------------------------------
| Risk Register                                      |
-----------------------------------------------------
| [Search Bar] [Filter: Status | Category | Impact] [Create Risk] |
-----------------------------------------------------
| Risk Name | Risk ID | Status | Impact | Likelihood | Owner | Actions |
|-------------------------------------------------------------------------|
| Risk A    | 001     | Open   | High   | Medium     | IT    | View/Edit/Archive |
| Risk B    | 002     | Mitigated | Low | Low       | HR    | View              |
| ...                                             ...                     |
-------------------------------------------------------------------------
| Pagination Controls                                                     |
-------------------------------------------------------------------------
\`\`\`

---

### **2. Risk Details Page**

**Purpose:** Provide detailed information about a specific risk and allow users to perform risk assessments, develop treatment plans, and track mitigation progress.

**Layout:**

- **Header:**
  - **Risk Name and ID**
  - **Status Indicator:** Open, Mitigated, Archived
  - **Action Buttons:** Edit, Archive/Delete, Perform Risk Assessment, Develop Treatment Plan

- **Risk Information Section:**
  - **Description:** Overview of the risk.
  - **Category:** Risk type (Operational, Cybersecurity, etc.).
  - **Impact Level:** Low, Medium, High
  - **Likelihood Level:** Low, Medium, High
  - **Risk Owner:** Assigned individual or department.
  - **Associated Assets:** Linked assets affected by the risk.
  - **Linked Standards:** ISO 27001, SOC 2, etc.

- **Risk Assessment Section:**
  - **Risk Score:** Impact x Likelihood = Risk Level (Low/Medium/High).
  - **Assessment History:** Log of previous assessments with dates and outcomes.

- **Risk Treatment Plan Section:**
  - **Treatment Actions:** List of actions taken to mitigate, transfer, accept, or avoid the risk.
  - **Status Updates:** Progress tracking for each treatment action.
  - **Attachments:** Supporting documents or evidence of mitigation.

- **Comments/Notes Section:**
  - **User Comments:** Allow users to add comments or notes related to the risk.

**User Actions:**

- **View Details:** Read detailed information about the risk.
- **Edit Risk:** Modify risk details.
- **Perform Risk Assessment:** Open the Risk Assessment Modal to update impact/likelihood levels and recalculate risk scores.
- **Develop Treatment Plan:** Open the Risk Treatment Plan Modal to create or update actions.
- **Archive/Delete Risk:** Archive resolved risks or delete non-critical ones.
- **Add Comments/Notes:** Collaborate by adding remarks or feedback.

**Wireframe Concept:**

\`\`\`
-----------------------------------------------------
| Risk A (ID: 001)                                   |
| Status: Open                                       |
| [Edit] [Archive] [Perform Risk Assessment] [Develop Treatment Plan] |
-----------------------------------------------------
| Description: [Text]                                |
| Category: Cybersecurity                            |
| Impact Level: High                                 |
| Likelihood Level: Medium                           |
| Risk Owner: IT Department                          |
| Associated Assets: [Asset 1, Asset 2]              |
| Linked Standards: [ISO 27001, SOC 2]               |
-----------------------------------------------------
| Risk Score: High                                   |
| Assessment History:                                |
| - 2024-01-15 | High Impact, Medium Likelihood | Bob |
| - 2023-12-10 | Medium Impact, Low Likelihood  | Alice |
-----------------------------------------------------
| Treatment Plan:                                    |
| - Action 1: Mitigate | In Progress | Due: 2024-02-01 |
| - Action 2: Transfer | Completed  | Due: 2024-01-20 |
| Attachments: [File1.pdf] [File2.docx]              |
-----------------------------------------------------
| Comments/Notes:                                    |
| - User1: [Comment]                                 |
| - User2: [Comment]                                 |
| [Add Comment]                                      |
-----------------------------------------------------
\`\`\`

---

### **3. Modals and Dialogs**

#### **3.1. Create/Edit Risk Modal**

**Purpose:** Facilitate the creation of new risks or editing of existing risks through a structured form.

**Layout:**

- **Header:**
  - **Title:** "Create New Risk" or "Edit Risk"

- **Form Sections:**
  1. **Basic Information:**
     - **Risk Name:** Text input.
     - **Risk ID:** Auto-generated or user-defined (read-only for editing).
     - **Description:** Text area.
     - **Category:** Dropdown selection (Operational, Cybersecurity, etc.).
     - **Risk Owner:** Dropdown selection for department or individual.
  
  2. **Impact and Likelihood:**
     - **Impact Level:** Dropdown (Low, Medium, High).
     - **Likelihood Level:** Dropdown (Low, Medium, High).
  
  3. **Associated Data:**
     - **Linked Assets:** Multi-select (e.g., data, systems).
     - **Linked Standards:** Multi-select dropdown (e.g., ISO 27001, SOC 2).

- **Action Buttons:**
  - **Save Draft:** Save changes without publishing.
  - **Submit Risk:** Save and activate the risk.
  - **Cancel:** Discard changes and close the modal.

**Wireframe Concept:**

\`\`\`
-----------------------------------------------------
| Create New Risk / Edit Risk                       |
-----------------------------------------------------
| Risk Name: [___________]                          |
| Risk ID: [Auto-Generated]                         |
| Description: [___________________________]        |
| Category: [Dropdown]                              |
| Risk Owner: [Dropdown]                            |
-----------------------------------------------------
| Impact Level: [Dropdown]                          |
| Likelihood Level: [Dropdown]                      |
-----------------------------------------------------
| Linked Assets: [Multi-select Dropdown]            |
| Linked Standards: [Multi-select Dropdown]         |
-----------------------------------------------------
| [Save Draft] [Submit Risk] [Cancel]               |
-----------------------------------------------------
\`\`\`

---

#### **3.2. Risk Assessment Modal**

**Purpose:** Allow users to assess or update the risk’s impact and likelihood.

**Layout:**

- **Form Inputs:**
  - **Impact Level:** Dropdown (Low, Medium, High).
  - **Likelihood Level:** Dropdown (Low, Medium, High).
  - **Risk Score:** Auto-calculated based on inputs.

- **Action Buttons:**
  - **Submit Assessment:** Save the assessment.
  - **Cancel:** Exit without saving.

**Wireframe Concept:**

\`\`\`
-----------------------------------------------------
| Risk Assessment                                   |
-----------------------------------------------------
| Impact Level: [Dropdown]                          |
| Likelihood Level: [Dropdown]                      |
-----------------------------------------------------
| Risk Score: High                                  |
-----------------------------------------------------
| [Submit Assessment] [Cancel]                      |
-----------------------------------------------------
\`\`\`

---

#### **3.3. Risk Treatment Plan Modal**

**Purpose:** Define actions to mitigate, transfer, accept, or avoid the risk.

**Layout:**

- **Form Inputs:**
  - **Action Name:** Text input.
  - **Action Type:** Dropdown (Mitigate, Transfer, Accept, Avoid).
  - **Assigned To:** Dropdown for responsible individuals.
  - **Due Date:** Date picker.
  - **Description:** Text area for action details.

- **Action Buttons:**
  - **Add Action:** Add the action to the treatment plan.
  - **Cancel:** Exit without saving.

**Wireframe Concept:**

\`\`\`
-----------------------------------------------------
| Risk Treatment Plan                               |
-----------------------------------------------------
| Action Name: [___________]                        |
| Action Type: [Dropdown]                           |
| Assigned To: [Dropdown]                           |
| Due Date: [Date Picker]                           |
| Description: [___________________________]        |
-----------------------------------------------------
| [Add Action] [Cancel]                             |
-----------------------------------------------------
\`\`\`

---

#### **3.4. Archive Confirmation Dialog**

**Purpose:** Confirm the archival of a resolved risk to prevent accidental archiving.

**Wireframe Concept:**

\`\`\`
-----------------------------------------------------
| Confirm Archive Risk                              |
-----------------------------------------------------
| Are you sure you want to archive the risk         |
| "Risk A"? This action will mark the risk as       |
| resolved and read-only.                           |
-----------------------------------------------------
| [Confirm Archive] [Cancel]                        |
-----------------------------------------------------
\`\`\`

---

### **4. Notification System**

**Purpose:** Notify users of changes, updates, or actions related to risks.

**Components:**
- **Toast Notifications:** For immediate feedback (e.g., "Risk created successfully").
- **In-App Notifications:** Persistent alerts in the notifications menu.

---

## **User Flow Scenarios**

### **1. Viewing Risks**

1. Navigate to the Risk Register Page.
2. Search or filter for specific risks.
3. Click on a risk to open its details in the Risk Details Page.

---

### **2. Creating a New Risk**

1. Click "Create Risk" on the Risk Register Page.
2. Fill out the Create Risk Modal form.
3. Save the risk as a draft or submit it to activate.

---

### **3. Managing an Existing Risk**

1. Select a risk from the Risk Register Page.
2. View details, perform assessments, or update treatment plans.

---

### **4. Archiving a Risk**

1. Open the Risk Details Page.
2. Click "Archive Risk."
3. Confirm archival in the dialog.

---

## **Conclusion**

The **Unified Risk Management** feature in SOS provides users with a robust, streamlined interface to manage risks efficiently. By consolidating risk-related actions into intuitive pages and modals, the UI supports effective risk identification, assessment, and treatment while aligning with multiple standards like ISO 27001. Let me know if you’d like further refinements or mockups for any specific components!`;

export default theMarkdown;
// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/index.js

import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { pdfjs } from "react-pdf";
import { HitchAIUIControllerProvider } from "context";
// import pdfWorker from "pdfjs-dist/build/pdf.worker.entry";

// pdfjs.GlobalWorkerOptions.workerSrc = pdfWorker;
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.8.69/pdf.min.mjs`;

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <BrowserRouter> 
    <HitchAIUIControllerProvider>
      <App />
    </HitchAIUIControllerProvider>
  </BrowserRouter>
);

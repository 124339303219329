
import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useHubConnections } from 'context/HubConnectionsProvider'; // Adjust the path if necessary
import { useParams } from 'react-router-dom';
import VuiBox from 'components/VuiBox';
import VuiButton from 'components/VuiButton';
import VuiInput from 'components/VuiInput';
import { Upload } from '@mui/icons-material';
import HitchDataTable from 'components/HitchDataTable'; // Import your custom data table component
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrash, faDownload } from "@fortawesome/free-solid-svg-icons";
import DeleteConfirmModal from './DeleteConfirmModal'; // Adjust path if necessary
import { IconButton } from '@mui/material';

function StoreDocumentsGrid({ path, handleUpload, handleView, handleDownload }) {
  const theme = useTheme();
  const { repoId } = useParams();
  const { dashboardConnection, hitchDashCommand, isConnectionReady } = useHubConnections();
  const [searchTerm, setSearchTerm] = useState('');
  const [fileToDelete, setFileToDelete] = useState(null);
  const [files, setFiles] = useState([]);
  const [open, setOpen] = useState(false);

  const filteredFiles = Array.isArray(files) ? files.filter(file =>
    file?.name?.toLowerCase().includes(searchTerm?.toLowerCase())
  ) : [];

  useEffect(() => {
    if (dashboardConnection && isConnectionReady && repoId) {
      dashboardConnection.on("8df1b111-2b65-4f11-a8e3-a9f268b90875", filesList);
      loadFromServer();
    }

    return () => {
      if (dashboardConnection) {
        dashboardConnection.off("8df1b111-2b65-4f11-a8e3-a9f268b90875", filesList);
      }
    };
  }, [dashboardConnection, isConnectionReady, repoId]);

  function filesList(fileListData) {
    setFiles(fileListData || []);
  }

  const handleClose = () => {
    setOpen(false);
    setFileToDelete(null);
  };

  const handleDeleteFile = (fileId) => {
    setFileToDelete(fileId);
    setOpen(true);
  };

  const confirmDelete = () => {
    hitchDashCommand('document', 'file', 'delete', { 'RepositoryId': repoId, 'FileId': fileToDelete });
    setOpen(false);
  };

  const loadFromServer = () => {
    hitchDashCommand('document', 'file', 'list', { 'RepositoryId': repoId }, filesList);
  };

  const columns = [
    { Header: "Name", accessor: "name", width: "50%" },
    { Header: "Date", accessor: "date", width: "20%", align: "center" },
    { Header: "Actions", accessor: "actions", width: "30%", align: "center" },
  ];

  const rows = filteredFiles.map((file) => ({
    name: file.name,
    date: file.date,
    actions: (
      <VuiBox display="flex" justifyContent="center">
        <IconButton onClick={() => handleDownload(file.id)} sx={{ color: theme.palette.info.main }}>
          <FontAwesomeIcon icon={faDownload} />
        </IconButton>
        <IconButton onClick={() => handleView(file.id)} sx={{ color: theme.palette.success.main }}>
          <FontAwesomeIcon icon={faEye} />
        </IconButton>
        <IconButton onClick={() => handleDeleteFile(file.id)} sx={{ color: theme.palette.error.main }}>
          <FontAwesomeIcon icon={faTrash} />
        </IconButton>
      </VuiBox>
    ),
  }));

  return (
    <VuiBox mt={3}>
      <VuiBox display="flex" alignItems="center" justifyContent="space-between" mb={3}>
        <VuiBox flexGrow={1} display="flex" alignItems="center">
          <VuiInput
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search Uploaded Files..."
            fullWidth
            sx={{ mr: 2 }}
          />
          <VuiButton
            variant="contained"
            component="label"
            startIcon={<Upload />}
            sx={{
              borderRadius: '8px',
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
              textTransform: 'none',
              backgroundColor: theme.palette.info.main,
              '&:hover': { backgroundColor: theme.palette.info.dark }
            }}
          >
            Upload File
            <input type="file" hidden onChange={handleUpload} />
          </VuiButton>
        </VuiBox>
      </VuiBox>

      <HitchDataTable
        table={{ columns, rows }}
        canSearch
        entriesPerPage={{ defaultValue: 10, entries: [5, 10, 15, 20] }}
        showTotalEntries
        pagination={{ variant: "gradient", color: "info" }}
        onRowClick={(row) => console.log("Row clicked:", row)}
      />

      <DeleteConfirmModal
        title="File Delete"
        content="Are you sure you want to delete this file? This action is not reversible!"
        labelNo="No"
        actionNo={handleClose}
        labelYes="Yes"
        actionYes={confirmDelete}
        open={open}
        onClose={handleClose}
      />
    </VuiBox>
  );
}

export default StoreDocumentsGrid;

import { Grid } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";

function PolicySaveButtons({ setEditMode, editMode }) {

    return (
        <Grid item xs={12}>
            {editMode && (
                <VuiBox display="flex" gap={2}>
                    <VuiButton type="submit" variant="outlined" color="white">Save</VuiButton>
                    <VuiButton variant="contained" color="white" onClick={() => setEditMode(false)}>Cancel</VuiButton>
                </VuiBox>
            )}
        </Grid>
    );
}

export default PolicySaveButtons;
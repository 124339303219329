import PropTypes from "prop-types";

// Vision UI Dashboard PRO React components
import HitchTypography from "components/HitchTypography";

function DefaultCell({ children }) {
  return (
    <HitchTypography variant="button" color="white" fontWeight="medium">
      {children}
    </HitchTypography>
  );
}

// Typechecking props for the DefaultCell
DefaultCell.propTypes = {
  children: PropTypes.string.isRequired,
};

export default DefaultCell;

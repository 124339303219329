import React from "react";
import { Card, CardContent, Grid, Avatar, Typography, Box } from "@mui/material";
import HitchTypography from "components/HitchTypography";

const AssigneesCard = ({ assignees }) => {
    if (!assignees || assignees.length === 0) {
        return null; // Render nothing if no assignees
    }

    return (
        <Card sx={{ marginTop: 3 }}>
            <CardContent>
                <HitchTypography variant="h5" fontWeight="medium" mb={2}>
                    Assignees
                </HitchTypography>
                <Grid container spacing={2}>
                    {assignees.map((assignee) => (
                        <Grid item xs={6} md={4} key={assignee.id}>
                            <Box display="flex" alignItems="center" gap={2}>
                                <Avatar
                                    alt={assignee.login}
                                    src={assignee.avatar_url}
                                    sx={{ width: 48, height: 48 }}
                                />
                                <Box>
                                    <Typography variant="h4" fontWeight="medium">
                                        {assignee.login}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        <a
                                            href={assignee.html_url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{
                                                textDecoration: "none",
                                                color: "#007bff",
                                            }}
                                        >
                                            View Profile
                                        </a>
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </CardContent>
        </Card>
    );
};

export default AssigneesCard;


// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/hitch/bot/BotDetail.js 

// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/hitch/bot/BotDetail.js 

import { Formik, Form } from 'formik';
import { useParams, useNavigate } from "react-router-dom";
import VuiBox from "components/VuiBox";
import HitchTypography from "components/HitchTypography";
import VuiButton from "components/VuiButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import Footer from "examples/Footer";
import { useHubConnections } from "context/HubConnectionsProvider";
import React, { useEffect, useState } from 'react';
import BotConfiguration from './BotConfiguration';
import BotGenerationsSettings from './BotGenerationsSettings';
import BotOperations from './BotOperations';
import BotFileManagement from './BotFileManagement';
import BotMetadata from './BotMetadata';
import OperationSelectionModal from './OperationSelectionModal'; // Ensure this is imported
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import HitchImageUploader from 'hitch/image-uploader';
import { Divider, Tab, Tabs } from '@mui/material';
import HitchDisplayField from 'components/HitchDisplayField';
import PropTypes from 'prop-types';
import HitchFormField from 'components/HitchFormField';
import HitchSelect from 'components/HitchSelect';
import HitchSwitch from 'components/HitchSwitch';
import HitchSlider from 'components/HitchSlider';
import HitchFormTextArea from 'components/HitchFormTextArea';
import HitchLoading from 'components/HitchLoading';
import A11yProps from 'components/A11yProps';
import HitchTabs from 'components/HitchTabs';
import BotButtons from './BotButtons';
import CustomTabPanel from 'components/CustomTabPanel';
import BotBasics from './BotBasics';
import BotAiSettings from './BotAiSettings';
import BotAiPrompts from './BotAiPrompts';
import BotAiKnowledge from './BotAiKnowledge';
import BotApis from './BotApis';
import BotAiDeployment from './BotAiDeployment';
import BotConfigurations from './BotConfigurations.js';

function BotDetail() {
  const { botId } = useParams();
  const navigate = useNavigate(); // Use navigate for routing
  const [bot, setBot] = useState({});
  const [metadata, setMetadata] = useState([{ key: "", value: "" }]);
  const [selectedOption, setSelectedOption] = useState('temperature');
  const { dashboardConnection, hitchDashCommand, isConnectionReady, hitchUser } = useHubConnections();
  const [allowAnonymous, setAllowAnonymous] = useState(false);
  const [jsonMode, setJsonMode] = useState(false);
  const [expandedSection, setExpandedSection] = useState({ section1: true, section2: true, section3: true, section4: true, section5: true, section6: true });
  const [editMode, setEditMode] = useState(false);
  const [models, setModels] = useState([]);
  const [functions, setFunctions] = useState([]);
  const [vectorStores, setVectorStores] = useState([]);
  const [openModal, setOpenModal] = useState(false); // State for controlling the modal visibility
  const [loading, setLoading] = useState(true); // Loading state for data fetching
  const [tab, setTab] = useState(0);

  useEffect(() => {
    if (dashboardConnection && botId && hitchUser && hitchUser.accountId) {
      // setLoading(true); // Start loading when fetching data
      const subscribeToEvents = () => {
        dashboardConnection.on("6ed4f39c-66fe-491a-8a06-aab443a31555", (data) => {
          console.log("Bot data received:", data);
          if (!Array.isArray(data)) {
            setBot({
              id: botId,
              accountId: hitchUser.accountId
            });
          }
          setBot(data);
          setMetadata(data.metadata || [{ key: "", value: "" }]);
          setAllowAnonymous(data.allowAnonymous || false);
          setJsonMode(data.jsonMode || false);
          setFunctions(data.functions || []);
          setLoading(false); // Stop loading once data is received
        });
        dashboardConnection.on("ab183cbd-c1a8-4bf3-a264-c8fa7d58660c", vectorStoresSir);
        dashboardConnection.on("b9704db3-9b31-4509-824f-4fea33241c5e", setTheModels);
      };

      subscribeToEvents();
      hitchDashCommand("chat", "bot", "get", { theBotId: botId });
      hitchDashCommand('openai', 'models', 'list', {});
      hitchDashCommand('document', 'repo', 'list', {});

      return () => {
        dashboardConnection.off("6ed4f39c-66fe-491a-8a06-aab443a31555");
        dashboardConnection.off("ab183cbd-c1a8-4bf3-a264-c8fa7d58660c");
        dashboardConnection.off("b9704db3-9b31-4509-824f-4fea33241c5e");
        setLoading(false); // Ensure loading state is reset if unsubscribed
      };
    }
  }, [dashboardConnection, botId, hitchDashCommand, hitchUser?.accountId]);

  function vectorStoresSir(data) {
    console.log('vectorStoresSir', data);
    setVectorStores(data);
  }

  const setTheModels = (data) => {
    setModels(data.data);
  };

  const handleSubmit = (values) => {
    console.log('values', values);
    console.log('functions', functions);
    if (dashboardConnection && isConnectionReady) {
      const updatedData = {
        ...values,           // Values from Formik form
        id: bot.id,
        accountId: hitchUser.accountId,
        created: bot.created,
        createdBy: bot.createdBy,
        updated: bot.updated,
        updatedBy: bot.updatedBy,
        metadata: bot.metadata,    // Metadata state
        allowAnonymous,            // Allow anonymous state
        jsonMode,                  // JSON mode state
        functions                  // Functions state
      };

      console.log('Updated Data to Submit:', updatedData);  // For debugging
      hitchDashCommand("chat", "bot", "update", { theBot: updatedData });
      setEditMode(false); // Switch to view mode after saving
    }
  };

  const removeFunction = (functionName) => {
    if (!functionName) return;
    setFunctions(currentFunctions => currentFunctions.filter(func => func.name !== functionName));
  };

  const handleFileSearchChange = (event, formikSetFieldValue) => {
    formikSetFieldValue("fileSearch", event.target.checked);
  };

  const handleVectorStoreChange = (value, formikSetFieldValue) => {
    formikSetFieldValue("selectedVectorStore", value);
  };

  const changeTab = (event, newValue) => {
    setTab(newValue);
  };

  useEffect(() => {
    if (bot) {
      console.log('bot', bot);
    }
  }, [bot]);

  function triggerDelete() {
    console.log('trigger delete');
  }

  function onSelectedOperation(selectedOperation) {
    if (dashboardConnection && isConnectionReady && selectedOperation) {
      hitchDashCommand('chat', 'bot', 'attach-operation', {
        botId: bot.id,
        service: selectedOperation.service,
        feature: selectedOperation.feature,
        operation: selectedOperation.operation
      },
        () => {
          // console.log('attach command submitted to the server.');
        });
    }
    else {
      console.error('signalR Hub Error');
    }
  }

  if (loading) { return (<HitchLoading />); }

  return (
    <DashboardLayout stickyNavbar>
      <DashboardNavbar />
      <Formik
        initialValues={{
          allowAnonymous: bot.allowAnonymous || false,
          botType: bot.botType || "",
          deployCode: bot.deployCode || "",
          description: bot.description || "",
          enabled: bot.enabled || false,
          fileSearch: bot.fileSearch || false,
          functions: bot.functions || [],
          initialWelcomeMessage: bot.initialWelcomeMessage || "",
          jsonMode: bot.jsonMode || false,
          model: bot.model || "",
          name: bot.name || "",
          openAiAssistantId: bot?.openAiAssistantId || "",
          selectedVectorStore: bot?.selectedVectorStore || "",
          standardInstructions: bot?.standardInstructions || "",
          temperature: bot?.temperature?.toString() || "1", // Converted to string
          topP: bot?.topP?.toString() || "1", // Converted to string
        }}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({ values, handleChange, setFieldValue, submitForm }) => (
          <VuiBox pt={6} pb={3}>

            <Card>
              <VuiBox pl={0} lineHeight={1}>
                <Grid container>
                  <Grid item xs={12} lg={1}>
                    <VuiBox p={3}>
                      <HitchImageUploader guid={botId} />
                    </VuiBox>
                  </Grid>
                  <Grid item xs={12} lg={9} container alignItems="center">
                    <Grid item xs={12}>
                      <HitchTypography variant="h5" fontWeight="medium" color="white">
                        Bot: {bot?.name}
                      </HitchTypography>
                    </Grid>
                    <Grid item xs={12}>
                      <HitchTypography variant="button" fontWeight="regular" color="text">
                        Open AI: {bot?.openAiAssistantId}
                      </HitchTypography>
                    </Grid>
                  </Grid>
                  <BotButtons
                    editMode={editMode}
                    saveForm={submitForm} // Call submitForm directly
                    setEditMode={setEditMode}
                    triggerDelete={triggerDelete}
                  />
                </Grid>
              </VuiBox>
            </Card>
            <Divider />
            <Card>
              <HitchTabs value={tab} onChange={changeTab} />

              <Form>
                <BotBasics
                  editMode={editMode}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  tab={tab}
                  values={values}
                />

                <BotAiSettings
                  editMode={editMode}
                  handleChange={handleChange}
                  models={models}
                  setFieldValue={setFieldValue}
                  tab={tab}
                  values={values}
                />
                <BotAiPrompts
                  editMode={editMode}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  tab={tab}
                  values={values}
                />

                <BotAiKnowledge
                  editMode={editMode}
                  handleChange={handleChange}
                  handleFileSearchChange={handleFileSearchChange}
                  handleVectorStoreChange={handleVectorStoreChange}
                  openModal={openModal}
                  setFieldValue={setFieldValue}
                  tab={tab}
                  values={values}
                  vectorStores={vectorStores}
                />

                <BotApis
                  editMode={editMode}
                  functions={functions}
                  handleChange={handleChange}
                  onSelectedOperation={onSelectedOperation}
                  openModal={openModal}
                  removeFunction={removeFunction}
                  setFunctions={setFunctions}
                  setOpenModal={setOpenModal}
                  tab={tab}
                  values={values}
                />

                <BotAiDeployment
                  editMode={editMode}
                  tab={tab}
                  values={values}
                />

                <BotConfigurations 
                  editMode={editMode}
                  tab={tab}
                  values={values}
                />
              </Form>


            </Card>
          </VuiBox>
        )}
      </Formik>
      <Footer />
    </DashboardLayout >
  );
}

export default BotDetail;
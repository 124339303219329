import IdCell from "layouts/ecommerce/orders/order-list/components/IdCell";
import DefaultCell from "layouts/ecommerce/orders/order-list/components/DefaultCell";
import StatusCell from "layouts/ecommerce/orders/order-list/components/StatusCell";
import CustomerCell from "layouts/ecommerce/orders/order-list/components/CustomerCell";
import { v4 as uuidv4 } from 'uuid';
import { MdCheckCircle, MdError, MdOutlinePending, MdPending, MdRunCircle } from "react-icons/md";
import ServiceCell from "./serviceCell";

export default {
  columns: [
    {
      Header: "DATE",
      accessor: "date",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    // { Header: "ID", accessor: "id", Cell: ({ value }) => <DefaultCell value={value} /> },
    {
      Header: "SERVICE",
      accessor: "service",
      Cell: ({ value: [serviceId, serviceName] }) => (
        <ServiceCell serviceId={serviceId} name={serviceName} />
      ),
    },
    {
      Header: "FEATURE",
      accessor: "feature",
      Cell: ({ value: [featureId, featureName] }) => (
        <ServiceCell serviceId={featureId} name={featureName} />
      ),
    },
    {
      Header: "OPERATION",
      accessor: "operation",
      Cell: ({ value: [operationId, operationName] }) => (
        <ServiceCell serviceId={operationId} name={operationName} />
      )
    },
    {
      Header: "STATUS",
      accessor: "status",
      Cell: ({ value }) => {
        let status;

        switch (value) {
          case "pending":
            status = <StatusCell icon={<MdOutlinePending />} color="dark" status="Pending" />;
            break;
          case "running":
            status = <StatusCell icon={<MdRunCircle />} color="info" status="Running" />;
            break;
          case "success":
            status = <StatusCell icon={<MdCheckCircle />} color="success" status="Success" />;
            break;
          case "exception":
            status = <StatusCell icon={<MdError />} color="error" status="Error" />;
            break;
        }
        return status;
      },
    },
  ],

  rows: [
    {
      id: uuidv4(),
      date: new Date().toISOString(),
      status: "pending",
      product: "Nike Sport V2",
      revenue: "$140,20",
      service: ['4fed2823-7fdb-41f8-adad-a562f4a2e709', 'Sos'],
      feature: ['c403a514-1344-4a4f-8b6f-43e7e3922e78', 'task'],
      operation: ['7e397905-254d-4bda-8170-eba5bb28abe1', 'update'],
    },
    {
      id: uuidv4(),
      date: new Date().toISOString(),
      status: "running",
      product: "Valvet T-shirt",
      revenue: "$42,00",
      service: ['d8ba1a8e-8733-47bb-a932-62f86e459f66', 'Chat'],
      feature: ['cdff1a7f-d18c-49da-82a0-680dc8eec2b4', 'bot'],
      operation: ['94012e40-39a4-4c10-8731-e1885ab46e20', 'attach-file'],
    },
    {
      id: uuidv4(),
      date: new Date().toISOString(),
      status: "success",
      product: "",
      revenue: "$25,50",
      service: ['c5e92791-b9e7-4d7e-ae22-90cce4ec0b43', 'GitHub'],
      feature: ['2d114a7b-98d6-4ac7-8c7e-bbfca1fe2021', 'issues'],
      operation: ['640282e9-5891-4e03-87e1-9d13f95d5256', 'update'],
    },
    {
      id: uuidv4(),
      date: new Date().toISOString(),
      status: "exception",
      product: "Bracelet Onu-Lino",
      revenue: "$19,40",
      service: ['e59d1ba9-5b71-4f5a-9205-9ce82cbf2dfc', 'OpenAI'],
      feature: ['720b3e53-82f8-4731-bcd2-480f8bcd1556', 'file'],
      operation: ['5a33056a-8207-4845-84c5-4992e9585a40', 'upload'],
    }
  ],
};


// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/hitch/administration/connections/index.js

import HitchDataTable from "components/HitchDataTable";
import HitchPage from "components/HitchPage";
import { useHubConnections } from "context/HubConnectionsProvider";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function Connections() {

    const [dataTableData, setDataTableData] = useState({
        columns: [
            { accessor: "name", Header: "Name", width: "20%" }
        ],
        rows: [],
    });
    const [list, setList] = useState([]);
    const { dashboardConnection, hitchDashCommand, isConnectionReady } = useHubConnections();
    const navigate = useNavigate();

    const handleRowClick = (row) => {
        console.log('row click', row);
        // navigate(`./${row.id}`);
    };

    useEffect(() => {
        if (list) {
            setDataTableData((prevState) => ({
                ...prevState,
                rows: list
            }));
        }
    }, [list]);

    // Fetch initial data
    useEffect(() => {
        if (dashboardConnection, isConnectionReady) {
            const subscribeToEvents = () => {
                dashboardConnection.on("7ca387cb-6ff9-4a13-8dff-2fdb954cbf89", setList);  // THIS IS THE HITCH ID.  This is where the response will come from
                hitchDashCommand("account", "connection", "list", {  // This is how to use the Service / Feature / Operation on the Hitch Platform.
                    /* PAYLOAD WOULD GO HERE */
                });
            };

            subscribeToEvents();

            return () => {
                dashboardConnection.off("7ca387cb-6ff9-4a13-8dff-2fdb954cbf89", setList);
            };
        }
    }, [dashboardConnection, isConnectionReady]);

    function newConnection() {
        console.error('new Connection is not wired up.');
    }

    return (
        <HitchPage
            title="Service Connections"
            subTitle="Manage Organization's Service Connections">

            <HitchDataTable
                onAddClick={newConnection}
                table={dataTableData}
                canSearch
                onRowClick={handleRowClick}
            />

        </HitchPage>
    )
}

export default Connections;
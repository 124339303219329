import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import HitchTypography from "components/HitchTypography";
import VuiDropzone from "components/VuiDropzone";

// NewProduct page components
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";

function Pricing() {
  return (
    <Card sx={{ overflow: "visible", height: "100%" }}>
      <VuiBox mb={3}>
        <HitchTypography variant="h5" fontWeight="bold" color="white">
          Product Images
        </HitchTypography>
      </VuiBox>
      <VuiDropzone options={{ addRemoveLinks: true }} />
    </Card>
  );
}

export default Pricing;

import { Button, Card, Grid } from "@mui/material";
import VuiBox from "components/VuiBox";
import { useHubConnections } from "context/HubConnectionsProvider";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SosTaskHeader from "./SosTaskHeader";
import { Form, Formik } from "formik";
import HitchFormField from "components/HitchFormField";
import VuiButton from "components/VuiButton";
import ChildrenTaskControl from "./ChildrenTaskControl";
import SosTaskTree from "./SosTaskTree";
import HitchTypography from "components/HitchTypography";
import EvidenceControl from "./EvidenceControl";

function SosTaskDetails() {
    const { taskId } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [theTask, setTheTask] = useState({});
    const { dashboardConnection, hitchDashCommand, hitchUser, isConnectionReady } = useHubConnections();
    const [editMode, setEditMode] = useState(false);
    const [saving, setSaving] = useState(false);
    const [treeOpen, setTreeOpen] = useState(false);

    const parentId = new URLSearchParams(location.search).get("parentId");

    useEffect(() => {
        console.log('theTask', theTask);
    }, [theTask]);

    useEffect(() => {
        if (dashboardConnection && isConnectionReady && taskId) {
            const fetchTask = () => {
                hitchDashCommand("sos", "task", "get", {
                    taskId: taskId,
                });
            };

            // Fetch the task when the component mounts or when taskId changes
            fetchTask();

            dashboardConnection.on("20e1742e-4b87-4bab-9b36-7bd8bea1e7b2", incomingTask);
            dashboardConnection.on("7e397905-254d-4bda-8170-eba5bb28abe1", taskUpdated);

            return () => {
                dashboardConnection.off("20e1742e-4b87-4bab-9b36-7bd8bea1e7b2", incomingTask);
                dashboardConnection.off("7e397905-254d-4bda-8170-eba5bb28abe1", taskUpdated);
            };
        }
    }, [dashboardConnection, isConnectionReady, taskId]); // Listen for taskId changes

    useEffect(() => {
        console.log('taskId', taskId);
    }, [taskId]);

    useEffect(() => {
        if (hitchUser && parentId && theTask && Object.keys(theTask)?.length === 0) {
            const newTask = {
                id: taskId,
                parentId: parentId,
                name: "",
                requirement: "",
                score: 0,
                maxScore: 1,
                accountId: hitchUser.accountId,
                created: new Date().toISOString(),
                createdBy: "placeholder-user-id",
            };
            setTheTask(newTask);
        }
    }, [parentId, taskId, theTask, hitchUser]);

    function incomingTask(data) {
        console.log("Incoming Task:", data);
        setTheTask(data);
        setSaving(false);
    }

    function saveTask(values) {
        setSaving(true);

        const aTask = {
            accountId: theTask?.accountId,
            created: theTask?.created,
            id: taskId,
            maxScore: values.maxScore,
            name: values.name,
            parentId: parentId,
            requirement: values.requirement,
            score: values.score,
            updated: theTask?.updated,
            updatedBy: theTask?.updatedBy,
        };

        console.log("Save Task", aTask);
        hitchDashCommand("sos", "task", "update", {
            theTask: aTask,
        });
        setEditMode(false);
    }

    function taskUpdated(data) {
        console.log("Updated Task:", data);
        hitchDashCommand("sos", "task", "get", {
            taskId: taskId,
        });
    }

    function treeViewClick() {
        setTreeOpen(true);
    }

    useEffect(() => {
        if (theTask?.parentId && (!parentId || parentId != theTask.parentId)) {
            navigate(`?parentId=${theTask.parentId}`, { replace: true });
        }
    }, [theTask, parentId, navigate]);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <VuiBox pt={6} pb={3}>
                <VuiBox mb={3}>
                    <Card
                        sx={({ functions: { linearGradient }, palette: { gradients } }) => ({
                            overflow: "visible",
                            background: linearGradient(
                                gradients.cardDark.main,
                                gradients.cardDark.state,
                                gradients.cardDark.deg
                            ),
                        })}
                    >
                        <VuiBox p={3} pl={0} lineHeight={1}>
                            <SosTaskHeader
                                editMode={editMode}
                                setEditMode={setEditMode}
                                theTask={theTask}
                                parentId={parentId}
                                treeViewClick={treeViewClick}
                            />
                        </VuiBox>

                        <VuiBox p={3}>
                            <Formik
                                enableReinitialize
                                initialValues={{
                                    name: theTask?.name || "",
                                    requirement: theTask?.requirement || "",
                                    score: theTask?.score || "",
                                    maxScore: theTask?.maxScore || "",
                                }}
                                onSubmit={(values) => saveTask(values)}
                            >
                                {({ values, handleSubmit }) => (
                                    <Form onSubmit={handleSubmit}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={6}>
                                                <HitchFormField
                                                    name="name"
                                                    label="Name"
                                                    fullWidth
                                                    editMode={editMode}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <HitchFormField
                                                    name="requirement"
                                                    label="Requirement"
                                                    fullWidth
                                                    multiline
                                                    rows={3}
                                                    editMode={editMode}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <HitchFormField
                                                    name="score"
                                                    label="Score"
                                                    fullWidth
                                                    editMode={editMode}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <HitchFormField
                                                    name="maxScore"
                                                    label="Max Score"
                                                    fullWidth
                                                    editMode={editMode}
                                                />
                                            </Grid>
                                        </Grid>

                                        {editMode && (
                                            <VuiBox mt={2}>
                                                <VuiButton variant="contained" color="success" type="submit">
                                                    Save
                                                </VuiButton>
                                            </VuiBox>
                                        )}
                                    </Form>
                                )}
                            </Formik>
                        </VuiBox>
                    </Card>
                </VuiBox>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <ChildrenTaskControl childrenTasks={theTask?.children || []} parentId={theTask?.id} editMode={editMode} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <EvidenceControl evidence= {theTask?.evidence} parentId={theTask?.id} editMode={editMode} />
                    </Grid>
                </Grid>

                <SosTaskTree
                    data={theTask}
                    treeOpen={treeOpen}
                    setTreeOpen={setTreeOpen}
                />
            </VuiBox>
        </DashboardLayout>
    );
}

export default SosTaskDetails;
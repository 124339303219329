// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/third/HC5E92791_B9E7_4D7E_AE22_90CCE4EC0B43/GitHubRepositories.js

import { Card } from "@mui/material";
import HitchDataTable from "components/HitchDataTable";
import HitchDataTableExternalPagination from "components/HitchDataTableExternalPagination";
import VuiBox from "components/VuiBox";
import HitchTypography from "components/HitchTypography";
import { useHubConnections } from "context/HubConnectionsProvider";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";


function GitHubRepositories() {
    const cols = [
        { accessor: 'id', Header: 'Id', width: '25%' },
        { accessor: 'name', Header: 'Name', width: '25%' },
        { accessor: 'description', Header: 'Description', width: '50%' }
    ];

    const [list, setList] = useState([]);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalResults, setTotalResults] = useState(11);

    const [dataTableData, setDataTableData] = useState({
        columns: cols,
        rows: []
    });

    const {
        dashboardConnection,
        hitchDashCommand,
    } = useHubConnections();

    useEffect(() => {
        if (dashboardConnection) {
            const subscribeToEvents = () => {
                console.log('subscribing to events!');
                dashboardConnection.on("2fb972b7-9d11-45a1-b629-24d02eeeca97", (data) => {
                    setList(data);
                });
                // Add other event subscriptions here
                hitchDashCommand('github', 'repositoriesrepositories', 'list', {
                     page: page, // DEFAULTS TO 1
                     per_page: perPage // DEFAULTS TO 10
                });
            };

            subscribeToEvents();

            return () => {
                dashboardConnection.off('2fb972b7-9d11-45a1-b629-24d02eeeca97');
            };
        }
    }, [dashboardConnection]);

    useEffect(() => {
        if (dashboardConnection && page && perPage) {
            hitchDashCommand('github', 'repositoriesrepositories', 'list', {
                page: page, // DEFAULTS TO 1
                per_page: perPage // DEFAULTS TO 10
            });
        }
    }, [dashboardConnection, page, perPage]);

    const navigate = useNavigate();

    const handleRowClick = (row) => {
        navigate(`./${row.name}`);
    };

    useEffect(() => {
        if (list) {
            if (list === "An Error Has Occurred.") {
                console.error(list);
                return;
            }

            console.log('THE LIST!', list);
            setDataTableData((prevState) => ({
                ...prevState,
                rows: list
            }));
        }
    }, [list]);

    function onPageChange(page, perPage) {
        setPage(page);
        setPerPage(perPage);
        setTotalResults(page * perPage + 1);
    }


    return (
        <DashboardLayout>
            <DashboardNavbar />
            <VuiBox pt={6} pb={3}>
                <VuiBox mb={3}>
                    <Card>
                        <VuiBox p={3} pl={0} lineHeight={1}>
                            <HitchTypography variant="h5" fontWeight="medium" color="white">
                                Hitch.AI GitHub Link
                            </HitchTypography>
                            <HitchTypography variant="button" fontWeight="regular" color="text">
                                Repositories by Org.
                            </HitchTypography>
                        </VuiBox>
                        <HitchDataTableExternalPagination
                            table={dataTableData}
                            canSearch
                            onRowClick={handleRowClick}
                            totalResults={totalResults}
                            onPageChange={onPageChange}
                            paginated={true}
                            page={page}
                        />
                    </Card>
                </VuiBox>
            </VuiBox>
        </DashboardLayout>
    );
}

export default GitHubRepositories;


// OUTPUT THE FULL FILE EVERY TIME, INCLUDING THESE INTRODUCTION COMMENTS. 
// WE WILL KEEP A CHAT LOG BETWEEN YOU AND ME IN THESE COMMENTS. YOU ARE AIA.
// AIA: How can I help?
// DREW: When I view the page, I know the data for serviceId, featureId, and operationId come in.  The drop lists are filled, but the values are not selected.

import { Grid } from "@mui/material";
import HitchDisplayField from "components/HitchDisplayField";
import HitchFormField from "components/HitchFormField";
import HitchSFOSelector from "components/HitchSFOSelector";
import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";
import HitchTypography from "components/HitchTypography";
import { useHubConnections } from "context/HubConnectionsProvider";
import { Form, Formik } from "formik";
import { useEffect } from "react";

function GitHubRuleDefinition({
    editMode,
    formikRef,
    gitHubService,
    rule,
    saveRule,
    setEditMode,
    setSelectedFeatureId
}) {

    const { dashboardConnection, hitchDashCommand, hitchUser } = useHubConnections();

    return (
        <>
            {!editMode ? (
                <VuiBox mb={2}>
                    <Grid
                        item
                        xs={12}
                        md={5}
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                        }}
                    >
                        <VuiButton
                            variant="outlined"
                            color="white"
                            sx={({ palette: { white } }) => ({
                                border: `1px solid ${white.main}`,
                                color: white.main,
                            })}
                            onClick={() => setEditMode(true)}
                        >
                            Edit
                        </VuiButton>
                    </Grid>
                    <HitchDisplayField label="Rule Id" value={rule?.id} />
                    <HitchDisplayField label="Name" value={rule?.name} />
                    <HitchDisplayField label="Account Id" value={rule?.accountId} />

                    <HitchSFOSelector
                        value={{
                            serviceId: rule.serviceId,
                            featureId: rule.featureId,
                            operationId: rule.operationId,
                        }}
                        onChange={(updatedValues) => {
                            setFieldValue("serviceId", updatedValues.serviceId);
                            setFieldValue("featureId", updatedValues.featureId);
                            setFieldValue("operationId", updatedValues.operationId);
                        }}
                        readOnly={!editMode}
                        fixedServiceId={gitHubService}
                    />

                    <HitchDisplayField label="Is Active" value={rule?.isActive} />
                    <HitchDisplayField label="Expression" value={rule?.ruleExpression} />
                </VuiBox>
            ) : (
                <Formik
                    innerRef={formikRef}
                    initialValues={{
                        name: rule?.name || "",
                        serviceId: rule?.serviceId || "",
                        featureId: rule?.featureId || "",
                        operationId: rule?.operationId || "",
                        isActive: rule?.isActive || false,
                        ruleExpression: rule?.ruleExpression || ""
                    }}
                    onSubmit={(values) => saveRule(values)}
                >
                    {({ values, setFieldValue, handleSubmit }) => {
                        useEffect(() => {
                            if (values.featureId) {
                                setSelectedFeatureId(values.featureId);
                                hitchDashCommand('account', 'connection', 'route', {
                                    theAccountId: hitchUser.accountId,
                                    theServiceId: gitHubService,
                                    theFeatureId: values.featureId,
                                });
                            }
                        }, [values.featureId, hitchUser.accountId, gitHubService, hitchDashCommand]);

                        return (
                            <Form onSubmit={handleSubmit}>
                                <VuiBox>
                                    <Grid
                                        item
                                        xs={12}
                                        md={5}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                        }}
                                    >
                                        <VuiButton
                                            type="submit"
                                            variant="contained"
                                            color="white"
                                            sx={({ palette: { white } }) => ({
                                                border: `1px solid ${white.main}`,
                                            })}
                                        >
                                            Save
                                        </VuiButton>
                                        <VuiButton
                                            variant="outlined"
                                            color="white"
                                            sx={({ palette: { white } }) => ({
                                                border: `1px solid ${white.main}`,
                                                color: white.main,
                                            })}
                                            onClick={() => setEditMode(false)}
                                        >
                                            Cancel
                                        </VuiButton>
                                    </Grid>

                                    <HitchDisplayField label="Rule Id" value={rule?.id} />

                                    <HitchFormField
                                        name="name"
                                        label="Name"
                                        fullWidth
                                        editMode={editMode}
                                    />

                                    <HitchDisplayField label="Account Id" value={rule?.accountId} />

                                    <HitchSFOSelector
                                        value={{
                                            serviceId: values.serviceId,
                                            featureId: values.featureId,
                                            operationId: values.operationId,
                                        }}
                                        onChange={(updatedValues) => {
                                            setFieldValue("serviceId", updatedValues.serviceId);
                                            setFieldValue("featureId", updatedValues.featureId);
                                            setFieldValue("operationId", updatedValues.operationId);
                                        }}
                                        readOnly={!editMode}
                                        fixedServiceId={gitHubService}
                                        editMode={editMode}
                                    />

                                    <VuiBox display="flex" alignItems="center" mt={2}>
                                        <HitchTypography variant="subtitle1" color="white" mr={2}>
                                            Is Active
                                        </HitchTypography>
                                        <VuiSwitch
                                            color="info"
                                            checked={!!values.isActive} // Ensure the value is always a boolean
                                            onChange={(e) => setFieldValue("isActive", e.target.checked)} // Store as a boolean
                                        />
                                    </VuiBox>

                                    <HitchFormField
                                        name="ruleExpression"
                                        label="Expression"
                                        fullWidth
                                        editMode={editMode}
                                    />
                                </VuiBox>
                            </Form>
                        );
                    }}
                </Formik>
            )}
        </>
    );
}

export default GitHubRuleDefinition;

// AI HINT:  org: HitchSoftware | repoId: hitch-ai | path: \UI\hitch-ai-ui-2\src\third\sos\dashboard\SosTaskList.js

import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import VuiBox from "components/VuiBox";
import HitchTypography from "components/HitchTypography";
import VuiButton from "components/VuiButton"; // Assuming you have a VuiButton component
import { useEffect, useState } from "react";
import SosTodo from "./SosToDo";

// Recursive function to get tasks without children
const getTasksWithoutChildren = (tasks) => {
    let results = [];
    if (!tasks) {
        return results;
    }
    tasks.forEach(task => {
        if (task.children && task.children.length > 0) {
            // Recursively check children
            results = results.concat(getTasksWithoutChildren(task.children));
        } else {
            // If no children, add the task to results
            results.push(task);
        }
    });

    return results;
};

// Function to format date
const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', { 
        month: 'short', 
        day: 'numeric', 
        year: 'numeric', 
        hour: 'numeric', 
        minute: 'numeric', 
        second: 'numeric', 
        hour12: true 
    });
};

function SosTaskList({ tasks, title, loadFrame }) {
    // State to manage the current page
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 4;
    const [updatedTime, setUpdatedTime] = useState(Date.now());

    // Step 1: Get all tasks without children, including those from children
    const tasksWithoutChildren = getTasksWithoutChildren(tasks);

    // Step 2: Calculate completion percentage and add it to the task object
    const tasksWithCompletion = tasksWithoutChildren.map(task => ({
        ...task,
        completionPercentage: (task.score / task.maxScore) * 100
    }));

    // Step 3: Filter out completed tasks (100% completion)
    const incompleteTasks = tasksWithCompletion.filter(task => task.completionPercentage < 100);

    // Step 4: Sort tasks by completion percentage
    const sortedTasks = incompleteTasks.sort((a, b) => b.completionPercentage - a.completionPercentage);

    // Step 5: Get tasks for the current page
    const startIndex = currentPage * itemsPerPage;
    const topTasks = sortedTasks.slice(startIndex, startIndex + itemsPerPage);

    // Step 6: Create the status message
    const totalTasksWithoutChildren = incompleteTasks.length;
    const endIndex = Math.min(startIndex + itemsPerPage, totalTasksWithoutChildren);
    const statusMessage = `Showing ${startIndex + 1}-${endIndex} of ${totalTasksWithoutChildren}`;

    // Function to handle the "Next" button click
    const handleNextClick = () => {
        setCurrentPage(prevPage => prevPage + 1);
    };

    // Function to handle the "Previous" button click
    const handlePreviousClick = () => {
        setCurrentPage(prevPage => prevPage - 1);
    };

    // Update the time every minute
    useEffect(() => {
        const interval = setInterval(() => {
            setUpdatedTime(Date.now());
        }, 60000); // Update every minute

        return () => clearInterval(interval); // Cleanup on unmount
    }, []);

    // Calculate minutes ago
    const minutesAgo = Math.floor((Date.now() - updatedTime) / 60000);
    const updatedMessage = `Updated ${minutesAgo} minute${minutesAgo !== 1 ? 's' : ''} ago`;

    return (
        <Card>
            <VuiBox display="flex" justifyContent="space-between" alignItems="center">
                <HitchTypography variant="lg" fontWeight="bold" color="white">
                    {title}
                </HitchTypography>
                <HitchTypography variant="button" fontWeight="regular" color="text">
                    {updatedMessage}
                </HitchTypography>
            </VuiBox>
            <Divider />
            <VuiBox>
                <VuiBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                    {topTasks.map(task => (
                        <SosTodo
                            key={task.id}
                            title={task.name}
                            date={formatDate(task.updated)} // Format the date
                            id={task.id} // Assuming accountId is used as project
                            company={`${task.score} / ${task.maxScore}`} // Replace with actual company name if available
                            defaultChecked={true} // Checkbox is checked and not clickable
                            disabled={true} // Checkbox is not clickable
                            loadFrame={loadFrame}
                        />
                    ))}
                </VuiBox>
            </VuiBox>
            <Divider />
            <HitchTypography variant="button" fontWeight="regular" color="text" align="center">
                {statusMessage}
            </HitchTypography>
            <VuiBox display="flex" justifyContent="space-between" mt={2}>
                <VuiButton 
                    onClick={handlePreviousClick} 
                    variant="outlined" 
                    disabled={currentPage === 0}
                >
                    Previous
                </VuiButton>
                <VuiButton 
                    onClick={handleNextClick} 
                    variant="outlined" 
                    disabled={endIndex >= totalTasksWithoutChildren}
                >
                    Next
                </VuiButton>
            </VuiBox>
        </Card>
    );
}

export default SosTaskList;
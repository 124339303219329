import { Card, Grid, Typography } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import HitchTypography from "components/HitchTypography";
import PieChartWidget from "components/HitchWidget/PieChartWidget";

function ChildrenTaskControl({ childrenTasks, editMode, parentId }) {
    const navigate = useNavigate();

    function addChildTask() {
        const newTaskId = uuidv4(); // Generate new UUID
        navigate(`/third/sos/tasks/${newTaskId}?parentId=${parentId}`);
    }

    function goToChild(theId) {
        navigate(`/third/sos/tasks/${theId}?parentId=${parentId}`);
    }

    return (
        <Card sx={{ p: 3, mt: 3 }}>
            <Grid container>
                <Grid item xs={12} md={9}>
                    <HitchTypography variant="h6">Child Tasks</HitchTypography>
                </Grid>
                <Grid item xs={12} md={3} alignContent="end" alignItems="end">
                    <VuiButton variant="outlined" color="white" onClick={addChildTask}>
                        Add Child Task
                    </VuiButton>
                </Grid>
            </Grid>

            {childrenTasks.length > 0 ? (
                childrenTasks.map((child, index) => (
                    <Card key={index} sx={{ mb: 2, p: 2 }}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={4} pr={10}>
                                <PieChartWidget
                                    chartData={[
                                        { value: child.score, label: 'Complete', color: '#00AAAA' },
                                        { value: (child.maxScore - child.score), label: 'Incomplete', color: '#111111' },
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <HitchTypography variant="subtitle1" color="white">{child.name}</HitchTypography>
                                <HitchTypography color="white">
                                    <strong>Requirement:</strong> {child.requirement}
                                </HitchTypography>
                                <HitchTypography color="white">
                                    <strong>Score:</strong> {child.score}/{child.maxScore}
                                </HitchTypography>

                                <VuiBox mt={2}>
                                    <VuiButton variant="outlined" color="warning" onClick={() => goToChild(child.id)}>
                                        View
                                    </VuiButton>
                                    <VuiButton variant="outlined" color="error" onClick={() => console.log(`Delete Child: ${child.id}`)}>
                                        Delete
                                    </VuiButton>
                                </VuiBox>
                            </Grid>
                        </Grid>
                    </Card>
                ))
            ) : (
                <HitchTypography>No child tasks available</HitchTypography>
            )}
        </Card>
    );
}

export default ChildrenTaskControl;
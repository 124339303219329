import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

function BasicPieChart({ pieChartData, pieChartOptions }) {
  const [chartData, setChartData] = useState(pieChartData || []);
  const [chartOptions, setChartOptions] = useState(pieChartOptions || {});

  useEffect(() => {
    setChartData(pieChartData);
    setChartOptions(pieChartOptions);
  }, [pieChartData, pieChartOptions]);

  return (
    <ReactApexChart
      options={chartOptions}
      series={chartData}
      type="pie"
      width="100%"
      height="100%"
    />
  );
}

export default BasicPieChart;
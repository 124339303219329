import HitchPage from "components/HitchPage";
import VuiBox from "components/VuiBox";
import HitchTypography from "components/HitchTypography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import StatsCard from "widgets/Cards/StatsCard";
import { useHubConnections } from "context/HubConnectionsProvider";
import SosTaskList from "./SosTaskList";
import ChatControl from "hitch/chat/general/ChatControl";
import VuiButton from "components/VuiButton";
import { v4 as uuidv4 } from 'uuid';
import { useLocation, useParams, useNavigate } from "react-router-dom";
import HitchFrame from "hitch/hitchframe";

function SosDashboard() {
    const hardCodedTaskId = '2dd40f66-0f8d-4d8c-b21a-96e35d04b4f1';
    const { dashboardConnection, hitchDashCommand, hitchUser } = useHubConnections();
    const [topLevelTask, setTopLevelTask] = useState(hardCodedTaskId);
    const [score, setScore] = useState(0);
    const [maxScore, setMaxScore] = useState(0);
    const [tasks, setTasks] = useState([]);
    const [chatMessages, setChatMessages] = useState([]);
    const [slackBotMenu, setSlackBotMenu] = useState(null);
    const closeSlackBotMenu = () => setSlackBotMenu(null);
    const [theTask, setTheTask] = useState(null);
    const [collapse, setCollapse] = useState(false);
    const { sessionId } = useParams();
    const navigate = useNavigate(); // Initialize the navigate function
    const [selectedFrameId, setSelectedFrameId] = useState(null);

    const [frameOpen, setFrameOpen] = useState(false);

    const closeFrame = () => setFrameOpen(false);

    useEffect(() => {
        if (!sessionId) {
            newChat();
        }
    }, [sessionId, navigate]);

    function newChat() {
        const newSessionId = uuidv4();
        navigate(`/third/sos/dashboard/${newSessionId}`); // Redirect with the new sessionId
        console.log('frame closed, clearing frame Id');
        setSelectedFrameId(null);
    }

    function loadFrame(frameId) {
        setSelectedFrameId(frameId);
        setFrameOpen(true);
    }

    useEffect(() => {
        if(!frameOpen) {
            console.log('frame closed, clearing frame Id');
            setSelectedFrameId(null);
        }
    }, [frameOpen]);
    

    const openSlackBotMenu = (event) => setSlackBotMenu(event.currentTarget);

    useEffect(() => {
        if (dashboardConnection) {
            dashboardConnection.on("20e1742e-4b87-4bab-9b36-7bd8bea1e7b2", setTheTask);
            LoadThePage();
            return () => {
                dashboardConnection.off("20e1742e-4b87-4bab-9b36-7bd8bea1e7b2", setTheTask);
            }
        }
    }, [dashboardConnection, topLevelTask]);

    function LoadThePage() {
        hitchDashCommand('sos', 'task', 'get', {
            taskId: topLevelTask
        });
    }

    useEffect(() => {
        if (theTask) {
            setScore(theTask.score);
            setMaxScore(theTask.maxScore);
        }
    }, [theTask]);

    useEffect(() => {
        fetchTasks();
        fetchChatMessages();
    }, []);

    const fetchTasks = () => {
        setTasks([
            { id: 1, name: "Task 1", status: "Pending" },
            { id: 2, name: "Task 2", status: "Completed" },
        ]);
    };

    const fetchChatMessages = () => {
        setChatMessages([
            { user: "AI", message: "Your current score is 85. To improve, consider reviewing Task 1." },
        ]);
    };

    const renderMenu = (state, close) => (
        <Menu
            anchorEl={state}
            anchorOrigin={{ vertical: "top", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={Boolean(state)}
            onClose={close}
            keepMounted
        >
            <MenuItem onClick={close}>Action</MenuItem>
            <MenuItem onClick={close}>Another action</MenuItem>
            <MenuItem onClick={close}>Something else here</MenuItem>
        </Menu>
    );

    return (
        <HitchPage title={"Certification Dashboard"} subTitle={"ISO 27001"}>
            <VuiBox pt={6} pb={3}>
                <VuiBox mb={3}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <SosTaskList
                                tasks={theTask?.children}
                                title={theTask?.name}
                                loadFrame={loadFrame}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <StatsCard
                                title="ISO 27001 Score"
                                count={score}
                                max={maxScore}
                                percentage={{
                                    color: "primary",
                                    label: "+5 since last week",
                                }}
                                action={{
                                    type: "internal",
                                    route: `/third/sos/tasks/${topLevelTask}`,
                                    label: "view more",
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <VuiBox p={3}>
                                <HitchTypography variant="h6">Chat with AI Session {sessionId}</HitchTypography>

                                <ChatControl
                                    maxHeight={collapse ? null : "400px"}
                                    chatId={sessionId}
                                    configurationId={'e4e88e6d-1eb2-4ac0-bea8-e405d324fc63'} />

                                <VuiButton color="white" variant="outlined" onClick={() => setCollapse(!collapse)}>
                                    {collapse ? 'Collapse' : 'Expand'}
                                </VuiButton>

                                <VuiButton color="white" variant="outlined" onClick={() => newChat()}>
                                    Clear !
                                </VuiButton>
                            </VuiBox>
                        </Grid>
                    </Grid>
                </VuiBox>
            </VuiBox>

            <HitchFrame 
                open={frameOpen}
                onClose={closeFrame}
            />

        </HitchPage>
    );
}

export default SosDashboard;
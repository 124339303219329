import { Grid } from "@mui/material";
import HitchTypography from "components/HitchTypography";
import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";
import VuiDropzone from "components/VuiDropzone";

function HitchTempFileUpload({ label, onFileUploaded }) {
    return (
        <Grid container spacing={1} alignItems="center" mb={1.5}>
            <Grid item xs={5}>
                <HitchTypography
                    component="label"
                    variant="button"
                    color="white"
                    fontWeight="bold"
                    textTransform="capitalize"
                >
                    {label}:
                </HitchTypography>
            </Grid>
            <Grid item xs={7}>
                <VuiDropzone 
                    options={{ addRemoveLinks: true }} 
                    onFileUploaded={onFileUploaded}
                    path={`${window._env_.HITCH_API}/api/TempFile`} />
            </Grid>
        </Grid>
    );
}

export default HitchTempFileUpload;
// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/components/HitchDateField/index.js


import PropTypes from "prop-types";
import { ErrorMessage, useField } from "formik";
import VuiBox from "components/VuiBox";
import HitchTypography from "components/HitchTypography";
import VuiDatePicker from "components/VuiDatePicker";
import dayjs from "dayjs";
import { useEffect } from "react";

function HitchDateField({ label, name, ...rest }) {
  const [field, , helpers] = useField(name);
  const { setValue } = helpers;

  const handleDateChange = (date) => {
    if (date) {
      const formattedDate = dayjs(date).format("YYYY-MM-DD");
      setValue(formattedDate);
    } else {
      setValue('');
    }
  };

  const parsedDate = field.value ? dayjs(field.value).toDate() : null;

  return (
    <VuiBox mb={1.5}>
      <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
        <HitchTypography
          component="label"
          variant="caption"
          color="white"
          fontWeight="bold"
          textTransform="capitalize"
        >
          {label}
        </HitchTypography>
      </VuiBox>
      <VuiDatePicker
        {...rest}
        value={parsedDate}
        onChange={(dateArray) => handleDateChange(dateArray[0])} // Flatpickr returns an array of dates
      />
      <VuiBox mt={0.75}>
        <HitchTypography component="div" variant="caption" color="error">
          <ErrorMessage name={name} />
        </HitchTypography>
      </VuiBox>
    </VuiBox>
  );
}

// Typechecking props for HitchDateField
HitchDateField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default HitchDateField;

// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/third/sos/risk-management/index.js
 
import Card from "@mui/material/Card";
import VuiBox from "components/VuiBox";
import HitchTypography from "components/HitchTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import HitchDataTableExternalPagination from "components/HitchDataTableExternalPagination";
import { useEffect, useState } from "react";
import { Button, TextField, MenuItem } from "@mui/material";
import { useHubConnections } from "context/HubConnectionsProvider";
import VuiButton from "components/VuiButton";
import { useNavigate } from "react-router-dom";

function SosRiskManagement() {
    const cols = [
        { accessor: 'riskName', Header: 'Risk Name', width: '20%' },
        { accessor: 'riskId', Header: 'Risk ID', width: '10%' },
        { accessor: 'status', Header: 'Status', width: '15%' },
        { accessor: 'impact', Header: 'Impact Level', width: '10%' },
        { accessor: 'likelihood', Header: 'Likelihood Level', width: '10%' },
        { accessor: 'owner', Header: 'Owner', width: '15%' },
        {
            accessor: 'actions',
            Header: 'Actions',
            width: '20%',
            Cell: ({ row }) => (
                <div style={{ display: 'flex', gap: '8px' }}>
                    <VuiButton variant="outlined" color="info" size="small">View</VuiButton>
                    <VuiButton 
                        variant="outlined" 
                        color="warning" 
                        size="small" 
                        disabled={row.original.status !== 'Open'}
                    >
                        Edit
                    </VuiButton>
                    <VuiButton 
                        variant="outlined" 
                        color="error" 
                        size="small" 
                        disabled={row.original.status !== 'Open'}
                    >
                        Archive
                    </VuiButton>
                </div>
            )
        }
    ];

    const navigate = useNavigate();
    const [list, setList] = useState([]);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalResults, setTotalResults] = useState(0);

    const [searchValue, setSearchValue] = useState('');
    const [statusFilter, setStatusFilter] = useState('');
    const [categoryFilter, setCategoryFilter] = useState('');
    const [impactFilter, setImpactFilter] = useState('');

    const [dataTableData, setDataTableData] = useState({
        columns: cols,
        rows: []
    });

    const { dashboardConnection, hitchDashCommand } = useHubConnections();

    useEffect(() => {
        const mockRisks = [
            { riskName: 'Risk A', riskId: '001', status: 'Open', impact: 'High', likelihood: 'Medium', owner: 'IT', category: 'Cybersecurity' },
            { riskName: 'Risk B', riskId: '002', status: 'Mitigated', impact: 'Low', likelihood: 'Low', owner: 'HR', category: 'Compliance' },
            { riskName: 'Risk C', riskId: '003', status: 'Open', impact: 'Medium', likelihood: 'High', owner: 'Finance', category: 'Operational' },
            { riskName: 'Risk D', riskId: '004', status: 'Archived', impact: 'High', likelihood: 'High', owner: 'Security', category: 'Operational' }
        ];

        let filtered = [...mockRisks];

        if (searchValue) {
            filtered = filtered.filter(item =>
                item.riskName.toLowerCase().includes(searchValue.toLowerCase()) ||
                item.riskId.toLowerCase().includes(searchValue.toLowerCase())
            );
        }
        if (statusFilter) {
            filtered = filtered.filter(item => item.status === statusFilter);
        }
        if (categoryFilter) {
            filtered = filtered.filter(item => item.category === categoryFilter);
        }
        if (impactFilter) {
            filtered = filtered.filter(item => item.impact === impactFilter);
        }

        setTotalResults(filtered.length);
        const start = (page - 1) * perPage;
        const end = start + perPage;
        filtered = filtered.slice(start, end);
        setList(filtered);
    }, [page, perPage, searchValue, statusFilter, categoryFilter, impactFilter]);

    useEffect(() => {
        setDataTableData(prev => ({
            ...prev,
            rows: list
        }));
    }, [list]);

    function onPageChange(newPage, newPerPage) {
        setPage(newPage);
        setPerPage(newPerPage);
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <VuiBox pt={6} pb={3}>
                <VuiBox mb={3}>
                    <Card>
                        <VuiBox p={3} pl={0} lineHeight={1}>
                            <HitchTypography variant="h5" fontWeight="medium" color="white">
                                Standards Orchestration System
                            </HitchTypography>
                            <HitchTypography variant="button" fontWeight="regular" color="text">
                                Unified Risk Management (MOCKED)
                                <VuiButton variant="outlined" color="white" onClick={(e) => navigate('./requirements')}>Requirements</VuiButton>
                            </HitchTypography>
                        </VuiBox>
                        <VuiBox p={3} pl={0}>
                            <div style={{ display: 'flex', gap: '16px', marginBottom: '16px', flexWrap: 'wrap' }}>
                                <TextField 
                                    variant="outlined"
                                    size="small"
                                    label="Search"
                                    value={searchValue}
                                    onChange={(e) => setSearchValue(e.target.value)}
                                />
                                <TextField
                                    select
                                    variant="outlined"
                                    size="small"
                                    label="Status"
                                    value={statusFilter}
                                    onChange={(e) => setStatusFilter(e.target.value)}
                                    style={{ width: '150px' }}
                                >
                                    <MenuItem value="">All</MenuItem>
                                    <MenuItem value="Open">Open</MenuItem>
                                    <MenuItem value="Mitigated">Mitigated</MenuItem>
                                    <MenuItem value="Archived">Archived</MenuItem>
                                </TextField>
                                <TextField
                                    select
                                    variant="outlined"
                                    size="small"
                                    label="Category"
                                    value={categoryFilter}
                                    onChange={(e) => setCategoryFilter(e.target.value)}
                                    style={{ width: '150px' }}
                                >
                                    <MenuItem value="">All</MenuItem>
                                    <MenuItem value="Operational">Operational</MenuItem>
                                    <MenuItem value="Cybersecurity">Cybersecurity</MenuItem>
                                    <MenuItem value="Compliance">Compliance</MenuItem>
                                </TextField>
                                <TextField
                                    select
                                    variant="outlined"
                                    size="small"
                                    label="Impact"
                                    value={impactFilter}
                                    onChange={(e) => setImpactFilter(e.target.value)}
                                    style={{ width: '150px' }}
                                >
                                    <MenuItem value="">All</MenuItem>
                                    <MenuItem value="Low">Low</MenuItem>
                                    <MenuItem value="Medium">Medium</MenuItem>
                                    <MenuItem value="High">High</MenuItem>
                                </TextField>
                                <VuiButton variant="outlined" color="white">Create Risk</VuiButton>
                            </div>
                            <HitchDataTableExternalPagination
                                table={dataTableData}
                                canSearch={false}
                                totalResults={totalResults}
                                onPageChange={onPageChange}
                                paginated={true}
                                page={page}
                            />
                        </VuiBox>
                    </Card>
                </VuiBox>
            </VuiBox>
        </DashboardLayout>
    );
}

export default SosRiskManagement;

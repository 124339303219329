// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/components/HitchDisplayBadgeList/index.js


import { Grid } from "@mui/material";
import HitchBadgeList from "components/HitchBadgeList";
import HitchTypography from "components/HitchTypography";
import dayjs from "dayjs"; // Import dayjs for date formatting

function HitchDisplayBadgeList({ label, value, date = false }) { // Default `date` to false here
    // Format the value as a date if the `date` prop is true
    const displayValue = date && value ? dayjs(value).format("YYYY-MM-DD") : value || "N/A";

    return (
        <Grid container alignItems="center" spacing={1}>
            <Grid item xs={5}>
                <HitchTypography variant="button" fontWeight="regular" color="text">
                    {label}:
                </HitchTypography>
            </Grid>
            <Grid item xs={7}>
                {Array.isArray(value) ? (
                    <HitchBadgeList value={value.join(",")} />
                ) : (
                    <HitchBadgeList value={displayValue} />
                )}
            </Grid>
        </Grid>
    );
}

export default HitchDisplayBadgeList;


import Grid from "@mui/material/Grid";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import PinterestIcon from "@mui/icons-material/Pinterest";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import VuiBox from "components/VuiBox";
import HitchTypography from "components/HitchTypography";
import { Link } from "react-router-dom";

function HomepageFooter() {
  return (
    <VuiBox component="footer" py={6}>
      <Grid container justifyContent="center">
        <Grid item xs={10} lg={8}>
          <VuiBox display="flex" justifyContent="center" flexWrap="wrap" mb={3}>
            <VuiBox mr={{ xs: 2, lg: 3, xl: 6 }}>
              <HitchTypography
                component="a"
                href="/privacy-policy"
                variant="body2"
                color="white"
              >
                Privacy Policy
              </HitchTypography>
            </VuiBox>
            {/* <VuiBox mr={{ xs: 2, lg: 3, xl: 6 }}>
              <HitchTypography
                component="a"
                href="/terms-of-service"
                variant="body2"
                color="white"
              >
                Terms of Service
              </HitchTypography>
            </VuiBox>
            <VuiBox mr={{ xs: 0, lg: 3, xl: 6 }}>
              <HitchTypography
                component="a"
                href="/integrations"
                variant="body2"
                color="white"
              >
                Supported Integrations
              </HitchTypography>
            </VuiBox>
            <VuiBox mr={{ xs: 2, lg: 3, xl: 6 }}>
              <HitchTypography
                component="a"
                href="/pricing"
                variant="body2"
                color="white"
              >
                Pricing
              </HitchTypography>
            </VuiBox>
            <VuiBox mr={{ xs: 2, lg: 3, xl: 6 }}>
              <HitchTypography
                component="a"
                href="/register"
                variant="body2"
                color="white"
              >
                Register
              </HitchTypography> 
            </VuiBox> */}
          </VuiBox>
        </Grid>
        <Grid item xs={12} lg={8}>
          <VuiBox display="flex" justifyContent="center" mt={1} mb={3}>
            {/* <VuiBox mr={3} color="white">
              <HitchTypography
                component="a"
                color="white"
                href="https://facebook.com/hitchsoftwareusa"
                target="blank">
                <FacebookIcon
                  fontSize="small"
                />
              </HitchTypography>
            </VuiBox> */}
            {/* <VuiBox mr={3} color="white">
              <TwitterIcon fontSize="small" />
            </VuiBox>
            <VuiBox mr={3} color="white">
              <InstagramIcon fontSize="small" />
            </VuiBox>
            <VuiBox mr={3} color="white">
              <PinterestIcon fontSize="small" />
            </VuiBox>
            <VuiBox color="white">
              <LinkedInIcon fontSize="small" />
            </VuiBox> */}
          </VuiBox>
        </Grid>
        <Grid item xs={12} lg={8} sx={{ textAlign: "center" }}>
          <HitchTypography variant="body2" color="white">
            ©2024 Hitch Software, LLC
          </HitchTypography>
        </Grid>
      </Grid>
    </VuiBox>
  );
}

export default HomepageFooter;

// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE:/UI/hitch-ai-ui-2/src/hitch/components/HitchModal/index.js

import React from "react";
import { Modal, Box, Typography, Button, Backdrop } from "@mui/material";
import VuiBox from "components/VuiBox";

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#000077',
    color: '#FFFFFF',
    borderRadius: '8px',
    boxShadow: 24,
    p: 3,
    outline: 'none',
};

const buttonStyle = {
    minWidth: '80px',
    margin: '0 8px',
};

function HitchModal({
    open,
    onClose,
    title,
    question,
    yesLabel,
    noLabel,
    onYes,
    onNo,
    dismissOnClickOutside = false,
    children, // Add children prop to allow rendering child elements
}) {
    return (
        <Modal
            open={open}
            onClose={dismissOnClickOutside ? onClose : () => {}}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
                style: { backgroundColor: 'rgba(0, 0, 0, 0.7)' },
            }}
        >
            <VuiBox sx={modalStyle}>
                <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Typography variant="h6" fontWeight="bold">
                        {title}
                    </Typography>
                    <Button
                        onClick={onClose}
                        style={{ minWidth: '40px', color: '#000', padding: 0 }}
                    >
                        X
                    </Button>
                </VuiBox>
                <Typography variant="body1" mb={4} textAlign="center">
                    {question}
                </Typography>
                {children && (
                    <VuiBox mb={4}>
                        {children}
                    </VuiBox>
                )}
                <VuiBox display="flex" justifyContent="center" mt={2}>
                    <Button
                        variant="outlined"
                        color="white"
                        onClick={onYes}
                        sx={buttonStyle}
                    >
                        {yesLabel}
                    </Button>
                    <Button
                        variant="outlined"
                        color="warning"
                        onClick={onNo}
                        sx={buttonStyle}
                    >
                        {noLabel}
                    </Button>
                </VuiBox>
            </VuiBox>
        </Modal>
    );
}

export default HitchModal;

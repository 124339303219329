
// FILE: /UI/hitch-ai-ui-2/src/layouts/pages/account/settings/components/Authentication/index.js

import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import VuiBox from "components/VuiBox";
import HitchTypography from "components/HitchTypography";
import VuiButton from "components/VuiButton";
import VuiBadge from "components/VuiBadge";

function Authentication() {
  return (
    <Card id="2fa" sx={{ overflow: "visible" }}>
      <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="32px">
        <HitchTypography variant="lg" color="white" fontWeight="bold">
          Two-factor authentication
        </HitchTypography>
        <VuiBadge variant="contained" color="green" badgeContent="enabled" container />
      </VuiBox>
      <VuiBox>
        <VuiBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ sm: "column", md: "row" }}
        >
          <HitchTypography variant="body2" color="text">
            Security keys
          </HitchTypography>
          <VuiBox
            display="flex"
            alignItems={{ xs: "flex-start", sm: "center" }}
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <VuiBox mx={{ xs: 0, sm: 2 }} mb={{ sm: 1, md: 0 }}>
              <HitchTypography variant="button" color="text" fontWeight="regular">
                No Security keys
              </HitchTypography>
            </VuiBox>
            <VuiButton variant="contained" color="info" size="small">
              add
            </VuiButton>
          </VuiBox>
        </VuiBox>
        <Divider light sx={{ my: "22px" }} />
        <VuiBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ sm: "column", md: "row" }}
        >
          <HitchTypography variant="body2" color="text">
            SMS number
          </HitchTypography>
          <VuiBox
            display="flex"
            alignItems={{ xs: "flex-start", sm: "center" }}
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <VuiBox mx={{ xs: 0, sm: 2 }} mb={{ sm: 1, md: 0 }}>
              <HitchTypography variant="button" color="text" fontWeight="regular">
                +3012374423
              </HitchTypography>
            </VuiBox>
            <VuiButton variant="contained" color="info" size="small">
              edit
            </VuiButton>
          </VuiBox>
        </VuiBox>
        <Divider light sx={{ my: "22px" }} />
        <VuiBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ sm: "column", md: "row" }}
        >
          <HitchTypography variant="body2" color="text">
            Authenticator app
          </HitchTypography>
          <VuiBox
            display="flex"
            alignItems={{ xs: "flex-start", sm: "center" }}
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <VuiBox mx={{ xs: 0, sm: 2 }} mb={{ sm: 1, md: 0 }}>
              <HitchTypography variant="button" color="text" fontWeight="regular">
                Not Configured
              </HitchTypography>
            </VuiBox>
            <VuiButton variant="contained" color="info" size="small">
              set up
            </VuiButton>
          </VuiBox>
        </VuiBox>
      </VuiBox>
    </Card>
  );
}

export default Authentication;

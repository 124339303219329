
import { Grid } from "@mui/material";
import CustomTabPanel from "components/CustomTabPanel";
import HitchFormTextArea from "components/HitchFormTextArea";
import VuiBox from "components/VuiBox";
import HitchTypography from "components/HitchTypography";

function BotAiPrompts({ editMode, handleChange, models, setFieldValue, tab, values }) {
    return (
        <CustomTabPanel value={tab} index={2}>
            <VuiBox pl={0} lineHeight={1}>
                <HitchTypography variant="h5" fontWeight="medium" color="white">
                    Prompts
                </HitchTypography>
                <Grid container>
                <Grid item xs={12}> 
                    <HitchFormTextArea
                        editMode={editMode}
                        label="Initial Welcome Message"
                        name="initialWelcomeMessage"
                    />
                </Grid>
                <Grid item xs={12}>
                    <HitchFormTextArea
                        editMode={editMode}
                        label="Standard Instructions"
                        name="standardInstructions"
                    />
                </Grid>
            </Grid>
            </VuiBox>
        </CustomTabPanel>
    );
}

export default BotAiPrompts;
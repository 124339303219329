import VuiAvatar from "components/VuiAvatar";

function HitchAvatar({hitchId, size = 'md'}) {
    return (
        <VuiAvatar 
            src={`${window._env_.HITCH_API}/api/accimage/${hitchId}.png`} 
            alt={hitchId} 
            size={size} />
    )
}

export default HitchAvatar;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import HitchDataTable from "components/HitchDataTable";
import { useHubConnections } from "context/HubConnectionsProvider";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

function HitchFeatureTable({ eventId, command, columnDefinitions, onLoaded, commandBody, onRowClick, onContextMenu }) {
    const { dashboardConnection, hitchDashCommand } = useHubConnections();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [holdData, setHoldData] = useState([]);
    const [fetchQueue, setFetchQueue] = useState(null); // Handles paging

    // Effect to handle incoming data updates from SignalR
    useEffect(() => {
        if (!dashboardConnection) return;

        const handleDataUpdate = (newData) => {
            const updatedHoldData = [...holdData, ...(newData?.files || [])];
            if (newData.nextPageToken && newData.files?.length) {
                setHoldData(updatedHoldData);
                setFetchQueue({
                    // path: commandBody.path,
                    pageToken: newData.nextPageToken,
                });
            } else {
                // No more pages or no files returned; finalize data and stop pagination
                setData((prevData) => {
                    const mergedData = [...prevData, ...updatedHoldData].reduce((acc, file) => {
                        if (!acc.some((item) => item.id === file.id)) {
                            acc.push(file);
                        }
                        return acc;
                    }, []);
                    return mergedData;
                });
                setHoldData([]); // Clear holdData
                setFetchQueue(null); // Stop further fetches
                setLoading(false); // Mark loading complete
            }
        };

        // Subscribe to the SignalR event
        dashboardConnection.on(eventId, handleDataUpdate);

        // Cleanup subscription on component unmount
        return () => {
            dashboardConnection.off(eventId, handleDataUpdate);
        };
    }, [dashboardConnection, eventId, commandBody, holdData]);

    // Effect to handle initial fetch when commandBody changes
    useEffect(() => {
        if (!dashboardConnection) return;
        // Clear previous data and initiate a new fetch
        setData([]);
        setHoldData([]);
        setFetchQueue(null); // Reset the queue to avoid unintended triggers
        hitchDashCommand(command.service, command.feature, command.operation, commandBody);
        setLoading(true);

    }, [dashboardConnection, commandBody]);

    // Effect to handle paginated fetches when fetchQueue updates
    useEffect(() => {
        if (!fetchQueue || !fetchQueue.pageToken) {
            return;
        }
        hitchDashCommand(command.service, command.feature, command.operation, fetchQueue);
        setLoading(true);
        setFetchQueue(null);
    }, [fetchQueue, command, hitchDashCommand]);

    // Effect to notify when data is loaded
    useEffect(() => {
        if (onLoaded) {
            onLoaded(data);
        }
    }, [data, onLoaded]);

    const tableData = {
        columns: columnDefinitions,
        rows: data,
    };


    return (
        <Box>
            {loading ? (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="300px"
                >
                    <CircularProgress color="primary" />
                </Box>
            ) : (
                <HitchDataTable
                    table={tableData}
                    canSearch
                    showTotalEntries
                    onRowClick={onRowClick}
                    onAddClick={() => console.log("Add new item")}
                    onContextMenu={onContextMenu}
                />
            )}
        </Box>
    );
}

HitchFeatureTable.propTypes = {
    eventId: PropTypes.string.isRequired, // The event ID to subscribe to for data updates
    command: PropTypes.shape({
        service: PropTypes.string.isRequired, // Command service
        feature: PropTypes.string.isRequired, // Command feature
        operation: PropTypes.string.isRequired, // Command operation
    }).isRequired,
    columnDefinitions: PropTypes.arrayOf(
        PropTypes.shape({
            accessor: PropTypes.string.isRequired,
            Header: PropTypes.string.isRequired,
            width: PropTypes.string,
        })
    ).isRequired,
    onRowClick: PropTypes.func, // Callback for row clicks
    onLoaded: PropTypes.func, // Callback for when data is loaded
    commandBody: PropTypes.object.isRequired, // Command body for API calls
    onContextMenu: PropTypes.func, // Add prop validation
};

export default HitchFeatureTable;

import ReactQuill from "react-quill";

// react-quill styles
import "react-quill/dist/quill.snow.css";

// Custom styles for the VuiEditor
import VuiEditorRoot from "components/VuiEditor/VuiEditorRoot";

function VuiEditor(props) {
  return (
    <VuiEditorRoot>
      <ReactQuill theme="snow" {...props} />
    </VuiEditorRoot>
  );
}

export default VuiEditor;

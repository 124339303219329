import React from 'react';
import { Field, ErrorMessage } from 'formik';
import { Grid } from '@mui/material';
import HitchTypography from 'components/HitchTypography';
import MonacoEditor from 'react-monaco-editor';

function HitchCodeField({ editMode, label, name, ...rest }) {
    return (
        <Grid container spacing={1} alignItems="center" mb={1.5}>
            <Grid item xs={5}>
                <HitchTypography
                    component="label"
                    variant="button"
                    color="white"
                    fontWeight="bold"
                    textTransform="capitalize"
                >
                    {label}:
                </HitchTypography>
            </Grid>

            <Grid item xs={7}>
                <Field name={name}>
                    {({ field, form }) => (
                        <MonacoEditor
                            height="200"
                            language="javascript"
                            value={field.value}
                            onChange={(value) => form.setFieldValue(name, value)}
                            options={{
                                selectOnLineNumbers: true,
                                readOnly: !editMode, // Set readOnly based on editMode
                                ...rest.options // Additional options can be passed
                            }}
                        />
                    )}
                </Field>
            </Grid>

            <Grid item xs={12}>
                <HitchTypography component="div" variant="caption" color="error">
                    <ErrorMessage name={name} />
                </HitchTypography>
            </Grid>
        </Grid>
    );
}

export default HitchCodeField;

// https://uat.hitchsoftware.com/chat/general/75c548a0-cd4e-4d5f-a6ba-8b4d86286bbd

import React, { useState, useEffect } from 'react';
import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";
import HitchTypography from "components/HitchTypography";
import VuiInput from "components/VuiInput";
import VuiSelect from "components/VuiSelect";
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useHubConnections } from '../../context/HubConnectionsProvider';

function OperationSelectionModal({ open, setOpen, onSelect }) {
    const { dashboardConnection, isConnectionReady, hitchDashCommand, hitchUser } = useHubConnections();
    const [matrixRoutes, setMatrixRoutes] = useState([]); // Initialize as an empty array
    const [service, setService] = useState('');
    const [feature, setFeature] = useState('');
    const [operation, setOperation] = useState('');

    // Define menuIsOpen state variable
    const [menuIsOpen, setMenuIsOpen] = useState(false);

    useEffect(() => {
        if (dashboardConnection && isConnectionReady) {
            dashboardConnection.on("72d23de7-efb6-45c1-b67a-e06bd00d4739", setMatrixRoutes);
            fetchMatrixRoutes();
        }

        return () => {
            if (dashboardConnection) {
                dashboardConnection.off("72d23de7-efb6-45c1-b67a-e06bd00d4739");
            }
        };
    }, [dashboardConnection, isConnectionReady]);

    useEffect(() => {
        if (open && dashboardConnection && isConnectionReady && hitchUser) {
            fetchMatrixRoutes();
        }
    }, [open, dashboardConnection, isConnectionReady, hitchUser]); // Fetch routes only when the modal is open and connection is ready

    // Function to fetch matrix routes
    function fetchMatrixRoutes() {
        hitchDashCommand('account', 'connection', 'route', {
            theAccountId: hitchUser.accountId
        });
    }

    // Function to call hitchDashCommand with service ID to get features
    function callForRoutesService(serviceId) {
        const route = matrixRoutes.find(route => route.system === serviceId);

        if (route) {
            hitchDashCommand('account', 'connection', 'route', {
                TheServiceId: route.serviceId, // Ensure serviceId is passed correctly
            });
        } else {
            console.error('No matching route found for serviceId:', serviceId);
        }
    }

    // Function to call hitchDashCommand with feature ID to get operations
    function callForRoutesFeature(featureId) {
        const route = matrixRoutes.find(route => route.operationType === featureId);

        if (route) {
            hitchDashCommand('account', 'connection', 'route', {
                TheFeatureId: route.featureId, // Ensure featureId is passed correctly
            });
        } else {
            console.error('No matching route found for featureId:', featureId);
        }
    }

    // When service changes, reset feature and operation, and fetch features for the selected service
    const handleServiceChange = (selectedOption) => {
        if (selectedOption && selectedOption.value) {
            setService(selectedOption.value); // Set the service with selected value
            setFeature(''); // Reset feature when service changes
            setOperation(''); // Reset operation when service changes

            // Call the function to get features for the selected service
            callForRoutesService(selectedOption.value);
        } else {
            // If selectedOption is null or undefined, reset service, feature, and operation
            setService('');
            setFeature('');
            setOperation('');
        }
    };

    // When feature changes, reset operation and fetch operations for the selected feature
    const handleFeatureChange = (selectedOption) => {
        if (selectedOption && selectedOption.value) {
            setFeature(selectedOption.value); // Set the feature with selected value
            setOperation(''); // Reset operation when feature changes

            // Call the function to get operations for the selected feature
            callForRoutesFeature(selectedOption.value);
        } else {
            setFeature('');
            setOperation('');
        }
    };

    const handleOperationChange = (selectedOption) => {
        if (selectedOption && selectedOption.value) {
            setOperation(selectedOption.value);
        } else {
            setOperation('');
        }
    };

    const handleClose = () => setOpen(false);
    const handleAdd = () => {
        onSelect({ service, feature, operation });
        setOpen(false); // Close modal after adding the operation
    };

    // Filter unique services
    const serviceOptions = Array.from(new Set(matrixRoutes.map(item => item.system)))
        .filter(system => system && system !== "")
        .map(system => ({ label: system, value: system }));

    // Filter features based on selected service
    const featureOptions = matrixRoutes
        .filter(item => item.system === service && item.operationType && item.operationType.trim() !== "")
        .map(item => item.operationType)
        .filter((value, index, self) => self.indexOf(value) === index) // Get unique features
        .map(operationType => ({ label: operationType, value: operationType }));

    // Filter operations based on selected service and feature
    const operationOptions = matrixRoutes
        .filter(item => item.system === service && item.operationType === feature && item.operationSubtype && item.operationSubtype.trim() !== "")
        .map(item => item.operationSubtype)
        .filter((value, index, self) => self.indexOf(value) === index) // Get unique operations
        .map(operationSubtype => ({ label: operationSubtype, value: operationSubtype }));

    const customStyles = {
        menu: (provided) => ({
            ...provided,
            zIndex: 1051, // Ensure this is higher than the modal
            position: 'absolute', // Use absolute positioning to align with the input
        }),
    };

    return (
        <Dialog
            open={open}
            onClose={(_, reason) => {
                if (reason !== "backdropClick") {
                  setOpen(false);
                }
              }}
            maxWidth="md"
            fullWidth
            PaperProps={{
                style: {
                    overflow: 'visible', // Allow the dropdowns to overflow outside the modal
                    zIndex: 1050, // Ensure z-index is lower than dropdown
                    position: 'relative', // Ensure dropdown can appear above
                },
            }}
        >
            <DialogTitle style={{ zIndex: 1050 }}>Add New Operation</DialogTitle>
            <DialogContent
                style={{
                    overflow: 'visible', // Ensure dropdowns don't get cut off inside DialogContent
                    zIndex: 1050, // Ensure z-index is lower than dropdown
                    position: 'relative', // Ensure dropdown can appear above
                }}
            >
                <VuiBox>
                    <VuiBox mb={2}>
                        <HitchTypography variant="caption" color="white" fontWeight="bold">
                            Service
                        </HitchTypography>
                        <VuiSelect
                            input={<VuiInput />}
                            value={serviceOptions.find(option => option.value === service)} 
                            onChange={handleServiceChange} 
                            placeholder="Select a Service"
                            fullWidth
                            options={serviceOptions}
                            styles={customStyles} // Apply the custom styles
                            menuIsOpen={menuIsOpen} // Use menuIsOpen state correctly
                            onMenuOpen={() => setMenuIsOpen(true)} // Update to onMenuOpen event
                            onMenuClose={() => setMenuIsOpen(false)} // Update to onMenuClose event
                            onBlur={(event) => event.preventDefault()} // Prevent blur events to avoid closing dropdown
                        />
                    </VuiBox>

                    <VuiBox mb={2}>
                        <HitchTypography variant="caption" color="white" fontWeight="bold">
                            Feature
                        </HitchTypography>
                        <VuiSelect
                            input={<VuiInput />}
                            value={featureOptions.find(option => option.value === feature)} // Ensure correct value object is selected
                            onChange={handleFeatureChange} // Updated onChange handler
                            placeholder="Select a Feature"
                            fullWidth
                            disabled={!service} // Disable if no service is selected
                            options={featureOptions}
                            styles={customStyles} // Apply the custom styles
                            menuPosition="fixed" // Ensure fixed position for menu
                        />
                    </VuiBox>

                    <VuiBox mb={2}>
                        <HitchTypography variant="caption" color="white" fontWeight="bold">
                            Operation
                        </HitchTypography>
                        <VuiSelect
                            input={<VuiInput />}
                            value={operationOptions.find(option => option.value === operation)} // Ensure correct value object is selected
                            onChange={handleOperationChange} // Updated onChange handler
                            placeholder="Select an Operation"
                            fullWidth
                            disabled={!feature} // Disable if no feature is selected
                            options={operationOptions}
                            styles={customStyles} // Apply the custom styles
                            menuPosition="fixed" // Ensure fixed position for menu
                        />
                    </VuiBox>
                </VuiBox>
            </DialogContent>
            <DialogActions>
                <VuiButton onClick={handleClose} variant="outlined" color="primary">
                    Cancel
                </VuiButton>
                <VuiButton onClick={handleAdd} variant="contained" color="primary" disabled={!service || !feature || !operation}>
                    Add
                </VuiButton>
            </DialogActions>
        </Dialog>
    );
}

export default OperationSelectionModal;

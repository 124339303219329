


// FILE: /UI/hitch-ai-ui-2/src/examples/Cards/MessageCard/index.js

import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import VuiBox from "components/VuiBox";
import HitchTypography from "components/HitchTypography";
import VuiButton from "components/VuiButton";

function MessageCard({ image, text, action }) {
  return (
    <Card>
      <VuiBox>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={4}>
            <VuiBox
              component="img"
              src={image}
              alt="message-image"
              borderRadius="lg"
              shadow="md"
              width="100%"
              display="inherit"
            />
          </Grid>
          <Grid item xs={8}>
            <VuiBox mb={2} lineHeight={1.4}>
              <HitchTypography
                fontSize={16}
                color="white"
                sx={({ breakpoints }) => ({
                  [breakpoints.only("sm")]: {
                    fontSize: "14px",
                  },
                })}
                fontWeight="regular"
              >
                {text}
              </HitchTypography>
            </VuiBox>
            {action.type === "internal" ? (
              <VuiButton
                component={Link}
                to={action.route}
                color={action.color}
                variant="contained"
                width="135px"
                height="35px"
                size="small"
              >
                {action.label}
              </VuiButton>
            ) : (
              <VuiButton
                component="a"
                href={action.route}
                target="_blank"
                rel="noreferrer"
                color={action.color}
                variant="contained"
                width="135px"
                height="35px"
                size="small"
              >
                {action.label}
              </VuiButton>
            )}
          </Grid>
        </Grid>
      </VuiBox>
    </Card>
  );
}

// Typechecking props for the MessageCard
MessageCard.propTypes = {
  image: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "default",
      "primary",
      "secondary",
      "info",
      "warning",
      "error",
      "light",
      "dark",
    ]).isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
};

export default MessageCard;

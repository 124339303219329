
import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import { Upload } from '@mui/icons-material';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrashAlt, faDownload } from "@fortawesome/free-solid-svg-icons"; // Using free solid icons
import VuiButton from 'components/VuiButton';
import VuiBox from 'components/VuiBox';
import HitchTypography from 'components/HitchTypography';
import VuiInput from 'components/VuiInput'; // Replacing TextField with VuiInput
import { useTheme } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import { useHubConnections } from 'context/HubConnectionsProvider';
import DeleteConfirmModal from './DeleteConfirmModal';

function StoreDocumentsGrid({ path, handleUpload, handleView, handleDownload }) {
  const theme = useTheme();
  const { guid } = useParams();
  const { dashboardConnection, hitchDashCommand, isConnectionReady } = useHubConnections();
  
  const [searchTerm, setSearchTerm] = useState('');
  const [fileToDelete, setFileToDelete] = useState(null);
  const [files, setFiles] = useState([]);
  const [open, setOpen] = useState(false);
  const [failCount, setFailCount] = useState(0);

  const filteredFiles = Array.isArray(files) ? files.filter(file =>
    file?.name?.toLowerCase().includes(searchTerm?.toLowerCase())
  ) : [];

  function filesList(fileListData) {
    if (fileListData && fileListData.stackTrace) {
      setFailCount(failCount + 1);
      if (fileListData.message === "Invalid Repository Id") {
        loadFromServer();
      }
      return;
    }
    setFiles(fileListData || []);
    setFailCount(0);
  }

  const handleClose = () => {
    setOpen(false);
    setFileToDelete(null);
  };

  const handleDeleteFile = (fileId) => {
    setFileToDelete(fileId);
    setOpen(true);
  };

  const confirmDelete = () => {
    hitchDashCommand('document', 'file', 'delete', { 'RepositoryId': guid, 'FileId': fileToDelete }, () => { });
    setOpen(false);
  };

  const loadFromServer = () => {
    if (failCount < 5) {
      hitchDashCommand('document', 'file', 'list', { 'RepositoryId': guid }, filesList);
    }
  };

  useEffect(() => {
    if (dashboardConnection && isConnectionReady && guid) {
      dashboardConnection.on("8df1b111-2b65-4f11-a8e3-a9f268b90875", filesList);
      loadFromServer();
    }

    return () => {
      if (dashboardConnection) {
        dashboardConnection.off("8df1b111-2b65-4f11-a8e3-a9f268b90875", filesList);
      }
    };
  }, [dashboardConnection, isConnectionReady, guid, hitchDashCommand]);

  return (
    <VuiBox>
      <HitchTypography variant="h5" color="white" mb={2}>
        Document Storage
      </HitchTypography>
      <VuiBox display="flex" alignItems="center" mb={2}>
        <VuiInput
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search Uploaded Files..."
          fullWidth
          sx={{
            mb: 2,
            '& input': {
              color: theme.palette.text.primary,
              fontFamily: '"Poppins", sans-serif',
              background: theme.palette.background.default,
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.divider,
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.primary.main,
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.primary.main,
            },
          }}
        />
      </VuiBox>
      <VuiBox mb={2}>
        <VuiButton variant="contained" component="label" color="primary">
          <Upload /> Upload File
          <input type="file" hidden onChange={handleUpload} />
        </VuiButton>
      </VuiBox>
      {filteredFiles.length > 0 ? (
        <TableContainer component={Paper} sx={{ backgroundColor: theme.palette.background.paper, borderRadius: '8px' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ color: theme.palette.text.primary, fontFamily: '"Poppins", sans-serif' }}>Name</TableCell>
                <TableCell sx={{ color: theme.palette.text.primary, fontFamily: '"Poppins", sans-serif' }}>Date</TableCell>
                <TableCell sx={{ color: theme.palette.text.primary, fontFamily: '"Poppins", sans-serif' }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredFiles.map((file) => (
                <TableRow key={file.id}>
                  <TableCell sx={{ color: theme.palette.text.secondary, fontFamily: '"Poppins", sans-serif' }}>{file.name}</TableCell>
                  <TableCell sx={{ color: theme.palette.text.secondary, fontFamily: '"Poppins", sans-serif' }}>{file.date}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleDownload(file.id)} sx={{ color: theme.palette.text.primary }}>
                      <FontAwesomeIcon icon={faDownload} />
                    </IconButton>
                    <IconButton onClick={() => handleView(file.id)} sx={{ color: theme.palette.text.primary }}>
                      <FontAwesomeIcon icon={faEye} />
                    </IconButton>
                    <IconButton onClick={() => handleDeleteFile(file.id)} sx={{ color: theme.palette.text.primary }}>
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <HitchTypography variant="body2" color="white" align="center" sx={{ mt: 2 }}>
          No files found. Upload a document to get started.
        </HitchTypography>
      )}

      <DeleteConfirmModal
        title="File Delete"
        content="Are you sure you want to delete this file? This action is not reversible!"
        labelNo="No"
        actionNo={handleClose}
        labelYes="Yes"
        actionYes={confirmDelete}
        open={open}
        onClose={handleClose}
      />
    </VuiBox>
  );
}

export default StoreDocumentsGrid;


// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/hitch/plugins/documentRepository/index.js

import React, { useEffect, useState } from "react";
import { Grid, Card } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import VuiBox from "components/VuiBox";
import HitchTypography from "components/HitchTypography";
import VuiButton from "components/VuiButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import Footer from "examples/Footer";
import { useHubConnections } from "context/HubConnectionsProvider";
import HitchDataTable from "components/HitchDataTable";

function DocumentRepository() {
    const navigate = useNavigate();
    const { dashboardConnection, hitchDashCommand, isConnectionReady } = useHubConnections();
    const [data, setData] = useState([]);

    useEffect(() => {
        if (dashboardConnection && isConnectionReady) {
            dashboardConnection.on("ab183cbd-c1a8-4bf3-a264-c8fa7d58660c", handleData);
            hitchDashCommand('document', 'repo', 'list', {});
            return () => {
                // Cleanup on unmount
                if (dashboardConnection) {
                    dashboardConnection.off("ab183cbd-c1a8-4bf3-a264-c8fa7d58660c", handleData);
                }
            };
        }
    }, [dashboardConnection, isConnectionReady]);

    function handleData(receivedData) {
        const formattedData = receivedData.map((item) => ({
            id: item.id,
            name: item.name,
            documentCount: item.documentCount,
            botCount: item.botCount,
        }));
        setData(formattedData);
    }

    function createNewStore() {
        let newId = uuidv4();
        navigate(`/integrations/hitch/document-repository/${newId}`);
    }

    const tableData = {
        columns: [
            { Header: "ID", accessor: "id", width: "30%" },
            { Header: "Store Name", accessor: "name", width: "30%" },
            { Header: "Documents", accessor: "documentCount", align: "right", width: "20%" },
            { Header: "Bots Attached", accessor: "botCount", align: "right", width: "20%" },
        ],
        rows: data,
    };

    return (
        <DashboardLayout stickyNavbar>
            <DashboardNavbar />
            <VuiBox mt={{ xs: 4, md: 10 }} mb={{ xs: 4, md: 8 }}>
                <Grid container justifyContent="center">
                    <Grid item xs={12} md={10}>
                        <Card>
                            <VuiBox p={3}>
                                <Grid container justifyContent="space-between" alignItems="center">
                                    <Grid item>
                                        <HitchTypography
                                            variant="h3"
                                            textGradient
                                            color="info"
                                            fontWeight="bold"
                                        >
                                            Hitch.AI Document Repositories
                                        </HitchTypography>
                                    </Grid>
                                    <Grid item>
                                        <VuiButton variant="contained" color="info" onClick={createNewStore}>
                                            Create a Store
                                        </VuiButton>
                                    </Grid>
                                </Grid>
                            </VuiBox>

                            <VuiBox
                                p={3}
                                sx={{
                                    height: 'auto',
                                    overflow: 'auto'
                                }}
                            >
                                <HitchDataTable
                                    table={tableData}
                                    entriesPerPage={{ defaultValue: 10, entries: [5, 10, 15, 20, 25] }}
                                    canSearch
                                    showTotalEntries
                                    isSorted
                                    onRowClick={(row) => navigate(`/integrations/hitch/document-repository/${row.id}`)}
                                />
                            </VuiBox>

                        </Card>
                    </Grid>
                </Grid>
            </VuiBox>
            <Footer />
        </DashboardLayout>
    );
}

export default DocumentRepository;

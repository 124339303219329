
// FILE: /UI/hitch-ai-ui-2/src/examples/Cards/InfoCards/DefaultInfoCard/index.js

import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import VuiBox from "components/VuiBox";
import HitchTypography from "components/HitchTypography";

function DefaultInfoCard({ color = "info", icon, title, description = "", value = "" }) {
  return (
    <Card sx={{ height: "100%", pt: "25px" }}>
      <VuiBox display="flex" justifyContent="center" mb="22px">
        <VuiBox
          display="grid"
          justifyContent="center"
          alignItems="center"
          bgColor={color}
          color="white"
          width="64px"
          height="64px"
          shadow="md"
          borderRadius="lg"
        >
          {icon}
        </VuiBox>
      </VuiBox>
      <VuiBox textAlign="center" lineHeight={1.25} display="flex" flexDirection="column">
        <HitchTypography variant="lg" color="white" textTransform="capitalize" mb="4px">
          {title}
        </HitchTypography>
        {description && (
          <HitchTypography variant="caption" color="text" fontWeight="regular">
            {description}
          </HitchTypography>
        )}
        {description && !value ? null : <Divider light />}
        {value && (
          <HitchTypography variant="lg" color="white" fontWeight="bold" fontSize={{ xl: 20 }}>
            {value}
          </HitchTypography>
        )}
      </VuiBox>
    </Card>
  );
}

// Typechecking props for the DefaultInfoCard
DefaultInfoCard.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default DefaultInfoCard;

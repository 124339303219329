
// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/third/HC5E92791_B9E7_4D7E_AE22_90CCE4EC0B43/Labels/index.js

import { Card } from "@mui/material";
import HitchDataTable from "components/HitchDataTable";
import HitchDataTableExternalPagination from "components/HitchDataTableExternalPagination";
import HitchSelectSimple from "components/HitchSelectSimple";
import VuiBox from "components/VuiBox";
import HitchTypography from "components/HitchTypography";
import { useHubConnections } from "context/HubConnectionsProvider";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";


function GitHubLabels() {
    const cols = [
        { accessor: 'id', Header: 'Id', width: '20%' },
        { 
            accessor: 'name', 
            Header: 'Label Name', 
            width: '25%', 
            Cell: ({ row }) => <LabelBadge label={row.original} /> // Render the badge here
        },
        { accessor: 'description', Header: 'Description', width: '55%' },
    ];

    const [list, setList] = useState([]);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalResults, setTotalResults] = useState(11);
    const [selectedRepo, setSelectedRepo] = useState(null);
    const [repos, setRepos] = useState([]);

    const [dataTableData, setDataTableData] = useState({
        columns: cols,
        rows: []
    });

    const {
        dashboardConnection,
        hitchDashCommand,
    } = useHubConnections();

    useEffect(() => {
        if (dashboardConnection) {
            const subscribeToEvents = () => {
                console.log('subscribing to events!');
                dashboardConnection.on("d21c653b-05ed-41ed-8413-ee83d8b5c7b1", (data) => {
                    console.log('d21c653b-05ed-41ed-8413-ee83d8b5c7b1', data);
                    setList(data);
                });
                dashboardConnection.on("2fb972b7-9d11-45a1-b629-24d02eeeca97", (data) => {
                    setRepos(data);
                });

                hitchDashCommand('github', 'repositoriesrepositories', 'list', {
                    page: page, // DEFAULTS TO 1
                    per_page: 100 // DEFAULTS TO 10
                });


            };

            subscribeToEvents();

            return () => {
                dashboardConnection.off('d21c653b-05ed-41ed-8413-ee83d8b5c7b1');
                dashboardConnection.off('2fb972b7-9d11-45a1-b629-24d02eeeca97');
            };
        }
    }, [dashboardConnection]);

    useEffect(() => {
        if (dashboardConnection && page && perPage && selectedRepo) {
            console.log('trying');
            const selectedRepository = repos.find((repo) => repo.id === selectedRepo);

            hitchDashCommand('github', 'issueslabels', 'list-for-repo', {
                repoId: selectedRepository.name,
                page: page, // DEFAULTS TO 1
                per_page: perPage, // DEFAULTS TO 10
            });
        }
    }, [dashboardConnection, page, perPage, selectedRepo]);

    const navigate = useNavigate();

    const handleRowClick = (row) => {
        const selectedRepository = repos.find((repo) => repo.id === selectedRepo);
        const selectedRow = list.find((issue) => issue.id == row.id);
        console.log('row', row);
        navigate(`/third/HC5E92791_B9E7_4D7E_AE22_90CCE4EC0B43/GitHubRepositories/${selectedRepository.name}/Labels/${selectedRow.name}`);
    };

    useEffect(() => {
        if (list) {
            if (list === "An Error Has Occurred.") {
                console.error(list);
                return;
            }

            console.log('THE LIST!', list);
            setDataTableData((prevState) => ({
                ...prevState,
                rows: list
            }));
        }
    }, [list]);

    function onPageChange(page, perPage) {
        setPage(page);
        setPerPage(perPage);
        setTotalResults(page * perPage + 1);
    }

    function onRepoChanged(repoId) {
        console.log('onRepoChanged', repoId);
        setSelectedRepo(repoId);
    }

    // Component to render a badge for a single label
    const LabelBadge = ({ label }) => {
        const backgroundColor = `#${label.color}`;
        const textColor = getTextColor(backgroundColor);

        return (
            <div
                style={{
                    display: "inline-block",
                    backgroundColor,
                    color: textColor,
                    borderRadius: "12px",
                    padding: "4px 12px",
                    fontSize: "0.75rem",
                    fontWeight: "bold",
                    textAlign: "center",
                }}
            >
                {label.name}
            </div>
        );
    };

    const getTextColor = (backgroundColor) => {
        const hex = backgroundColor.replace("#", "");
        const r = parseInt(hex.substr(0, 2), 16);
        const g = parseInt(hex.substr(2, 2), 16);
        const b = parseInt(hex.substr(4, 2), 16);
        const brightness = (r * 299 + g * 587 + b * 114) / 1000;
        return brightness > 128 ? "#000000" : "#FFFFFF"; // Dark text on light background, white text on dark background
    };



    const sortedRepos = [...repos].sort((a, b) => a.name.localeCompare(b.name));

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <VuiBox pt={6} pb={3}>
                <VuiBox mb={3}>
                    <Card>
                        <VuiBox p={3} pl={0} lineHeight={1}>
                            <HitchTypography variant="h5" fontWeight="medium" color="white">
                                Hitch.AI GitHub Repo Labels
                            </HitchTypography>
                            <HitchTypography variant="button" fontWeight="regular" color="text">
                                The Labels.
                            </HitchTypography>
                        </VuiBox>
                        <VuiBox p={3}>
                            <HitchSelectSimple
                                value={selectedRepo}
                                label="Repository"
                                options={sortedRepos.map((option) => ({
                                    label: option.name,
                                    value: option.id,
                                }))}
                                placeholder="Select repository"
                                onChange={(e) => onRepoChanged(e)}
                                isClearable
                            />
                        </VuiBox>
                        <HitchDataTableExternalPagination
                            table={dataTableData}
                            canSearch
                            onRowClick={handleRowClick}
                            totalResults={totalResults}
                            onPageChange={onPageChange}
                            paginated={true}
                            page={page}
                        />
                    </Card>
                </VuiBox>
            </VuiBox>
        </DashboardLayout>
    );
}

export default GitHubLabels;

// FILE: /UI/hitch-ai-ui-2/src/assets/theme/components/iconButton.js


import colors from "assets/theme/base/colors";

const { transparent } = colors;

export default {
  styleOverrides: {
    root: {
      "&:hover": {
        backgroundColor: transparent.main,
      },
    },
  },
};

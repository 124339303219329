
import CustomTabPanel from "components/CustomTabPanel";
import VuiBox from "components/VuiBox";
import HitchTypography from "components/HitchTypography";
import HitchDataTable from "components/HitchDataTable";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useHubConnections } from "context/HubConnectionsProvider";
import VuiButton from "components/VuiButton";
import { v4 as uuidv4 } from 'uuid';

function BotConfigurations({
    tab,
}) {
    const { botId } = useParams();
    const navigate = useNavigate();

    const cols = [
        { Header: "Configuration", accessor: "name", width: "50%" },
        { Header: "Mode Id", accessor: "modeId", align: "start", width: "50%" },
    ];

    const { dashboardConnection, hitchDashCommand, isConnectionReady, hitchUser } = useHubConnections();

    const [dataTableData, setDataTableData] = useState({
        columns: cols,
        rows: [],
    });

    function newConfiguration() {
        navigate(`./${uuidv4()}`);
    }

    function handleRowClick(row) {
        navigate(`./${row.id}`);
    }

    useEffect(() => {
        console.log('no');
        if (dashboardConnection && isConnectionReady && tab === 6) {
            // setLoading(true); // Start loading when fetching data
            const subscribeToEvents = () => {
                dashboardConnection.on("cc35e575-2034-4acc-acdb-8dd7e44f7d81", (data) => {
                    console.log("Options received:", data);
                    if (Array.isArray(data)) {
                        setDataTableData((prevState) => ({
                            ...prevState,
                            rows: data
                        }));
                    }
                });

            };

            subscribeToEvents();
            console.log('calling for list-configs');
            hitchDashCommand("chat", "bot", "list-configs", { botId: botId });

            return () => {
                dashboardConnection.off("cc35e575-2034-4acc-acdb-8dd7e44f7d81");
                // setLoading(false); // Ensure loading state is reset if unsubscribed
            };
        }
    }, [dashboardConnection, isConnectionReady, tab]);

    return (
        <CustomTabPanel value={tab} index={6}>
            <VuiBox pl={0} lineHeight={1}>
                <HitchTypography variant="h5" fontWeight="medium" color="white">
                    Configurations
                </HitchTypography>
                <VuiButton
                    color="white"
                    variant="outlined"
                    onClick={() => newConfiguration()}
                >new</VuiButton>
                <HitchDataTable
                    table={dataTableData}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    isSorted={false}
                    renderRow={(row) => (
                        <div key={row.key}>
                            {row.name}
                            {row.avatar}
                        </div>
                    )}
                    onRowClick={(row) => handleRowClick(row)}
                />
            </VuiBox>
        </CustomTabPanel>
    );
}

export default BotConfigurations;
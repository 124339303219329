
// FILE: /UI/hitch-ai-ui-2/src/examples/Footer/index.js

import PropTypes from "prop-types";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import VuiBox from "components/VuiBox";
import HitchTypography from "components/HitchTypography";
import typography from "assets/theme/base/typography";

function Footer({ links = defaultLinks }) {
  const { size } = typography;

  const renderLinks = () =>
    links.map((link) => (
      <VuiBox key={link.name} component="li" px={2} lineHeight={1}>
        <Link href={link.href} target="_blank">
          <HitchTypography variant="button" fontWeight="medium" color="white">
            {link.name}
          </HitchTypography>
        </Link>
      </VuiBox>
    ));

  return (
    <VuiBox
      width="100%"
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="space-between"
      alignItems="center"
      px={1.5}
    >
      <VuiBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        color="white"
        fontSize={size.sm}
        px={1.5}
      >
        &copy; {new Date().getFullYear()}, Hitch Software, LLC
      </VuiBox>
      <VuiBox
        component="ul"
        sx={({ breakpoints }) => ({
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          listStyle: "none",
          mt: 3,
          mb: 0,
          p: 0,

          [breakpoints.up("lg")]: {
            mt: 0,
          },
        })}
      >
        {renderLinks()}
      </VuiBox>
    </VuiBox>
  );
}

// Default links value
const defaultLinks = [
  { href: "https://hitchsoftware.com/", name: "Hitch Software" },
  { href: "https://ai.hitchsoftware.com/", name: "Portal Login" },
  { href: "https://www.youtube.com/@HitchSoftware", name: "Hitch on You Tube" },
  { href: "https://x.com/hitchsoftware", name: "Hitch on X" },
];

// Typechecking props for the Footer
Footer.propTypes = {
  links: PropTypes.arrayOf(PropTypes.object),
};

export default Footer;


import { Container } from "@mui/material";
import VuiBox from "components/VuiBox";
import HitchTypography from "components/HitchTypography";
import PageLayout from "examples/LayoutContainers/PageLayout";
import HomepageFooter from "homepage/HomepageFooter";

function PrivacyPolicy() {
    return (
        <PageLayout>
            <Container>
                <VuiBox>
                    <VuiBox mt={8}>
                        <HitchTypography fontSize={32} color="white" fontWeight="bold">
                            Privacy Policy
                        </HitchTypography>
                    </VuiBox>
                    <VuiBox mt={8}>
                        <HitchTypography fontSize={16} color="white" fontWeight="regular">
                            <strong>Effective Date:</strong> December 1, 2024
                        </HitchTypography>
                        <HitchTypography fontSize={16} color="white" mt={2}>
                            <strong>Introduction</strong>
                            <br />
                            Hitch.AI, provided by Hitch Software, LLC (referred to as "we," "our," or "us"),
                            is committed to protecting your privacy. This Privacy Policy outlines how we
                            collect, use, and safeguard your information when you use our platform. By
                            accessing or using Hitch.AI, you agree to the terms of this Privacy Policy.
                        </HitchTypography>
                        <HitchTypography fontSize={16} color="white" mt={4}>
                            <strong>1. Information We Collect</strong>
                            <br />
                            We collect and store information that you provide to us directly or through integrations with third-party services, such as OAuth.
                            <ul>
                                <li><strong>User-Provided Data:</strong> This includes information you input into the platform, such as documents and other data files you upload. This data is stored in a dedicated, secure storage space and remains your property.</li>
                                <li><strong>OAuth Integration Data:</strong> We utilize OAuth to facilitate secure access to third-party services, specifically Google Docs and Google Drive APIs. This allows you to connect your Google accounts to Hitch.AI, enabling the creation, editing, and organization of policy documents. We do not store your OAuth credentials or access tokens. Instead, access tokens are securely managed through Google's OAuth framework and are used solely to provide the requested functionalities.</li>
                                <li><strong>Usage Data:</strong> We may collect anonymous usage data to improve the functionality and performance of our platform. This does not include any data you upload or access via OAuth integrations.</li>
                            </ul>
                        </HitchTypography>
                        <HitchTypography fontSize={16} color="white" mt={4}>
                            <strong>2. How We Use Your Data</strong>
                            <br />
                            <ul>
                                <li><strong>Storage and Accessibility:</strong> Your uploaded data is securely stored in Google Drive through OAuth-integrated APIs and is only accessible to you or those you authorize. We do not view, use, or share your data unless required to do so by law.</li>
                                <li><strong>Service Functionality:</strong> We use OAuth integrations with Google Docs and Google Drive APIs to enable the platform’s features, such as creating, editing, and organizing policy documents. This integration allows for real-time collaboration and version control within your policy management workflow.</li>
                                <li><strong>Platform Improvement:</strong> Aggregated and anonymized data may be analyzed to improve our services. This process excludes any personal or user-uploaded data.</li>
                            </ul>
                        </HitchTypography>
                        <HitchTypography fontSize={16} color="white" mt={4}>
                            <strong>3. Data Sharing and Disclosure</strong>
                            <br />
                            <ul>
                                <li><strong>Third-Party Services:</strong> We integrate with Google Docs and Google Drive APIs to enhance our platform's functionality. These integrations allow us to provide seamless document creation, editing, and storage capabilities. Google may have access to certain data as outlined in their [Privacy Policy](https://policies.google.com/privacy), but we do not share your data with any other third parties.</li>
                                <li><strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of all or a portion of our assets, your data may be transferred as part of that transaction. We will notify you via email and/or a prominent notice on our platform of any change in ownership or uses of your personal information, as well as any choices you may have regarding your personal information.</li>
                                <li><strong>Legal Requirements:</strong> We may disclose your information if required to do so by law or in response to valid requests by public authorities (e.g., a court or government agency).</li>
                            </ul>
                        </HitchTypography>
                        <HitchTypography fontSize={16} color="white" mt={4}>
                            <strong>4. Data Storage and Security</strong>
                            <br />
                            <ul>
                                <li><strong>Storage Practices:</strong> All user-uploaded data is stored securely in Google Drive, utilizing Google's robust infrastructure and security measures. We leverage Google's data centers, which employ advanced physical and digital security protocols to protect your information.</li>
                                <li><strong>Security Measures:</strong> We implement industry-standard security practices, including encryption of data in transit using HTTPS and encryption at rest provided by Google. Access to your data is controlled through OAuth's secure token management and our internal access controls, ensuring that only authorized users can access or modify your documents.</li>
                                <li><strong>Data Retention:</strong> We retain your data for as long as you maintain an active account with Hitch.AI or as required by law. You have the ability to delete your data at any time through your account settings, which will permanently remove your documents from our platform and revoke access from integrated Google services.</li>
                            </ul>
                        </HitchTypography>
                        <HitchTypography fontSize={16} color="white" mt={4}>
                            <strong>5. User Rights</strong>
                            <br />
                            <ul>
                                <li><strong>Access and Correction:</strong> You have the right to access and update your personal information at any time through your account settings. This includes modifying your profile information and managing connected Google accounts.</li>
                                <li><strong>Deletion:</strong> You can request the deletion of your data at any time. Upon deletion, all your documents and related data will be permanently removed from our platform and the connected Google Drive account.</li>
                                <li><strong>Opt-Out:</strong> You may opt out of certain data collection practices by adjusting your account settings. However, some functionalities may be limited if you choose to opt out of specific integrations.</li>
                                <li><strong>Data Portability:</strong> You have the right to request a copy of your data in a structured, commonly used, and machine-readable format. This allows you to transfer your data to another service if desired.</li>
                            </ul>
                        </HitchTypography>
                        <HitchTypography fontSize={16} color="white" mt={4}>
                            <strong>6. Cookies and Tracking Technologies</strong>
                            <br />
                            <ul>
                                <li><strong>Types of Cookies:</strong> We use cookies and similar tracking technologies to enhance your user experience, analyze usage patterns, and improve our platform's performance.</li>
                                <li><strong>Purpose:</strong> Cookies help us remember your preferences, maintain session information, and understand how you interact with our platform. This information is used to personalize content and improve overall functionality.</li>
                                <li><strong>Management:</strong> You can manage or disable cookies through your browser settings. Please note that disabling cookies may affect the functionality of certain features within Hitch.AI.</li>
                            </ul>
                        </HitchTypography>
                        <HitchTypography fontSize={16} color="white" mt={4}>
                            <strong>7. Children’s Privacy</strong>
                            <br />
                            Our platform is not intended for use by individuals under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have inadvertently received personal information from a child under 13, we will take steps to delete such information from our records promptly.
                        </HitchTypography>
                        <HitchTypography fontSize={16} color="white" mt={4}>
                            <strong>8. International Data Transfers</strong>
                            <br />
                            Your information, including personal data, may be transferred to and maintained on servers located outside of your country of residence. By using Hitch.AI, you consent to the transfer of your data to countries that may have different data protection laws than your country.
                            <br />
                            We take appropriate measures to ensure that your data is treated securely and in accordance with this Privacy Policy and applicable laws.
                        </HitchTypography>
                        <HitchTypography fontSize={16} color="white" mt={4}>
                            <strong>9. Changes to the Privacy Policy</strong>
                            <br />
                            We may update our Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date. We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting your information.
                            <br />
                            By continuing to use Hitch.AI after any changes become effective, you agree to the updated Privacy Policy.
                        </HitchTypography>
                        <HitchTypography fontSize={16} color="white" mt={4}>
                            <strong>10. Contact Information</strong>
                            <br />
                            If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:
                            <br />
                            <strong>Email:</strong> privacy@hitchsoftware.com
                            <br />
                            <strong>Address:</strong> 1234 Policy Lane, Suite 100, Tech City, TX 75001, USA
                        </HitchTypography>
                        <HitchTypography fontSize={16} color="white" mt={4}>
                            <strong>11. OAuth Integration with Google Services</strong>
                            <br />
                            Hitch.AI integrates with Google Docs and Google Drive APIs to enhance our platform's policy management capabilities. This integration allows you to create, edit, and organize policy documents directly within Hitch.AI, leveraging Google's robust collaboration and storage features.
                            <br /><br />
                            <strong>Data Access and Usage:</strong>
                            <ul>
                                <li><strong>Google Docs API (`https://www.googleapis.com/auth/documents`):</strong> Enables the creation and modification of policy documents within Hitch.AI. This scope allows us to insert, update, and format text in your Google Docs to ensure that your policies are well-structured and easily editable.</li>
                                <li><strong>Google Drive API (`https://www.googleapis.com/auth/drive`):</strong> Facilitates the storage and organization of your policy documents in Google Drive. This scope allows us to create folders, upload files, and manage access permissions to ensure that your documents are securely stored and easily accessible.</li>
                            </ul>
                            <strong>Security and Data Protection:</strong>
                            <ul>
                                <li>All data accessed through Google APIs is transmitted securely using HTTPS protocols.</li>
                                <li>We implement role-based access controls to ensure that only authorized users can access or modify your policy documents.</li>
                                <li>Access tokens are managed securely through Google's OAuth framework, and we do not store your OAuth credentials or access tokens.</li>
                                <li>Regular security audits and compliance checks are conducted to maintain the integrity and confidentiality of your data.</li>
                            </ul>
                            <strong>User Control and Consent:</strong>
                            <ul>
                                <li>You have full control over the permissions granted to Hitch.AI when connecting your Google accounts.</li>
                                <li>You can revoke access at any time through your Google account settings, which will immediately terminate Hitch.AI's access to your Google Docs and Drive.</li>
                                <li>During the OAuth consent process, you are clearly informed about the permissions being requested and the purposes for which they are used.</li>
                            </ul>
                        </HitchTypography>
                    </VuiBox>
                </VuiBox>
            </Container>
            <VuiBox mt={8}>
                <HomepageFooter />
            </VuiBox>
        </PageLayout>
    );
}

export default PrivacyPolicy;

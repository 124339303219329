
// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/components/VuiInput/index.js

import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import VuiInputRoot from 'components/VuiInput/VuiInputRoot';
import VuiInputWithIconRoot from 'components/VuiInput/VuiInputWithIconRoot';
import VuiInputIconBoxRoot from 'components/VuiInput/VuiInputIconBoxRoot';
import VuiInputIconRoot from 'components/VuiInput/VuiInputIconRoot';
import { useHitchAIUIController } from 'context';
import HitchInputHelperText from 'components/HitchInputHelperText'; // Import the new helper text component

const VuiInput = forwardRef(
  ({ size = 'medium', icon = { component: false, direction: 'none' }, error = false, success = false, disabled = false, helperText = '', fullWidth = false, ...rest }, ref) => { // Added fullWidth prop
    let template;
    const [controller] = useHitchAIUIController();
    const { direction } = controller;
    const iconDirection = icon.direction;

    if (icon.component && icon.direction === 'left') {
      template = (
        <VuiInputWithIconRoot ref={ref} ownerState={{ error, success, disabled, fullWidth }}> {/* Pass fullWidth prop */}
          <VuiInputIconBoxRoot ownerState={{ size }}>
            <VuiInputIconRoot fontSize="small" ownerState={{ size }}>
              {icon.component}
            </VuiInputIconRoot>
          </VuiInputIconBoxRoot>
          <VuiInputRoot {...rest} ownerState={{ size, error, success, iconDirection, direction, disabled, fullWidth }} /> {/* Pass fullWidth prop */}
          {helperText && <HitchInputHelperText helperText={helperText} />} {/* Render helper text here */}
        </VuiInputWithIconRoot>
      );
    } else if (icon.component && icon.direction === 'right') {
      template = (
        <VuiInputWithIconRoot ref={ref} ownerState={{ error, success, disabled, fullWidth }}> {/* Pass fullWidth prop */}
          <VuiInputRoot {...rest} ownerState={{ size, error, success, iconDirection, direction, disabled, fullWidth }} /> {/* Pass fullWidth prop */}
          <VuiInputIconBoxRoot ownerState={{ size }}>
            <VuiInputIconRoot fontSize="small" ownerState={{ size }}>
              {icon.component}
            </VuiInputIconRoot>
          </VuiInputIconBoxRoot>
          {helperText && <HitchInputHelperText helperText={helperText} />} {/* Render helper text here */}
        </VuiInputWithIconRoot>
      );
    } else {
      template = (
        <div>
          <VuiInputRoot {...rest} ref={ref} ownerState={{ size, error, success, disabled, fullWidth }} /> {/* Pass fullWidth prop */}
          {helperText && <HitchInputHelperText helperText={helperText} />} {/* Render helper text here */}
        </div>
      );
    }

    return template;
  }
);

// Typechecking props for the VuiInput
VuiInput.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  icon: PropTypes.shape({
    component: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
    direction: PropTypes.oneOf(['none', 'left', 'right']),
  }),
  error: PropTypes.bool,
  success: PropTypes.bool,
  disabled: PropTypes.bool,
  helperText: PropTypes.string, // Add helperText prop type
  fullWidth: PropTypes.bool, // Add fullWidth prop type
};

export default VuiInput;


import { forwardRef } from "react";
import PropTypes from "prop-types";
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import VuiTagInputRoot from "components/VuiTagInput/VuiTagInputRoot";

const VuiTagInput = forwardRef(({ size = "medium", error = false, success = false, ...rest }, ref) => (
  <VuiTagInputRoot ownerState={{ size, error, success }}>
    <ReactTagInput {...rest} ref={ref} />
  </VuiTagInputRoot>
));

// Typechecking props for the VuiTagInput
VuiTagInput.propTypes = {
  size: PropTypes.oneOf(["medium", "large"]),
  error: PropTypes.bool,
  success: PropTypes.bool,
};

export default VuiTagInput;


// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/third/H1259DF13_46D9_4237_A2F1_6C7AE95AC2E6/index.js

import Card from "@mui/material/Card";
import VuiBox from "components/VuiBox";
import HitchTypography from "components/HitchTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { useHubConnections } from "context/HubConnectionsProvider";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HitchDataTable from "components/HitchDataTable";
import { Grid } from "@mui/material";
import VuiButton from "components/VuiButton";
import HitchDisplayField from "components/HitchDisplayField";
import { Form, Formik } from "formik";
import HitchFormField from "components/HitchFormField";
import VuiSwitch from "components/VuiSwitch";

function H747949B5_4FBC_435E_A6CE_39F7EFB5B25D() {
    const [config, setConfig] = useState({});
    const [editMode, setEditMode] = useState(false);

    const {
        dashboardConnection,
        hitchDashCommand,
        hitchUser
    } = useHubConnections();

    function saveConfig(values) {
        console.log('save', values);

        const theConfiguration = {
            Configuration: {
                serviceId: config.serviceId,
                connectionId: config.connectionId,
                gptModelName: config.gptModelName,
                accessCode: values.accessCode,
                baseUrl: values.baseUrl,
                locationId: values.locationId,
            }
        };

        console.log('Saving the following Configuration Data', theConfiguration);
        hitchDashCommand('squareup', 'config', 'update', theConfiguration, () => {
            setEditMode(false);
        });
    }

    useEffect(() => {
        if (dashboardConnection) {
            const subscribeToEvents = () => {
                dashboardConnection.on("47addacd-683b-4862-8ba1-7874869d85aa", setConfig);
                hitchDashCommand('squareup', 'config', 'get', {});
            };

            subscribeToEvents();

            return () => {
                dashboardConnection.off("47addacd-683b-4862-8ba1-7874869d85aa", setConfig);
            };
        }
    }, [dashboardConnection]);

    useEffect(() => {
        if (config) {
            console.log('the Config', config);
        }
    }, [config]);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <VuiBox pt={6} pb={3}>
                <VuiBox mb={3}>
                    <Card>
                        <VuiBox p={3} pl={0} lineHeight={1}>
                            <HitchTypography variant="h5" fontWeight="medium" color="white">
                                SquareUp Service
                            </HitchTypography>
                            <HitchTypography variant="button" fontWeight="regular" color="text">
                                Service
                            </HitchTypography>
                        </VuiBox>
                        <VuiBox p={3}>
                            {!editMode ? (
                                <VuiBox mb={2}>
                                    <Grid
                                        item
                                        xs={12}
                                        md={5}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                        }}
                                    >
                                        <VuiButton
                                            variant="outlined"
                                            color="white"
                                            sx={({ palette: { white } }) => ({
                                                border: `1px solid ${white.main}`,
                                                color: white.main,
                                            })}
                                            onClick={() => setEditMode(true)}
                                        >
                                            Edit
                                        </VuiButton>
                                    </Grid>

                                    <HitchDisplayField label="Access Code" value={config?.accessCode} />
                                    <HitchDisplayField label="Base Url" value={config?.baseUrl} />
                                    <HitchDisplayField label="Location Id" value={config?.locationId} />
                                </VuiBox>
                            ) : (
                                <>
                                    <Formik
                                        initialValues={{
                                            accessCode: config?.accessCode || "",
                                            baseUrl: config?.baseUrl || "",
                                            locationId: config?.locationId || "",
                                        }}
                                        onSubmit={(values) => saveConfig(values)}
                                    >
                                        {({ values, setFieldValue, handleSubmit }) => (
                                            <Form onSubmit={handleSubmit}>
                                                <VuiBox>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        md={5}
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent: "flex-end",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <VuiButton
                                                            type="submit"
                                                            variant="contained"
                                                            color="white"
                                                            sx={({ palette: { white } }) => ({
                                                                border: `1px solid ${white.main}`,
                                                            })}
                                                        >
                                                            save
                                                        </VuiButton>
                                                        <VuiButton
                                                            variant="outlined"
                                                            color="white"
                                                            sx={({ palette: { white } }) => ({
                                                                border: `1px solid ${white.main}`,
                                                                color: white.main,
                                                            })}
                                                            onClick={() => setEditMode(false)}
                                                        >
                                                            cancel
                                                        </VuiButton>
                                                    </Grid>

                                                    <HitchFormField
                                                        name="accessCode"
                                                        label="Access Code"
                                                        fullWidth
                                                        editMode={editMode}
                                                    />

                                                    <HitchFormField
                                                        name="baseUrl"
                                                        label="Base Url"
                                                        fullWidth
                                                        editMode={editMode}
                                                    />

                                                    <HitchFormField
                                                        name="locationId"
                                                        label="Location Id"
                                                        fullWidth
                                                        editMode={editMode}
                                                    />

                                                </VuiBox>
                                            </Form>
                                        )}
                                    </Formik>


                                </>
                            )}

                        </VuiBox>
                    </Card>
                </VuiBox>
            </VuiBox>
        </DashboardLayout>
    );
}

export default H747949B5_4FBC_435E_A6CE_39F7EFB5B25D;

import VuiBox from "components/VuiBox";
import HitchTypography from "components/HitchTypography";

function HitchTitle({ title, subTitle }) {

    return (
        <VuiBox p={3} pl={0} lineHeight={1}>
            <HitchTypography variant="h5" fontWeight="medium" color="white">
                {title}
            </HitchTypography>
            <HitchTypography variant="button" fontWeight="regular" color="text">
                {subTitle}
            </HitchTypography>
        </VuiBox>
    );
}

export default HitchTitle;
// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/third/sos/policies/PolicyDetails.js

import { Card, Divider, Grid, IconButton, MenuItem } from "@mui/material";
import VuiBox from "components/VuiBox";
import HitchTypography from "components/HitchTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import VuiButton from "components/VuiButton";
import { useParams, useNavigate } from "react-router-dom";
import { useHubConnections } from "context/HubConnectionsProvider";
import { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import HitchFormField from "components/HitchFormField";
import HitchDisplayField from "components/HitchDisplayField";
import dayjs from "dayjs";
import HitchSelectSimple from "components/HitchSelectSimple";
import HitchSwitch from "components/HitchSwitch";
import { IoCopy } from "react-icons/io5";
import { HiOutlineDocumentSearch } from "react-icons/hi";
import HitchTempFileUpload from "components/HitchTempFileUpload";
import PolicySaveButtons from "./PolicySaveButtons";
import PolicyFile from "./PolicyFile";

function SosPolicyDetails() {
    const { policyId } = useParams();
    const navigate = useNavigate();
    const { dashboardConnection, hitchDashCommand, hitchUser } = useHubConnections();

    const [policy, setPolicy] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [vectorStores, setVectorStores] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [statuses, setStatuses] = useState([]);

    const [policyPdfId, setPolicyPdfId] = useState(null);
    const [policyDocxId, setPolicyDocxId] = useState(null);

    const [policyPdfContentType, setPolicyPdfContentType] = useState(null);
    const [policyDocxContentType, setPolicyDocxContentType] = useState(null); 

    useEffect(() => {
        if(policyPdfId) {
            console.log('policyPdfId', policyPdfId);
        }
    }, [policyPdfId]);

    function incomingPolicy(data) {
        if (data) {
            console.log("9f803eeb-708f-434e-ad82-e53f0063d63d", data);
            setPolicy(data);
        } else {
            console.log('NO POLICY FOUND!  CREATING A NEW ONE!');
            const thePolicy = {
                id: policyId,
                accountId: hitchUser.accountId,
                name: 'New Policy',
                policyStatus: '',
                department: '',
                description: '',
                scope: '',
                reviewDate: '',
                repositoryId: '',
                docxFileId: '',
                docxFileName: '',
                pdfFileId: '',
                pdfFileName: '',
                tags: '',
                isStandard: false
            };
            setPolicy(thePolicy);
        }
    }

    function vectorStoresSir(data) {
        setVectorStores(data);
    }

    useEffect(() => {
        if (dashboardConnection && policyId) {

            dashboardConnection.on("9f803eeb-708f-434e-ad82-e53f0063d63d", incomingPolicy);
            dashboardConnection.on("ab183cbd-c1a8-4bf3-a264-c8fa7d58660c", vectorStoresSir);
            dashboardConnection.on("a72e63f5-c02c-4187-9cc3-cc84ce29ceac", setDepartments);
            dashboardConnection.on("aaa42f55-d906-4660-b9a1-009c46a7f1c5", setStatuses);

            hitchDashCommand('sos', 'policyrepository', 'get', { policyId: policyId });
            hitchDashCommand('document', 'repo', 'list', {});
            hitchDashCommand('account', 'department', 'list', {});
            hitchDashCommand('sos', 'policyrepository', 'list-statuses', {});

            return () => {
                dashboardConnection.off("9f803eeb-708f-434e-ad82-e53f0063d63d", incomingPolicy);
                dashboardConnection.off("ab183cbd-c1a8-4bf3-a264-c8fa7d58660c", vectorStoresSir);
                dashboardConnection.off("a72e63f5-c02c-4187-9cc3-cc84ce29ceac", setDepartments);
                dashboardConnection.off("aaa42f55-d906-4660-b9a1-009c46a7f1c5", setStatuses);
            }
        }
    }, [dashboardConnection, policyId]);

    function deleteConfirmed() {
        // In real scenario, call hitchDashCommand to delete
        console.log('Delete confirmed for policy', policyId);
        navigate('/third/sos/policies');
    }

    function archivePolicy() {
        // In real scenario, call hitchDashCommand to archive
        console.log('Archive requested for policy', policyId);
        setPolicy((prev) => prev ? { ...prev, status: "Archived" } : prev);
    }

    function savePolicy(values) {
        // In real scenario, call hitchDashCommand to save changes
        console.log('Saving policy', values);

        const upPolicy = {
            ...policy,
            name: values.name,
            policyStatus: values.policyStatus,
            department: values.department,
            description: values.description,
            scope: values.scope,
            reviewDate: values.reviewDate,
            repositoryId: values.repositoryId,
            docxFileId: values.docxFileId,
            docxFileName: values.docxFileName,
            pdfFileId: values.pdfFileId,
            pdfFileName: values.pdfFileName,
            tags: values.tags,
            isStandard: values.isStandard,
        };

        setPolicy(upPolicy);
        setEditMode(false);

        hitchDashCommand('sos', 'policyrepository', 'update',
            {
                thePolicy: upPolicy,
                policyDocxId: policyDocxId,
                policyPdfId: policyPdfId
            });
    }

    const handleFileUploaded = (setFieldValue, fieldId, tempId) => {
        console.log('fieldId', fieldId);
        console.log('tempId', tempId);

        if (fieldId === 'docxFileId') {
            setPolicyDocxId(tempId?.tempId);
            setPolicyDocxContentType(tempId?.contentType)
            return;
        }

        if (fieldId === 'pdfFileId') {
            setPolicyPdfId(tempId?.tempId);
            setPolicyPdfContentType(tempId?.contentType)
            return;
        }

        setFieldValue(fieldId, tempId);
    };

    const vectorStoresArray = Array.isArray(vectorStores) ? vectorStores : [];
    const departmentsArray = Array.isArray(departments) ? departments : [];
    const statusArray = Array.isArray(statuses) ? statuses : [];


    if (!policy) {
        return (
            <DashboardLayout>
                <DashboardNavbar />
                <VuiBox pt={6} pb={3}>
                    <HitchTypography variant="h5" color="white">
                        Loading policy details...
                    </HitchTypography>
                </VuiBox>
            </DashboardLayout>
        );
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <VuiBox pt={6} pb={3}>
                <VuiBox mb={3}>
                    <Card
                        sx={({ functions: { linearGradient }, palette: { gradients } }) => ({
                            overflow: "visible",
                            background: linearGradient(
                                gradients.cardDark.main,
                                gradients.cardDark.state,
                                gradients.cardDark.deg
                            ),
                        })}
                    >
                        <VuiBox p={3} pl={0} lineHeight={1}>
                            <HitchTypography variant="h5" fontWeight="medium" color="white">
                                Standards Orchestration System
                            </HitchTypography>
                            <HitchTypography variant="button" fontWeight="regular" color="text">
                                Policy Id {policy.id}
                            </HitchTypography>
                        </VuiBox>
                        <VuiBox p={3}>

                            <VuiBox mb={2} display="flex" gap={2}>
                                {!editMode && (
                                    <>
                                        <VuiButton variant="outlined" color="warning" onClick={() => setEditMode(true)}>
                                            Edit
                                        </VuiButton>
                                        <VuiButton variant="outlined" color="error" disabled={policy.status !== 'Active'} onClick={archivePolicy}>
                                            Archive
                                        </VuiButton>
                                        <VuiButton variant="outlined" color="info" onClick={() => console.log('View Version History')}>
                                            View Version History
                                        </VuiButton>
                                    </>
                                )}
                                <VuiButton variant="outlined" color="white" onClick={() => navigate('/third/sos/policies')}>
                                    Back to Policies
                                </VuiButton>
                            </VuiBox>

                            <Formik
                                initialValues={{
                                    name: policy.name || "",
                                    department: policy.department || "",
                                    description: policy.description || "",
                                    scope: policy.scope || "",
                                    reviewDate: policy.reviewDate || dayjs().format('YYYY-MM-DD'),
                                    repositoryId: policy.repositoryId || "",
                                    docxFileId: policy.docxFileId || "",
                                    docxFileName: policy.docxFileName || "",
                                    pdfFileId: policy.pdfFileId || "",
                                    pdfFileName: policy.pdfFileName || "",
                                    tags: policy.tags || "",
                                    isStandard: policy.isStandard || false,
                                    policyStatus: policy.policyStatus || ''
                                }}
                                onSubmit={(values) => savePolicy(values)}
                            >
                                {({ values, setFieldValue, handleSubmit }) => (
                                    <Form onSubmit={handleSubmit}>
                                        <Grid container spacing={2}>

                                            <Grid item xs={12} md={6}>
                                                <HitchFormField
                                                    name="name"
                                                    label="Name"
                                                    fullWidth
                                                    editMode={editMode}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <HitchFormField
                                                    name="tags"
                                                    label="Tags"
                                                    fullWidth
                                                    editMode={editMode}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <HitchFormField
                                                    name="description"
                                                    label="Description"
                                                    fullWidth
                                                    editMode={editMode}
                                                    multiline
                                                    rows={3}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <HitchFormField
                                                    name="scope"
                                                    label="Scope"
                                                    fullWidth
                                                    editMode={editMode}
                                                    multiline
                                                    rows={2}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <HitchFormField
                                                    name="reviewDate"
                                                    label="Review Date (YYYY-MM-DD)"
                                                    fullWidth
                                                    editMode={editMode}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <HitchSelectSimple
                                                    value={values?.policyStatus}
                                                    label="Select the policy status"
                                                    options={statusArray.map((option) => ({
                                                        label: option.name,
                                                        value: option.id,
                                                    }))}
                                                    placeholder="Policy Status"
                                                    onChange={(e) => setFieldValue('policyStatus', e)}
                                                    isClearable
                                                    isDisabled={!editMode}
                                                />

                                            </Grid>

                                            <Divider />

                                            <Grid item xs={12} md={12}>
                                                <HitchSelectSimple
                                                    value={values?.department}
                                                    label="Select the owning department"
                                                    options={departmentsArray.map((option) => ({
                                                        label: option.name,
                                                        value: option.id,
                                                    }))}
                                                    placeholder="Select Department"
                                                    onChange={(e) => setFieldValue('department', e)}
                                                    isClearable
                                                    isDisabled={!editMode}
                                                />
                                            </Grid>

                                            <Divider />

                                            <Grid item xs={12} md={12}>
                                                <HitchSwitch
                                                    label="Is A Standard"
                                                    name="isStandard"
                                                    onLabel="Yes"
                                                    offLabel="No"
                                                    editMode={editMode}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={12}>
                                                <HitchSelectSimple
                                                    value={values?.repositoryId}
                                                    label="Select a Hitch Document Storage Repository"
                                                    options={vectorStoresArray.map((option) => ({
                                                        label: option.name,
                                                        value: option.id,
                                                    }))}
                                                    placeholder="Select Storage"
                                                    onChange={(e) => setFieldValue('repositoryId', e)}
                                                    isClearable
                                                    isDisabled={!editMode}
                                                />
                                            </Grid>

                                            {values?.repositoryId && !values?.isStandard && (
                                                <Grid item xs={12}>
                                                    {values?.docxFileId && (
                                                        <HitchTypography
                                                            component="label"
                                                            variant="caption"
                                                            color="white"
                                                            fontWeight="bold"
                                                            textTransform="capitalize"
                                                        >
                                                            File Selected
                                                        </HitchTypography>
                                                    )}

                                                    {!values?.docxFileId && (
                                                        <VuiBox>
                                                            <Grid
                                                                container
                                                                justifyContent="center"
                                                                alignItems="center"
                                                            >
                                                                <Grid item xs={12}>
                                                                    <HitchTypography
                                                                        component="label"
                                                                        variant="caption"
                                                                        color="white"
                                                                        fontWeight="bold"
                                                                        textTransform="capitalize"
                                                                    >

                                                                        The Policy Draft
                                                                    </HitchTypography>
                                                                </Grid>

                                                                <Grid
                                                                    container
                                                                    justifyContent="center"
                                                                    alignItems="center"
                                                                    direction="row"
                                                                >
                                                                    <Grid item xs={6}>
                                                                        <HitchTempFileUpload label="Upload File" onFileUploaded={(e) => handleFileUploaded(setFieldValue, 'docxFileId', e)} />
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <VuiButton
                                                                            variant="outlined"
                                                                            color="white">
                                                                            <HiOutlineDocumentSearch /> Search for file
                                                                        </VuiButton>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </VuiBox>
                                                    )}

                                                </Grid>
                                            )}

                                            {values?.repositoryId && (
                                                <Grid item xs={12}>
                                                    
                                                    
                                                    <PolicyFile
                                                        field='pdfFileId'
                                                        handleFileUploaded={handleFileUploaded}
                                                        policyPdfId={policyPdfId}
                                                        repoId={values?.repositoryId}
                                                        searchLabel="Search for File"
                                                        setFieldValue={setFieldValue}
                                                        title="The Published Policy"
                                                        uploadLabel="Upload File"
                                                        valuepdfFile={values?.pdfFileId} />
                                                </Grid>
                                            )}
                                            <PolicySaveButtons setEditMode={setEditMode} editMode={editMode} />
                                        </Grid>
                                    </Form>
                                )}
                            </Formik>

                        </VuiBox>
                    </Card>
                </VuiBox>
            </VuiBox>
        </DashboardLayout>
    );
}

export default SosPolicyDetails;

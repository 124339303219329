import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import VuiBox from "components/VuiBox";
import HitchTypography from "components/HitchTypography";
import HomepageNavbar from "./HomepageNavbar";
import pageRoutes from "page.routes";
import bgPricingPage from "assets/images/pricing-page-background.png";
import colors from "assets/theme/base/colors";
import linearGradient from "assets/theme/functions/linearGradient";
import borders from "assets/theme/base/borders";


function HomepageHeader({ tabValue, tabHandler }) {
    const { gradients } = colors;
    const { cardContent } = gradients;
    const { borderRadius } = borders;

    return (
        <>
            <HomepageNavbar
                routes={pageRoutes}
                action={{
                    //   type: "external",
                    route: "authentication/sign-in/basic",
                    label: "login",
                    color: "dark",
                }}
                transparent
                light
            />
            <VuiBox
                position="relative"
                height={{ xs: "70vh", lg: "55vh", xl: "50vh", xxl: "50vh" }}
                borderRadius="xl"
                overflow="hidden"
                sx={{
                    background: `url(${bgPricingPage})`,
                    backgroundSize: "cover",
                    overflowX: "hidden",
                    display: "flex", // Add flex display
                    flexDirection: "column", // Align items in a column
                    alignItems: "center", // Center horizontally
                    justifyContent: "center", // Center vertically
                }}
                m={2}
                pt={12}
                pb={20}
            >
                <VuiBox width="105rem" position="absolute" top={0} />
                <Grid container spacing={3} justifyContent="center" sx={{ textAlign: "center" }}>
                    <Grid item xs={10} lg={5}>
                        <VuiBox mb={1}>
                            <HitchTypography fontSize={32} color="white" fontWeight="bold">
                                HITCH.AI
                            </HitchTypography>
                        </VuiBox>
                        <VuiBox
                            mb={2}
                            textAlign="center"
                            display="flex"
                            justifyContent="center"
                            flexDirection="row"
                        >
                            <HitchTypography
                                variant="body2"
                                color="white"
                                fontWeight="regular"
                                sx={{ maxWidth: "340px" }}
                            >
                                Coming Soon
                            </HitchTypography>
                        </VuiBox>
                    </Grid>
                </Grid>
            </VuiBox>

        </>
    );
}

HomepageHeader.propTypes = {
    tabValue: PropTypes.number.isRequired,
    tabHandler: PropTypes.func.isRequired,
};

export default HomepageHeader;

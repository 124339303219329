
// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/components/VuiSelect/styles.js

import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";
import boxShadows from "assets/theme/base/boxShadows";
import pxToRem from "assets/theme/functions/pxToRem";
import linearGradient from "assets/theme/functions/linearGradient";
import boxShadow from "assets/theme/functions/boxShadow";
import { keyframes } from "@emotion/react";

export default (selectSize, selectError, selectSuccess) => {
  const { dark, white, text, inputColors, gradients, grey, info } = colors;
  const { size, fontWeightRegular } = typography;
  const { borderWidth, borderRadius } = borders;
  const { inputBoxShadow, lg } = boxShadows;

  let borderColorValue;
  let borderColorFocused;
  let boxShadowValue;
  let selectSizeValue;

  if (selectError) {
    borderColorValue = inputColors.error;
    borderColorFocused = inputColors.error;
  } else if (selectSuccess) {
    borderColorValue = inputColors.success;
    borderColorFocused = inputColors.success;
  } else {
    borderColorValue = inputColors.borderColor.main;
    borderColorFocused = inputColors.borderColor.focus;
  }

  if (selectError) {
    boxShadowValue = inputBoxShadow.error;
  } else if (selectSuccess) {
    boxShadowValue = inputBoxShadow.success;
  } else {
    boxShadowValue = boxShadow([0, 0], [0, 2], inputColors.boxShadow, 1);
  }

  if (selectSize === "small") {
    selectSizeValue = pxToRem(32);
  } else if (selectSize === "large") {
    selectSizeValue = pxToRem(48);
  } else {
    selectSizeValue = pxToRem(40);
  }

  // animations
  const prespective = keyframes`
    from {
      opacity: 0;
      transform: perspective(999px) rotateX(-10deg) translateZ(0) translate3d(0, 0, 0);
    }
    to {
      opacity: 1;
      transform: perspective(999px) rotateX(0deg) translateZ(0) translate3d(0, 0, 5px);
    }
  `;

  return {
    control: (provided, state) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      // width: "250px", // Increased width for dropdown control
      minWidth: "250px",
      height: selectSizeValue,
      padding: `0 ${pxToRem(12)}`,
      fontSize: size.sm,
      fontWeight: fontWeightRegular,
      backgroundColor: inputColors.backgroundColor,
      backgroundClip: "padding-box",
      border: `1px solid ${state.isFocused ? borderColorFocused : borderColorValue}`,
      appearance: "none",
      borderRadius: borderRadius.md,
      transition: "box-shadow 150ms ease, border-color 150ms ease",
      cursor: "pointer",
      boxShadow: state.isFocused ? boxShadowValue : "none",
      overflow: "hidden",
      boxSizing: "border-box",
    }),

    valueContainer: (provided) => ({
      ...provided,
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      width: "100%",
      height: "100%",
      padding: `0 ${pxToRem(8)}`,
      color: dark.main,
      overflow: "hidden",
      boxSizing: "border-box",
    }),

    placeholder: (provided) => ({
      ...provided,
      position: "absolute", // Revert back to absolute for proper alignment
      top: "50%",
      transform: "translateY(-50%)",
      marginLeft: 0,
      marginRight: 0,
      color: grey[500],
      fontSize: size.sm,
      opacity: 0.7,
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    }),

    singleValue: (provided) => ({
      ...provided,
      position: "absolute", // Revert back to absolute for proper alignment
      top: "50%",
      transform: "translateY(-50%)",
      color: dark.main,
      fontSize: size.sm,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      boxSizing: "border-box",
    }),

    input: (provided) => ({
      ...provided,
      color: dark.main,
      margin: 0,
      padding: 0,
      boxSizing: "border-box",
    }),

    indicatorSeparator: () => ({
      display: "none",
    }),

    dropdownIndicator: (provided, state) => ({
      ...provided,
      display: "flex",
      padding: pxToRem(8),
      "& svg": {
        fill: text.main,
        width: pxToRem(14),
        height: pxToRem(14),
        strokeWidth: 1,
        stroke: text.main,
        transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : "rotate(0)",
        transition: "transform 250ms ease",
      },
    }),

    menu: (provided, state) => ({
      ...provided,
      minWidth: "250px", // Increased width for dropdown menu
      // maxWidth: "250px", // Increased width for dropdown menu
      boxShadow: lg,
      padding: `${pxToRem(8)} ${pxToRem(8)}`,
      fontSize: size.sm,
      color: text.main,
      textAlign: "left",
      background: linearGradient(gradients.menu.main, gradients.menu.state, gradients.menu.deg),
      border: `${borderWidth[1]} solid ${grey[700]}`,
      borderRadius: borderRadius.md,
      transformOrigin: "50% 0",
      animation: `${prespective} 250ms ease forwards !important`,
      overflowX: "hidden", 
      overflowY: "auto", 
      boxSizing: "border-box",
      position: 'absolute',
      zIndex: 1050, 
    }),

    menuList: (provided) => ({
      ...provided,
      padding: 0,
      maxHeight: pxToRem(180),
      overflowY: "auto",
      overflowX: "hidden",
    }),

    option: (provided, state) => ({
      ...provided,
      position: "relative",
      minWidth: "250px", // Ensure options are aligned with the wider dropdown
      // maxWidth: "250px", 
      padding: `${pxToRem(8)} ${pxToRem(16)}`,
      borderRadius: borderRadius.sm,
      fontSize: size.sm,
      color: state.isSelected ? white.main : text.main,
      backgroundColor: state.isFocused
        ? gradients.menu.state
        : state.isSelected
        ? dark.main
        : "transparent",
      cursor: state.isDisabled ? "not-allowed" : "pointer",
      opacity: state.isDisabled ? 0.5 : 1,
      userSelect: state.isDisabled ? "none" : "auto",
      transition: "background-color 300ms ease, color 300ms ease",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      boxSizing: "border-box",

      "&:hover": {
        backgroundColor: dark.main,
        color: white.main,
      },
    }),

    multiValue: (provided) => ({
      ...provided,
      margin: 0,
      borderRadius: borderRadius.section,
      display: "flex",
      alignItems: "center",
      backgroundColor: info.main,
      color: white.main,
      padding: `${pxToRem(4)} ${pxToRem(8)}`,
    }),
  };
};


import { userManager } from 'services/authService';

const Login = () => {
    console.log('OIDC Authority:', window._env_.REACT_APP_OIDC_AUTHORITY);
    console.log('Client ID:', window._env_.REACT_APP_OIDC_CLIENT_ID);
    console.log('Redirect URI:', `${window.location.origin}/callback`);

    userManager.signinRedirect();
    return <div>Redirecting...</div>;
  };
  
  export default Login;

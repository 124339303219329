
import PropTypes from "prop-types";

// @mui material components
import TableCell from "@mui/material/TableCell";

// Vision UI Dashboard PRO React components
import HitchTypography from "components/HitchTypography";
import VuiBox from "components/VuiBox";

function SalesTableCell({ title, content, image = "", noBorder = false, ...rest }) {
  let template;

  if (image) {
    template = (
      <TableCell {...rest} align="left" width="30%" sx={{ border: noBorder && 0 }}>
        <VuiBox display="flex" alignItems="center" width="max-content">
          <VuiBox component="img" src={image} alt={content} width="1.5rem" height="auto" />{" "}
          <VuiBox display="flex" flexDirection="column" ml={3}>
            <HitchTypography
              variant="caption"
              color="text"
              fontWeight="medium"
              textTransform="capitalize"
            >
              {title}:
            </HitchTypography>
            <HitchTypography
              variant="button"
              color="white"
              fontWeight="medium"
              textTransform="capitalize"
            >
              {content}
            </HitchTypography>
          </VuiBox>
        </VuiBox>
      </TableCell>
    );
  } else {
    template = (
      <TableCell {...rest} align="center" sx={{ border: noBorder && 0 }}>
        <VuiBox display="flex" flexDirection="column">
          <HitchTypography
            variant="caption"
            color="text"
            fontWeight="medium"
            textTransform="capitalize"
          >
            {title}:
          </HitchTypography>
          <HitchTypography
            variant="button"
            fontWeight="medium"
            color="white"
            textTransform="capitalize"
          >
            {content}
          </HitchTypography>
        </VuiBox>
      </TableCell>
    );
  }

  return template;
}

// Typechecking props for the SalesTableCell
SalesTableCell.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  image: PropTypes.string,
  noBorder: PropTypes.bool,
};

export default SalesTableCell;

import { Grid, Modal, Box } from "@mui/material";
import HitchTempFileUpload from "components/HitchTempFileUpload";
import HitchTypography from "components/HitchTypography";
import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";
import { FaFilePdf } from "react-icons/fa6";
import { HiOutlineDocumentSearch } from "react-icons/hi";
import { IoCopy } from "react-icons/io5";
import HitchPdfViewer, { FileSource } from "components/HitchPdfViewer"; // Import HitchPdfViewer
import { useEffect, useState } from "react";

function PolicyFile({ field, handleFileUploaded, policyPdfId, repoId, searchLabel, setFieldValue, title, uploadLabel, valuepdfFile }) {
    const [modalOpen, setModalOpen] = useState(false);

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    useEffect(() => {
        console.log('valuepdfFile', valuepdfFile);
    }, [valuepdfFile]);

    return (
        <>
            <VuiBox>
                <Grid container alignItems="center">
                    <Grid item xs={12}>
                        <HitchTypography component="label" variant="caption" color="white" fontWeight="bold" textTransform="capitalize">
                            {title}
                        </HitchTypography>
                    </Grid>
                    {!policyPdfId && !valuepdfFile && (
                        <Grid container justifyContent="center" alignItems="center" direction="row">
                            <Grid item xs={12} md={6}>
                                <HitchTempFileUpload label={uploadLabel} onFileUploaded={(e) => handleFileUploaded(setFieldValue, field, e)} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <VuiButton variant="outlined" color="white">
                                    <HiOutlineDocumentSearch /> {searchLabel}
                                </VuiButton>
                            </Grid>
                        </Grid>
                    )}
                    {policyPdfId && (
                        <HitchTypography variant="h6" color="white" fontWeight="bold" textTransform="capitalize" style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <FaFilePdf style={{ fontSize: '1.5em', color: 'red' }} />
                            Temp File: {policyPdfId}
                            <VuiButton variant="outlined" color="white" onClick={() => setModalOpen(true)}>
                                View Document
                            </VuiButton>
                        </HitchTypography>
                    )}
                    {/* This condition seems redundant since policyPdfId is already checked above */}
                    {!policyPdfId && valuepdfFile && (
                        <HitchTypography variant="h6" color="white" fontWeight="bold" textTransform="capitalize">
                            <FaFilePdf style={{ fontSize: '1.5em', color: 'red' }} />
                                DOCUMENT NUMBER: {valuepdfFile}
                            <VuiButton variant="outlined" color="white" onClick={() => setModalOpen(true)}>
                                View Document
                            </VuiButton>
                        </HitchTypography>
                    )}
                </Grid>
            </VuiBox>

            {/* Modal for PDF Viewing */}
            <Modal open={modalOpen} onClose={handleCloseModal}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80%',
                        height: '80%',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        overflow: 'auto',
                    }}
                >
                    {/* Render HitchPdfViewer directly */}
                    {policyPdfId && (
                        <HitchPdfViewer 
                            source={FileSource.TEMP} 
                            sourceId={policyPdfId} 
                        />
                    )}
                    {valuepdfFile && (
                        <HitchPdfViewer 
                            source={FileSource.DOC_REPO} 
                            repoId={repoId}
                            sourceId={valuepdfFile} 
                        />
                    )}
                </Box>
            </Modal>
        </>
    );
}

export default PolicyFile;

// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/components/HitchPage/index.js

import { Card } from "@mui/material";
import HitchTitle from "components/HitchTitle";
import VuiBox from "components/VuiBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";


function HitchPage({title, subTitle, children}) {

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <VuiBox pt={6} pb={3}>
                <VuiBox mb={3}>
                    <Card>
                        <HitchTitle
                            title={title}
                            subTitle={subTitle} />
                        {children}
                    </Card>
                </VuiBox>
            </VuiBox>
        </DashboardLayout>
    );
}

export default HitchPage;
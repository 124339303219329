
// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/hitch/administration/changeorg/index.js

import HitchTypography from "components/HitchTypography";
import OrgList from "./OrgList";
import VuiBox from "components/VuiBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import { Card } from "@mui/material";


function ChangeOrg() {

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <VuiBox my={7}>
                <VuiBox mx="auto" display="flex" justifyContent="center" maxWidth="780px">
                    <Card sx={{ padding: "20px" }}>
                        <VuiBox pt={2} px={2}>
                            <HitchTypography variant="h5" fontWeight="medium" color="white">
                                My Organizations
                            </HitchTypography>
                        </VuiBox>
                        <VuiBox mt={2}>
                            <OrgList />
                        </VuiBox>
                    </Card>
                </VuiBox>
            </VuiBox>
        </DashboardLayout>
    )
}

export default ChangeOrg;
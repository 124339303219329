import VuiBox from "components/VuiBox";
import HitchTypography from "components/HitchTypography";

// Vision UI Dashboard PRO React example components
import TimelineItem from "examples/Timeline/TimelineItem";

function OrdersOverview() {
  return (
    <>
      <HitchTypography variant="h6" fontWeight="medium" color="white" mb="30px">
        Track order
      </HitchTypography>
      <VuiBox mt={2}>
        <TimelineItem color="secondary" title="Order received" dateTime="22 DEC 7:20 PM" />
        <TimelineItem
          color="secondary"
          title="Generate order id #1832412"
          dateTime="22 DEC 7:21 AM"
        />
        <TimelineItem
          color="secondary"
          title="Order transmited to courier"
          dateTime="22 DEC 8:10 AM"
        />
        <TimelineItem color="success" title="Order delivered" dateTime="22 DEC 4:54 PM" />
      </VuiBox>
    </>
  );
}

export default OrdersOverview;


// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/services/componentResolver.jsx

import React from 'react';
import { IoBuild, IoDocument, IoDocuments, IoEggSharp, IoFunnel, IoShieldHalf } from 'react-icons/io5';
import { SiAdp, SiAzuredevops, SiDocusign, SiDotnet, SiGithub, SiGooglecloud, SiHubspot, SiIfttt, SiMicrosoftoffice, SiOpenai, SiQuickbooks, SiSquare } from 'react-icons/si';
import { GiLaddersPlatform } from "react-icons/gi";
import { FaUserGear } from "react-icons/fa6";
import { GoDatabase, GoOrganization } from "react-icons/go";
import { GrDocumentStore } from "react-icons/gr";
import { HiChatBubbleLeftRight } from "react-icons/hi2";
import { MdAutoGraph } from 'react-icons/md';
import { MdEngineering } from "react-icons/md";
import { MdOutlineVpnLock } from "react-icons/md";


const iconMapping = {
  "5877da4c-01ac-4f36-acb8-24d96fecea37": <IoDocument />,
  "82680c23-2719-48ea-a3ed-6bdfa4a1b4cf": <IoEggSharp />,
  "bcff090c-54b1-4940-a8d4-4bb9f84c4174": <IoBuild />,
  "ef759b69-6bfd-48c2-bcd4-cc5359f4a736": <IoShieldHalf />,
  "2d51d09f-8beb-4ce1-9a49-d7f9d0a9c2d6": <SiDotnet />,
  "a46d1e5f-527c-4119-a772-5f5f28e624e2": <GiLaddersPlatform />,
  "71adc37e-4fd1-4e39-9411-765f564c57ef": <SiOpenai />,
  "550a452a-40fe-466d-9f90-13d632186076": <SiQuickbooks />,
  "9a8b5f23-85ad-4788-9104-ac703988b0e2": <SiDocusign />,
  "d776a0c3-9ce1-40bf-a74e-e563406d41ff": <SiGithub />,
  "59c218e3-32fd-4a59-93f9-7af9a2074b15": <SiGooglecloud />,
  "565f454c-8612-4f54-8cb7-81c907d83c08": <FaUserGear />,
  "1bb266e3-9260-4af8-9ba8-0d0afb689c9b": <GoOrganization />,
  "7ec4c8e2-9f37-4116-b6c7-82036c2389bd": <HiChatBubbleLeftRight />,  
  "17230d4d-b04e-4822-8c17-6e2b04dedc8b": <GoDatabase />,
  "003f9d65-d8fa-443b-bd30-0d50a2a4c09a": <SiAzuredevops />,
  "2ad15559-6cbd-4205-9739-aee4079eabde": <SiAdp />,
  "18d7b23f-98e4-4311-b690-86c3489f304b": <IoFunnel />,
  "dc9b2184-9643-4e7c-a6ce-d1d876a252eb": <GrDocumentStore />, 
  "f1e3d73d-ac79-44d9-9d53-1c8bfc1e90f0": <SiHubspot />,
  "988f27e2-5130-401f-ac59-b164d3c6310e": <SiIfttt />,
  "af0abff7-d6eb-46d1-91d2-3b79dc47f4f2": <MdAutoGraph />,
  "22c6f6bc-35ca-4079-a06c-4a731a812478": <SiMicrosoftoffice />,
  "885e6b2b-7a62-4013-a38f-97ad9d250b42": <SiSquare />,
  "792e7db9-529b-4c0b-ab6f-88e5cefc164e": <IoDocuments />,
  "af6459c1-55ae-4107-a346-c34a8f2990c1": <MdEngineering />,
  "bd8a3df3-49f5-4389-9793-784198b3335a": <MdOutlineVpnLock />
};

// Function to resolve components from a string identifier
export const resolveIcon = (iconName) => {
  return iconMapping[iconName] || null;
};

 

import { Icon, IconButton } from "@mui/material";
import HitchTypography from "components/HitchTypography";
import { useHubConnections } from "context/HubConnectionsProvider";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { navbarIconButton } from "hitch/dashboard/styles";
import HitchAvatar from "components/HitchAvatar";


function UserLogin() {

    const { hitchUser } = useHubConnections();

    useEffect(() => {
        if(hitchUser) {
            console.log('hitchUser', hitchUser);
        }
    }, [hitchUser]);

    return (
        <Link to="/authentication/sign-in/basic">
            <IconButton sx={navbarIconButton} size="small">
                <HitchAvatar hitchId={hitchUser?.userId} size="xs" />
                {/* <Icon sx={({ palette: { dark, white } }) => ({ color: white.main })}>
                    account_circle
                </Icon> */}
                <HitchTypography variant="button" fontWeight="medium" color="white">
                    {hitchUser ? `Hi ${hitchUser.firstName}!` : "Sign in" }
                </HitchTypography>
            </IconButton>
        </Link>
    );
}

export default UserLogin;
// FILE: /UI/hitch-ai-ui-2/src/assets/theme/components/slider.js

import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import boxShadows from "assets/theme/base/boxShadows";

import linearGradient from "assets/theme/functions/linearGradient";
import pxToRem from "assets/theme/functions/pxToRem";

// Destructure the values for better readability
const { light, white, sliderColors, black, gradients } = colors;
const { borderRadius, borderWidth } = borders;
const { sliderBoxShadow } = boxShadows;


// Check and log all the values being used
// console.log('light.main:', light?.main || 'light.main is undefined');
// console.log('white.main:', white?.main || 'white.main is undefined');
// console.log('sliderColors.thumb.borderColor:', sliderColors?.thumb?.borderColor || 'sliderColors.thumb.borderColor is undefined');
// console.log('gradients.info.main:', gradients?.info?.main || 'gradients.info.main is undefined');
// console.log('gradients.info.state:', gradients?.info?.state || 'gradients.info.state is undefined');
// console.log('sliderBoxShadow.thumb:', sliderBoxShadow?.thumb || 'sliderBoxShadow.thumb is undefined');

// Define fallback values to avoid issues if a value is undefined
const fallbackColors = {
  lightMain: light?.main || '#e9ecef',
  whiteMain: white?.main || '#ffffff',
  sliderThumbBorderColor: sliderColors?.thumb?.borderColor || '#d9d9d9',
  gradientInfoMain: gradients?.info?.main || '#00B3B3',
  gradientInfoState: gradients?.info?.state || '#21d4fd',
  sliderThumbBoxShadow: sliderBoxShadow?.thumb || '0rem 0.0625rem 0.8125rem 0rem rgba(0, 0, 0, 0.2)',
};

// Configuration for the Slider component styles
export default {
  styleOverrides: {
    root: {
      width: "100%",
      "& .MuiSlider-active, & .Mui-focusVisible": {
        boxShadow: "none !important",
      },
      "& .MuiSlider-valueLabel": {
        color: black.main, // Make sure this is a valid color
      },
    },
    rail: {
      height: pxToRem(3),
      backgroundColor: fallbackColors.lightMain, // Use fallback value
      borderRadius: borderRadius.sm,
    },
    track: {
      backgroundImage: linearGradient(fallbackColors.gradientInfoMain, fallbackColors.gradientInfoState), // Use fallback values
      height: pxToRem(6),
      position: "relative",
      top: pxToRem(2),
      border: "none",
      borderRadius: borderRadius.lg,
      zIndex: 2, // FROM 1
    },
    thumb: {
      width: pxToRem(15),
      height: pxToRem(15),
      backgroundColor: fallbackColors.whiteMain, // Use fallback value
      zIndex: 10,
      boxShadow: fallbackColors.sliderThumbBoxShadow, // Use fallback value
      border: `${borderWidth[1]} solid ${fallbackColors.sliderThumbBorderColor}`, // Use fallback value
      "&:hover": {
        boxShadow: "none",
      },
    },
  },
};

// FILE: /UI/hitch-ai-ui-2/src/assets/theme/base/colors.js
 
export default {
  
  background: {
    default: "#777777",
  },

  sidenav: {
    button: "#333333",
  },

  text: {
    main: "#a0aec0",
    focus: "#ffffff",
  },

  transparent: {
    "main": "rgba(0, 0, 0, 0)" // Using RGBA format to indicate full transparency
  },

  white: {
    main: "#ffffff",
    focus: "#ffffff",
  },

  brand: {
    main: "#00b3b3",
    focus: "#00b3b3",
  },

  black: {
    light: "#141414",
    main: "#000000",
    focus: "#000000",
  },

  primary: {
    main: "#004545",  // HERE DUDE
    focus: "#004545",
  },

  secondary: {
    main: "#000045",
    focus: "#000045",
  },

  lightblue: {
    main: "#4299e1",
    focus: "#4299e1",
  },

  orange: {
    main: "#f6ad55",
    focus: "#f6ad55",
  },

  grey: {
    100: "#edf2f7",
    200: "#e2e8f0",
    300: "#cbd5e0",
    400: "#a0aec0",
    500: "#718096",
    600: "#4a5568",
    700: "#004545",
    800: "#1a202a",
    900: "#171923",
  },

  borderCol: {
    main: "#56577a",
    red: "#e31a1a",
    navbar: "rgba(226, 232, 240, 0.3)",
  },

  // Other colors
  info: {
    main: "#00B3B3",  // ICONS AND DOTS ON MENU
    focus: "#3993fe",
    charts: {
      100: "#2d8cfc",
      200: "#2370cc",
      300: "#2370cc",
      400: "#0f4a91",
      500: "#073a78",
      600: "#012654",
    },
  },

  success: {
    main: "#01b574",
    focus: "#35d28a",
  },

  warning: {
    main: "#ffb547",
    focus: "#ffcd75",
  },

  error: {
    main: "#e31a1a",
    focus: "#ee5d50",
  },

  light: {
    main: "#e9ecef",
    focus: "#ebeff4",
  },

  dark: {
    main: "#ccc",
    focus: "#121241",
  },

  gradients: {
    navbar: {
      deg: "123.64deg",
      main: "rgba(255, 255, 255, 0) -22.38%",
      state: "rgba(255, 255, 255, 0.039) 70.38%",
    },

    sidenav: {
      deg: "127.09",
      main: "rgba(30, 30, 30, 0.94) 19.41%",
      state: "rgba(25, 25, 25, 0.49) 76.65%",
    },

    borderLight: {
      angle: "94.43% 69.43% at 50% 50%",
      main: "#ffffff 0%",
      state: "rgba(255, 255, 255, 0) 100%",
    },

    borderDark: {
      angle: "69.43% 69.43% at 50% 50%",
      main: "#ffffff 0%",
      state: "rgba(255, 255, 255, 0) 100%",
    },

    cover: {
      deg: "159.02",
      main: "#0f123b 14.25%",
      state: "#090d2e 56.45%",
      stateSecondary: "#020515 86.14%",
    },

    cardDark: {
      deg: "126.97",
      main: "rgba(30, 30, 30, 0.74) 28.26%",
      state: "rgba(25, 25, 25, 0.71) 91.2%",
    },

    cardLight: {
      deg: "127.09",
      main: "rgba(30, 30, 30, 0.94) 19.41%",
      state: "rgba(25, 25, 25, 0.49) 76.65%",
    },

    card: {
      deg: "127.09",
      main: "rgba(30, 30, 30, 0.94) 19.41%",
      state: "rgba(25, 25, 25, 0.49) 76.65%",
    },

    menu: {
      deg: "126.97",
      main: "#05153f 28.26%",
      state: "#072561 91.2%",
    },

    cardContent: {
      deg: "126.97",
      main: "rgb(6, 11, 40) 28.26%",
      state: "rgb(10, 14, 35) 91.2%",
    },

    box: {
      deg: "126.97",
      main: "rgba(6, 11, 40, 0.74) 28.26%",
      state: "rgba(10, 14, 35, 0.71) 91.2%",
    },

    bill: {
      deg: "127.09",
      main: "rgba(24, 29, 60, 0.94) 19.41%",
      state: "rgba(10, 14, 35, 0.49) 76.65%",
    },

    primary: {
      deg: "97.89",
      main: "#00b3b3",  // HERE DUDE
      state: "#9f7aea",
    },

    secondary: {
      main: "#627594",
      state: "#a8b8d8",
    },

    logo: {
      deg: "97.89",
      main: "#ffffff 70.67%",
      state: "rgba(117, 122, 140, 0) 108.55%",
    },

    info: {
      main: "#00B3B3",
      state: "#21d4fd",
    },

    success: {
      main: "#01B574",
      state: "#c9fbd5",
    },

    warning: {
      main: "#f53939",
      state: "#fbcf33",
    },

    error: {
      main: "#f53c2b",
      state: "#f53c2b",
    },

    light: {
      main: "#ced4da",
      state: "#ebeff4",
    },

    dark: {
      main: "#141727",
      state: "#3a416f",
    },
  },

  socialMediaColors: {
    facebook: {
      main: "#3b5998",
      dark: "#344e86",
    },

    twitter: {
      main: "#55acee",
      dark: "#3ea1ec",
    },

    instagram: {
      main: "#125688",
      dark: "#0e456d",
    },

    linkedin: {
      main: "#0077b5",
      dark: "#00669c",
    },

    pinterest: {
      main: "#cc2127",
      dark: "#b21d22",
    },

    youtube: {
      main: "#e52d27",
      dark: "#d41f1a",
    },

    vimeo: {
      main: "#1ab7ea",
      dark: "#13a3d2",
    },

    slack: {
      main: "#3aaf85",
      dark: "#329874",
    },

    dribbble: {
      main: "#ea4c89",
      dark: "#e73177",
    },

    github: {
      main: "#24292e",
      dark: "#171a1d",
    },

    reddit: {
      main: "#ff4500",
      dark: "#e03d00",
    },

    tumblr: {
      main: "#35465c",
      dark: "#2a3749",
    },
  },

  alertColors: {
    primary: {
      main: "#7928ca",
      state: "#d6006c",
      border: "#efb6e2",
    },

    secondary: {
      main: "#627594",
      state: "#8ca1cb",
      border: "#dadee6",
    },

    info: {
      main: "#2152ff",
      state: "#02c6f3",
      border: "#b9ecf8",
    },

    success: {
      main: "#17ad37",
      state: "#84dc14",
      border: "#daf3b9",
    },

    warning: {
      main: "#f53939",
      state: "#fac60b",
      border: "#fef1c2",
    },

    error: {
      main: "#ea0606",
      state: "#ff3d59",
      border: "#f9b4b4",
    },

    light: {
      main: "#ced4da",
      state: "#d1dae6",
      border: "#f8f9fa",
    },

    dark: {
      main: "#141727",
      state: "#2c3154",
      border: "#c2c8d1",
    },
  },

  badgeColors: {
    primary: {
      basic: "#805ad5",
      background: "#f883dd",
      text: "#a3017e",
    },

    secondary: {
      basic: "#5974a2",
      background: "#e4e8ed",
      text: "#5974a2",
    },

    info: {
      basic: "#004545",
      background: "#abe9f7",
      text: "#08a1c4",
    },

    success: {
      basic: "#01b574",
      background: "#c9fbd5",
      text: "#01b574",
    },

    warning: {
      basic: "#ffb547",
      background: "#fef5d3",
      text: "#fbc400",
    },

    error: {
      basic: "#e31a1a",
      background: "#fc9797",
      text: "#bd0000",
    },

    light: {
      basic: "#ffffff",
      background: "#ffffff",
      text: "#c7d3de",
    },

    dark: {
      basic: "#1E244B",
      background: "#1E244B",
      text: "#fff",
    },
  },

  inputColors: {
    backgroundColor: "#101010",
    borderColor: { main: "rgba(226, 232, 240, 0.3)", focus: "rgba(226, 232, 240, 0.6)" },
    boxShadow: "#628fc2",
    error: "#fd5c70",
    success: "#66d432",
  },

  sliderColors: {
    thumb: { borderColor: "#d9d9d9" },
  },

  circleSliderColors: {
    background: "#d3d3d3",
  },

  tabs: {
    indicator: { boxShadow: "#ddd" },
  },
};

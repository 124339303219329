
// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/hitch/chat/ChatHistoryModal.js

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // For redirection
import { Modal, Box, Typography, IconButton, List, ListItem, ListItemText, Divider } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useChatConnections } from 'context/ChatConnectionProvider';

function ChatHistoryModal({ closeModal, setActiveTab }) {
    const navigate = useNavigate();
    const { conversationHistories } = useChatConnections();

    useEffect(() => {
        if (conversationHistories) {
            console.log('conversationHistories', conversationHistories); // Log the incoming data for debugging
        }
    }, [conversationHistories]);

    const handleRowClick = (conversationId) => {
        // Switch to chat tab and close modal after redirecting
        closeModal();
        setActiveTab(0);
        navigate(`/chat/general/${conversationId}`);
    };

    const handleCloseModal = () => {
        // Switch to chat tab when modal is closed manually
        setActiveTab(0);
        closeModal();
    };

    return (
        <Modal open={true} onClose={handleCloseModal}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '80%',
                    maxHeight: '80%',
                    overflowY: 'auto', // Ensure modal is scrollable
                    bgcolor: '#333', // Darker background color
                    color: '#fff', // Light text color to contrast
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2
                }}
            >
                <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
                    Chat History
                </Typography>
                <IconButton
                    onClick={handleCloseModal}
                    sx={{ position: 'absolute', top: 10, right: 10, color: 'white' }} // Color the close icon white
                >
                    <CloseIcon />
                </IconButton>

                {conversationHistories && conversationHistories.length > 0 ? (
                    <List>
                        {conversationHistories.map((item, index) => {
                            if (item.type === "header") {
                                // Render header sections like "Today", "Yesterday", etc.
                                return (
                                    <React.Fragment key={index}>
                                        <ListItem sx={{ backgroundColor: '#444', borderRadius: 1, mt: 1 }}>
                                            <Typography variant="subtitle1" color="lightgray" fontWeight="bold">
                                                {item.text}
                                            </Typography>
                                        </ListItem>
                                        <Divider sx={{ backgroundColor: '#555' }} /> {/* Adjust divider color */}
                                    </React.Fragment>
                                );
                            } else if (item.type === "row") {
                                // Render individual chat rows with clickable navigation
                                return (
                                    <React.Fragment key={item.guid}>
                                        <ListItem
                                            button
                                            onClick={() => handleRowClick(item.guid)}
                                            sx={{ '&:hover': { backgroundColor: '#555' }, borderRadius: 1, mb: 1, color: '#fff' }}
                                        >
                                            <ListItemText
                                                primary={item.text}
                                                secondary={`Last updated: ${new Date(item.updated).toLocaleString()}`}
                                                primaryTypographyProps={{ color: '#fff' }}
                                                secondaryTypographyProps={{ color: 'lightgray' }}
                                            />
                                        </ListItem>
                                        <Divider sx={{ backgroundColor: '#555' }} />
                                    </React.Fragment>
                                );
                            }
                            return null;
                        })}
                    </List>
                ) : (
                    <Typography sx={{ mt: 2, color: 'lightgray' }}>No chat history available.</Typography>
                )}
            </Box>
        </Modal>
    );
}

export default ChatHistoryModal;


import { useState, useEffect } from "react";
import { Modal, Grid, Tabs, Tab, Button, MenuItem, Select, FormControl, InputLabel, IconButton, Menu } from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import HitchFeatureTable from "components/HitchFeatureTable";
import HitchPage from "components/HitchPage";
import VuiInput from "components/VuiInput";
import HitchFormBadgeList from "components/HitchFormBadgeList";
import {
    FaFolder,
    FaFileAudio,
    FaFileAlt,
    FaFileCode,
    FaFileImage,
    FaFilePdf,
    FaFile,
    FaQuestionCircle,
} from "react-icons/fa";
import { useHubConnections } from "context/HubConnectionsProvider";
import HitchPdfViewer from "components/HitchPdfViewer";
import { FileSource } from "components/HitchPdfViewer";

function GoogleDrive() {
    const { dashboardConnection, hitchDashCommand } = useHubConnections();
    const [path, setPath] = useState("/");
    const [labels, setLabels] = useState([]);
    const [tabIndex, setTabIndex] = useState(0); // 0 for Path, 1 for Labels
    const [repositories, setRepositories] = useState([]);
    const [selectedRepository, setSelectedRepository] = useState("");
    const [commandBody, setCommandBody] = useState({ path });
    const [feedback, setFeedback] = useState(null); // For displaying success/error messages
    const [anchorEl, setAnchorEl] = useState(null); // Context menu anchor
    const [selectedRow, setSelectedRow] = useState(null); // Row for context menu
    const [isPdfModalOpen, setIsPdfModalOpen] = useState(false);
    const [selectedFileId, setSelectedFileId] = useState(null);

    const mimeTypeIconMap = {
        "application/vnd.google-apps.folder": <FaFolder />,
        "application/vnd.google-apps.audio": <FaFileAudio />,
        "application/vnd.google-apps.document": <FaFileAlt />,
        "application/vnd.google-apps.drive-sdk": <FaFileCode />,
        "application/vnd.google-apps.drawing": <FaFileImage />,
        "application/vnd.google-apps.file": <FaFile />,
        "application/vnd.google-apps.form": <FaFileAlt />,
        "application/vnd.google-apps.photo": <FaFileImage />,
        "application/pdf": <FaFilePdf />,
    };

    const handleActionsClick = (event, row) => {
        event.preventDefault(); // Prevent default context menu
        const rect = event.currentTarget.getBoundingClientRect();
        setAnchorEl({
            top: rect.top + window.scrollY,
            left: rect.left + window.scrollX,
        });
        setSelectedRow(row);
    };

    const handleActionsClose = () => {
        setAnchorEl(null);
        setSelectedRow(null);
    };

    const handleViewPdf = (row) => {
        setSelectedFileId(row.id);
        setIsPdfModalOpen(true);
    };

    const handleSubscribe = () => {
        setFeedback(`Subscribed to folder: ${selectedRow.name}`);
        handleActionsClose();
    };

    const handleClone = () => {
        const newName = prompt("Enter new name for the cloned folder:", `${selectedRow.name}_Copy`);
        if (newName) {
            setFeedback(`Cloned folder: ${selectedRow.name} as ${newName}`);
        }
        handleActionsClose();
    };

    const handleDelete = () => {
        setFeedback(`Deleted folder: ${selectedRow.name}`);
        handleActionsClose();
    };

    const columnDefinitions = [
        {
            accessor: "mimeType",
            Header: "",
            width: "10%",
            Cell: ({ value }) => (
                <div style={{ display: "flex", justifyContent: "center" }}>
                    {mimeTypeIconMap[value] || <FaQuestionCircle />}
                </div>
            ),
            disableSortBy: true,
        },
        { accessor: "name", Header: "Name", width: "60%" },
        {
            accessor: "labelInfo",
            Header: "Labels",
            width: "20%",
            Cell: ({ value }) => {
                return Object.values(value || {}).map(label => label.value).join(", ");
            },
        },
        {
            id: "actions",
            Header: "Actions",
            width: "10%",
            Cell: ({ row }) => (
                <IconButton
                    aria-label="actions"
                    onClick={(event) => handleActionsClick(event, row.original)}
                >
                    <MoreVert />
                </IconButton>
            ),
            disableSortBy: true,
        },
    ];

    // Load repositories on mount
    useEffect(() => {
        if (dashboardConnection) {
            hitchDashCommand("document", "repo", "list", {});
            dashboardConnection.on("ab183cbd-c1a8-4bf3-a264-c8fa7d58660c", (data) => {
                setRepositories(data);
            });

            return () => {
                dashboardConnection.off("ab183cbd-c1a8-4bf3-a264-c8fa7d58660c");
            };
        }
    }, [dashboardConnection]);

    // Update command body based on path or labels
    useEffect(() => {
        if (tabIndex === 0) {
            setCommandBody({ path });
        } else {
            setCommandBody({ labels });
        }
    }, [path, labels, tabIndex]);

    const pathCommand = {
        service: "gcp",
        feature: "drive-file",
        operation: "list",
    };

    const labelCommand = {
        service: "gcp",
        feature: "drive-file",
        operation: "list-by-labels",
    };

    useEffect(() => {
        const command = tabIndex === 0 ? pathCommand : labelCommand;
        hitchDashCommand(command.service, command.feature, command.operation, commandBody);
    }, [commandBody, hitchDashCommand, tabIndex]);

    return (
        <HitchPage title="Google Drive" subTitle="Manage Files in your Google Drive.">
            <FormControl fullWidth margin="normal">
                <InputLabel>Select Repository</InputLabel>
                <Select
                    value={selectedRepository}
                    onChange={(e) => setSelectedRepository(e.target.value)}
                >
                    {repositories.map((repo) => (
                        <MenuItem key={repo.Id} value={repo.Id}>
                            {repo.Name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <Tabs value={tabIndex} onChange={(event, newValue) => setTabIndex(newValue)}>
                <Tab label="Path" />
                <Tab label="Labels" />
            </Tabs>

            {tabIndex === 0 ? (
                <VuiInput
                    placeholder="Path..."
                    value={path}
                    fullWidth
                    onChange={({ currentTarget }) => setPath(currentTarget.value)}
                />
            ) : (
                <HitchFormBadgeList
                    label="Labels"
                    name="labels"
                    allowCustom={true}
                    sourceList={["Confidential", "Shared", "Important"]}
                    onChange={(values) => setLabels(values.split(","))}
                />
            )}

            {tabIndex === 0 && <button onClick={() => setPath("/")}>Go to Root</button>}

            <HitchFeatureTable
                eventId="57662e55-4fb6-4215-b764-17de6397a955"
                command={tabIndex === 0 ? pathCommand : labelCommand}
                columnDefinitions={columnDefinitions}
                commandBody={commandBody}
                onRowClick={(row) => {
                    if (row.mimeType === "application/pdf") {
                        handleViewPdf(row);
                    } else if (row.mimeType === "application/vnd.google-apps.folder") {
                        setPath((prevPath) => `${prevPath.endsWith("/") ? prevPath : prevPath + "/"}${row.name}`);
                    }
                }}
            />

            <Menu
                open={Boolean(anchorEl)}
                onClose={handleActionsClose}
                anchorReference="anchorPosition"
                anchorPosition={anchorEl ? { top: anchorEl.top, left: anchorEl.left } : undefined}
                slotProps={{
                    paper: {
                        sx: {
                            maxHeight: 48 * 4.5,
                            width: "20ch",
                        },
                    },
                }}
            >
                {selectedRow?.mimeType === "application/vnd.google-apps.folder" ? (
                    [
                        <MenuItem key="subscribe" onClick={handleSubscribe}>Subscribe</MenuItem>,
                        <MenuItem key="clone" onClick={handleClone}>Clone</MenuItem>,
                        <MenuItem key="delete" onClick={handleDelete}>Delete</MenuItem>,
                    ]
                ) : (
                    <MenuItem key="view" onClick={() => handleViewPdf(selectedRow)}>View</MenuItem>
                )}
            </Menu>

            <Modal open={isPdfModalOpen} onClose={() => setIsPdfModalOpen(false)}>
                <div style={{ padding: "20px", backgroundColor: "white", margin: "auto", maxWidth: "80%" }}>
                    <Grid container spacing={1} alignItems="center" mb={1.5}>
                        <div style={{ height: "80vh" }}>
                            <HitchPdfViewer sourceId={selectedFileId} source={FileSource.GOOGLE_DRIVE} />
                        </div>
                    </Grid>
                </div>
            </Modal>

            {feedback && <div style={{ marginTop: "20px", color: "red" }}>{feedback}</div>}
        </HitchPage>
    );
}

export default GoogleDrive;

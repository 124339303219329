import React from "react";
import { Grid } from "@mui/material";
import HitchTypography from "components/HitchTypography";

// Utility function to determine the contrasting text color
const getTextColor = (backgroundColor) => {
    const hex = backgroundColor.replace("#", "");
    const r = parseInt(hex.substr(0, 2), 16);
    const g = parseInt(hex.substr(2, 2), 16);
    const b = parseInt(hex.substr(4, 2), 16);
    // Calculate brightness (https://www.w3.org/TR/AERT/#color-contrast)
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    return brightness > 128 ? "#000000" : "#FFFFFF"; // Dark text on light background, white text on dark background
};

// Component to render a badge for a single label
const LabelBadge = ({ label }) => {
    const backgroundColor = `#${label.color}`;
    const textColor = getTextColor(backgroundColor);

    return (
        <div
            style={{
                display: "inline-block",
                backgroundColor,
                color: textColor,
                borderRadius: "12px",
                padding: "4px 8px",
                fontSize: "0.75rem",
                fontWeight: "bold",
                marginRight: "8px",
                marginBottom: "8px",
            }}
        >
            {label.name}
        </div>
    );
};

// Main component to render all labels in the HitchDisplayField style
const LabelBadges = ({ labels }) => {
    if (!labels || labels.length === 0) {
        return (
            <Grid container alignItems="center" spacing={1}>
                <Grid item xs={5}>
                    <HitchTypography variant="button" fontWeight="regular" color="text">
                        Labels:
                    </HitchTypography>
                </Grid>
                <Grid item xs={7}>
                    <HitchTypography variant="body2" color="white" fontWeight="medium">
                        N/A
                    </HitchTypography>
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid container alignItems="center" spacing={1}>
            <Grid item xs={5}>
                <HitchTypography variant="button" fontWeight="regular" color="text">
                    Labels:
                </HitchTypography>
            </Grid>
            <Grid item xs={7}>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {labels.map((label) => (
                        <LabelBadge key={label.id} label={label} />
                    ))}
                </div>
            </Grid>
        </Grid>
    );
};

export default LabelBadges;

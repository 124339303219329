
import PropTypes from "prop-types";
import { ErrorMessage, Field } from "formik";
import { Grid } from "@mui/material"; // Use Grid for layout
import HitchTypography from "components/HitchTypography";
import VuiInput from "components/VuiInput";

function HitchFormField({ editMode, label, name, displayValue, ...rest }) {
  return (
    <Grid container spacing={1} alignItems="center" mb={1.5}>
      {/* Label */}
      <Grid item xs={5}>
        <HitchTypography
          component="label"
          variant="button"
          color="white"
          fontWeight="bold"
          textTransform="capitalize"
        >
          {label}:
        </HitchTypography>
      </Grid>
      
      {/* Field or Read-Only Value */}
      <Grid item xs={7}>
        {editMode ? (
          <Field {...rest} name={name} as={VuiInput} />
        ) : (
          <Field name={name}>
            {({ field }) => (
              <HitchTypography
                variant="body2"
                color="white"
                fontWeight="medium"
                {...rest}
              >
                {displayValue || field.value || "N/A"} {/* Ensure fallback to "N/A" if value is undefined */}
              </HitchTypography>
            )}
          </Field>
        )}
      </Grid>

      {/* Validation Error Message */}
      <Grid item xs={12}>
        <HitchTypography component="div" variant="caption" color="error">
          <ErrorMessage name={name} />
        </HitchTypography>
      </Grid>
    </Grid>
  );
}

// Typechecking props for HitchFormField
HitchFormField.propTypes = {
  editMode: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  displayValue: PropTypes.string, // Optional display value for read-only mode
};

export default HitchFormField;

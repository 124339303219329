
// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/third/H1259DF13_46D9_4237_A2F1_6C7AE95AC2E6/index.js

import Card from "@mui/material/Card";
import VuiBox from "components/VuiBox";
import HitchTypography from "components/HitchTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { useHubConnections } from "context/HubConnectionsProvider";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HitchDataTable from "components/HitchDataTable";

function H342FB936_5DE4_4644_9D36_C29C3427430B() {

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <VuiBox pt={6} pb={3}>
                <VuiBox mb={3}>
                    <Card>
                        <VuiBox p={3} pl={0} lineHeight={1}>
                            <HitchTypography variant="h5" fontWeight="medium" color="white">
                                Calling Service
                            </HitchTypography>
                            <HitchTypography variant="button" fontWeight="regular" color="text">
                                Service
                            </HitchTypography>
                        </VuiBox>
                    </Card>
                </VuiBox>
            </VuiBox>
        </DashboardLayout>
    );
}

export default H342FB936_5DE4_4644_9D36_C29C3427430B;
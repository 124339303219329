import { Card, Grid, IconButton, Modal } from "@mui/material";
import DataTable from "examples/Tables/DataTable";
import { useState } from "react";
import dataTableData from "./framesample";
import HitchTitle from "components/HitchTitle";
import VuiBox from "components/VuiBox";
import { MdClose } from "react-icons/md";
import HitchTypography from "components/HitchTypography";

function HitchFrame({ open, onClose }) {

    const [frameTitle, setFrameTitle] = useState("Hitch Frame");

    return (
        <Modal
            open={open}
            onClose={onClose}
        >
            <VuiBox p={5}>
                <Card>
                    <Grid container>
                        <Grid xs={11} item>
                            <HitchTitle
                                title={"Frame we doing man!"}
                                subTitle={"Woo Hoo!"}
                            />
                        </Grid>
                        <Grid xs={1} item>
                            <HitchTypography>
                                <IconButton
                                    icon={<MdClose />}
                                    onClick={(() => onClose())}
                                />
                            </HitchTypography>
                        </Grid>
                        <Grid xs={12} item>

                            <DataTable table={dataTableData} entriesPerPage={false} canSearch />

                        </Grid>
                    </Grid>
                </Card>
            </VuiBox>
        </Modal>
    )
}

export default HitchFrame;
// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/third/sos/policies/index.js

import Card from "@mui/material/Card";
import VuiBox from "components/VuiBox";
import HitchTypography from "components/HitchTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import HitchDataTableExternalPagination from "components/HitchDataTableExternalPagination";
import HitchSelectSimple from "components/HitchSelectSimple";
import { useHubConnections } from "context/HubConnectionsProvider";
import { useEffect, useState } from "react";
import { Button, TextField, MenuItem } from "@mui/material";
import VuiButton from "components/VuiButton";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';

function SosPolicies() {
    const cols = [
        { accessor: 'name', Header: 'Policy Name', width: '25%' },
        { accessor: 'id', Header: 'Policy ID', width: '15%' },
        { accessor: 'policyStatus', Header: 'Status', width: '10%' },
        { accessor: 'updated', Header: 'Last Updated', width: '15%' },
        { accessor: 'department', Header: 'Owner/Department', width: '20%' },
        {
            accessor: 'actions',
            Header: 'Actions',
            width: '15%',
            Cell: ({ row }) => (
                <div style={{ display: 'flex', gap: '8px' }}>
                    <VuiButton variant="outlined" color="info" size="small" onClick={(e) => handleRowClick(row.original)}>View</VuiButton>
                    <VuiButton variant="outlined" color="warning" size="small" disabled={row.original.status !== 'Active'}>Edit</VuiButton>
                    <VuiButton variant="outlined" color="error" size="small" disabled={row.original.status !== 'Active'}>Archive</VuiButton>
                </div>
            )
        },
    ];
    const navigate = useNavigate();
    const [list, setList] = useState([]);
    const [page, setPage] = useState(1);

    const [departments, setDepartments] = useState([]);
    const [statuses, setStatuses] = useState([]);

    const [perPage, setPerPage] = useState(10);
    const [totalResults, setTotalResults] = useState(0);

    const [searchValue, setSearchValue] = useState('');
    const [statusFilter, setStatusFilter] = useState('');
    const [standardFilter, setStandardFilter] = useState('');
    const [departmentFilter, setDepartmentFilter] = useState('');
    const [dateFilter, setDateFilter] = useState('');

    const [dataTableData, setDataTableData] = useState({
        columns: cols,
        rows: []
    });

    const { dashboardConnection, hitchDashCommand } = useHubConnections();

    useEffect(() => {
        if (dashboardConnection) {
            dashboardConnection.on("a9c0c52a-482c-46cc-b094-e5ec75b09743", incomingPolicies);

            hitchDashCommand('sos', 'policyrepository', 'list', {
                page: 1,
                pageSize: 20
            });

            return () => {
                dashboardConnection.off("a9c0c52a-482c-46cc-b094-e5ec75b09743", incomingPolicies);
            }
        }
    }, [dashboardConnection]);

    // function incomingPolicies(data) {
    //     console.log('a9c0c52a-482c-46cc-b094-e5ec75b09743', data);
    //     setList(data?.policies);
    //     setStatuses(data?.statuses);
    //     setDepartments(data?.departments);
    // }

    function incomingPolicies(data) {
        console.log('a9c0c52a-482c-46cc-b094-e5ec75b09743', data);
        const { policies, statuses, departments } = data;

        const statusMap = statuses.reduce((acc, status) => {
            acc[status.id] = status.name;
            return acc;
        }, {});

        const departmentMap = departments.reduce((acc, department) => {
            acc[department.id] = department.name;
            return acc;
        }, {});

        const processedPolicies = policies.map(policy => ({
            ...policy,
            policyStatus: statusMap[policy.policyStatus] || 'Unknown',
            department: departmentMap[policy.department] || 'Unknown',
        }));

        setList(processedPolicies);
        setStatuses(statuses);
        setDepartments(departments);
    }


    useEffect(() => {
        setDataTableData(prev => ({
            ...prev,
            rows: list
        }));
    }, [list]);

    function onPageChange(newPage, newPerPage) {
        setPage(newPage);
        setPerPage(newPerPage);
    }

    const handleRowClick = (row) => {
        navigate(`./${row.id}`);
    };

    function createPolicy() {
        navigate(`./${uuidv4()}`);
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <VuiBox pt={6} pb={3}>
                <VuiBox mb={3}>
                    <Card>
                        <VuiBox p={3} pl={0} lineHeight={1}>
                            <HitchTypography variant="h5" fontWeight="medium" color="white">
                                Standards Orchestration System
                            </HitchTypography>
                            <HitchTypography variant="button" fontWeight="regular" color="text">
                                Policy Repository (MOCKED)
                                <VuiButton variant="outlined" color="white" onClick={(e) => navigate('./requirements')}>Requirements</VuiButton>
                            </HitchTypography>
                        </VuiBox>
                        <VuiBox p={3} pl={0}>
                            <div style={{ display: 'flex', gap: '16px', marginBottom: '16px', flexWrap: 'wrap' }}>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    label="Search"
                                    value={searchValue}
                                    onChange={(e) => setSearchValue(e.target.value)}
                                />
                                <TextField
                                    select
                                    variant="outlined"
                                    size="small"
                                    label="Status"
                                    value={statusFilter}
                                    onChange={(e) => setStatusFilter(e.target.value)}
                                    style={{ width: '150px' }}
                                >
                                    <MenuItem value="">All</MenuItem>
                                    <MenuItem value="Active">Active</MenuItem>
                                    <MenuItem value="Archived">Archived</MenuItem>
                                </TextField>
                                <TextField
                                    select
                                    variant="outlined"
                                    size="small"
                                    label="Department"
                                    value={departmentFilter}
                                    onChange={(e) => setDepartmentFilter(e.target.value)}
                                    style={{ width: '150px' }}
                                >
                                    <MenuItem value="">All</MenuItem>
                                    <MenuItem value="IT">IT</MenuItem>
                                    <MenuItem value="HR">HR</MenuItem>
                                    <MenuItem value="Compliance">Compliance</MenuItem>
                                    <MenuItem value="Security">Security</MenuItem>
                                </TextField>
                                <VuiButton variant="outlined" color="white" onClick={(e) => createPolicy()}>Create Policy</VuiButton>
                            </div>
                            <HitchDataTableExternalPagination
                                table={dataTableData}
                                canSearch={false}
                                totalResults={totalResults}
                                onPageChange={onPageChange}
                                paginated={true}
                                page={page}

                            />
                        </VuiBox>
                    </Card>
                </VuiBox>
            </VuiBox>
        </DashboardLayout>
    );
}

export default SosPolicies;

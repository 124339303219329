import React, { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { Grid, IconButton } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useHubConnections } from 'context/HubConnectionsProvider';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

export const FileSource = {
    HITCH_REPO: "HITCH_REPO",
    GOOGLE_DRIVE: "GOOGLE_DRIVE",
    GITHUB: "GITHUB",
    TEMP: "TEMP", // Added TEMP source
    DOC_REPO: "DOC_REPO"
};

function HitchPdfViewer({ source = FileSource.HITCH_REPO, repoId, sourceId, bearerToken, pdfUrl, valuepdfFile }) {
    const [pdfFile, setPdfFile] = useState(null);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [scale, setScale] = useState(window.innerWidth < 600 ? 0.6 : 1.0); // Smaller scale on mobile
    const { user } = useHubConnections();

    useEffect(() => {
        const handleResize = () => {
            const newScale = window.innerWidth < 600 ? 0.7 : 1.0;
            setScale(newScale);
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const fetchPdf = async () => {
            if (pdfUrl) {
                setPdfFile(pdfUrl);
                return;
            }

            if (sourceId && user?.id_token) {
                let fetchUrl;
                switch (source) {
                    case FileSource.HITCH_REPO:
                        fetchUrl = `${window._env_.HITCH_API}/api/render/pdf/${sourceId}`;
                        break;
                    case FileSource.DOC_REPO:
                        fetchUrl = `${window._env_.HITCH_API}/api/render/repo/${repoId}/doc/${sourceId}`;
                        break;
                    case FileSource.GOOGLE_DRIVE:
                        fetchUrl = `${window._env_.HITCH_API}/api/render/drive/${sourceId}`;
                        break;
                    case FileSource.GITHUB:
                        fetchUrl = `${window._env_.HITCH_API}/api/render/github/${sourceId}`;
                        break;
                    case FileSource.TEMP:
                        fetchUrl = `${window._env_.HITCH_API}/api/tempfile/${sourceId}`;
                        break;
                    default:
                        console.error("Invalid source");
                        return;
                }

                try {
                    const response = await fetch(fetchUrl, {
                        method: 'GET',
                        headers: { Authorization: `Bearer ${user.id_token}` },
                    });
                    const blob = await response.blob();

                    if (blob.type === 'application/pdf') {
                        setPdfFile(URL.createObjectURL(blob)); // Use the Blob URL
                    } else {
                        console.error('Invalid Blob type:', blob.type);
                        console.log('response', response);
                    }
                } catch (error) {
                    console.error('Error fetching PDF:', error);
                }
            }
        };

        fetchPdf();
    }, [source, sourceId, user, pdfUrl, valuepdfFile, user]);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        setPageNumber(1);
    };

    const zoomIn = () => setScale(scale + 0.1);
    const zoomOut = () => setScale(Math.max(scale - 0.1, 0.1));

    const downloadPdf = () => {
        if (pdfFile) {
            const link = document.createElement('a');
            link.href = pdfFile;
            link.download = 'downloaded.pdf';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    return (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{ minHeight: '100vh', backgroundColor: '#f4f4f4', padding: '1rem' }} // Optional background and padding for contrast
        >
            <Grid item xs={12} style={{ textAlign: 'center' }}>
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                    sx={{ marginBottom: 2 }}
                >
                    <Stack direction="row" spacing={1}>
                        <IconButton onClick={zoomOut} color="primary">
                            <RemoveIcon />
                        </IconButton>
                        <IconButton onClick={zoomIn} color="primary">
                            <AddIcon />
                        </IconButton>
                        <IconButton onClick={downloadPdf} color="primary">
                            <DownloadIcon />
                        </IconButton>
                    </Stack>
                    {numPages > 1 && (
                        <Pagination
                            count={numPages}
                            page={pageNumber}
                            onChange={(_, value) => setPageNumber(value)}
                            variant="outlined"
                            color="primary"
                        />
                    )}
                </Stack>
            </Grid>

            {pdfFile ? (
                <Grid
                    item
                    xs={12}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        maxHeight: '80vh',
                        maxWidth: '90%',
                        overflow: 'auto',
                        border: '1px solid #ddd',
                        backgroundColor: '#fff',
                        borderRadius: '8px',
                        padding: '1rem',
                    }}
                >
                    <Document
                        file={pdfFile}
                        onLoadSuccess={onDocumentLoadSuccess}
                        onLoadError={(error) => console.error('Error loading PDF:', error)}
                    >
                        <Page pageNumber={pageNumber} scale={scale} />
                    </Document>
                </Grid>
            ) : (
                <Grid item xs={12} style={{ textAlign: 'center', padding: '1rem' }}>
                    <p>Loading PDF...</p>
                </Grid>
            )}
        </Grid>
    );
}

export default HitchPdfViewer;
import React, { useMemo } from "react";
import VuiBox from "components/VuiBox";
import ReactApexChart from "react-apexcharts";

const PieChartWidget = ({ chartData }) => {
    // Extract values, labels, and colors from the chartData
    const series = chartData.map(item => item.value); // Get values for the pie chart
    const labels = chartData.map(item => item.label); // Get labels for the pie chart
    const colors = chartData.map(item => item.color); // Get colors for the pie chart

    const pieChartOptions = {
        labels: labels,
        colors: colors,
        chart: {
            width: "100%",
            stroke: {
                show: false,
            },
        },
        states: {
            hover: {
                filter: {
                    type: "none",
                },
            },
        },
        legend: {
            show: false,
        },
        stroke: {
            show: false,
        },
        dataLabels: {
            enabled: false,
        },
        hover: { mode: null },
        plotOptions: {
            pie: {
                expandOnClick: false,
            },
        },
        fill: {
            colors: colors,
        },
        tooltip: {
            enabled: true,
            theme: "dark",
        },
    };

    return (
        <VuiBox>
            {useMemo(
                () => (
                    <ReactApexChart
                        options={pieChartOptions}
                        series={series}
                        type="pie"
                        width="150%"
                        height="150%"
                    />
                ),
                [pieChartOptions, series]
            )}
        </VuiBox>
    );
};

export default PieChartWidget;
import Grid from "@mui/material/Grid";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import HitchTypography from "components/HitchTypography";
import VuiSelect from "components/VuiSelect";

// NewProduct page components
import FormField from "layouts/ecommerce/products/new-product/components/FormField";

function Pricing() {
  return (
    <VuiBox>
      <HitchTypography variant="h5" color="white">
        Pricing
      </HitchTypography>
      <VuiBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={3}>
            <FormField type="text" label="price" placeholder="99.00" />
          </Grid>
          <Grid item xs={12} sm={4}>
            <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <HitchTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
                color="white"
              >
                Currency
              </HitchTypography>
            </VuiBox>
            <VuiSelect
              defaultValue={{ value: "usd", label: "USD" }}
              options={[
                { value: "btc", label: "BTC" },
                { value: "cny", label: "CNY" },
                { value: "eur", label: "EUR" },
                { value: "gbp", label: "GBP" },
                { value: "inr", label: "INR" },
                { value: "use", label: "USD" },
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <FormField type="text" label="SKU" placeholder="71283476591" />
          </Grid>
        </Grid>
      </VuiBox>
      <VuiBox mt={1}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <HitchTypography component="label" variant="caption" fontWeight="bold" color="white">
                Project Tags
              </HitchTypography>
            </VuiBox>
            <VuiSelect
              defaultValue={[
                { value: "in stock", label: "In Stock" },
                { value: "out of stock", label: "Out of Stock" },
              ]}
              options={[
                { value: "black friday", label: "Black Friday" },
                { value: "expired", label: "Expired", isDisabled: true },
                { value: "out of stock", label: "Out of Stock" },
                { value: "in stock", label: "In Stock" },
                { value: "sale", label: "Sale" },
              ]}
              size="large"
              isMulti
            />
          </Grid>
        </Grid>
      </VuiBox>
    </VuiBox>
  );
}

export default Pricing;

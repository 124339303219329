// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/third/sos/workflow/index.js 

import Card from "@mui/material/Card";
import VuiBox from "components/VuiBox";
import HitchTypography from "components/HitchTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import HitchDataTableExternalPagination from "components/HitchDataTableExternalPagination";
import { useEffect, useState } from "react";
import { Button, TextField, MenuItem } from "@mui/material";
import VuiButton from "components/VuiButton";
import { useNavigate } from "react-router-dom";

function SosWorkflow() {
    const cols = [
        { accessor: 'workflowName', Header: 'Workflow Name', width: '20%' },
        { accessor: 'workflowId', Header: 'Workflow ID', width: '10%' },
        { accessor: 'status', Header: 'Status', width: '15%' },
        { accessor: 'lastTriggered', Header: 'Last Triggered', width: '20%' },
        { accessor: 'nextRun', Header: 'Next Scheduled Run', width: '20%' },
        {
            accessor: 'actions',
            Header: 'Actions',
            width: '15%',
            Cell: ({ row }) => {
                const { status } = row.original;
                return (
                    <div style={{ display: 'flex', gap: '8px' }}>
                        <VuiButton variant="outlined" color="info" size="small">View</VuiButton>
                        <VuiButton 
                            variant="outlined" 
                            color="warning" 
                            size="small"
                        >
                            Edit
                        </VuiButton>
                        {
                            status === 'Active' ? (
                                <VuiButton 
                                    variant="outlined" 
                                    color="success" 
                                    size="small"
                                >
                                    Trigger
                                </VuiButton>
                            ) : status === 'Paused' ? (
                                <VuiButton 
                                    variant="outlined" 
                                    color="success" 
                                    size="small"
                                >
                                    Resume
                                </VuiButton>
                            ) : null
                        }
                        <VuiButton variant="outlined" color="error" size="small">Delete</VuiButton>
                    </div>
                );
            }
        }
    ];
    const navigate = useNavigate();
    const [list, setList] = useState([]);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalResults, setTotalResults] = useState(0);

    const [searchValue, setSearchValue] = useState('');
    const [statusFilter, setStatusFilter] = useState('');
    const [typeFilter, setTypeFilter] = useState('');

    const [dataTableData, setDataTableData] = useState({
        columns: cols,
        rows: []
    });

    useEffect(() => {
        const mockWorkflows = [
            { workflowName: 'Workflow A', workflowId: '001', status: 'Active', lastTriggered: '2024-01-15', nextRun: '2024-01-22', type: 'Compliance Review' },
            { workflowName: 'Workflow B', workflowId: '002', status: 'Paused', lastTriggered: '2023-12-10', nextRun: 'N/A', type: 'Risk Assessment' },
            { workflowName: 'Workflow C', workflowId: '003', status: 'Completed', lastTriggered: '2024-01-05', nextRun: 'N/A', type: 'Policy Update' },
            { workflowName: 'Workflow D', workflowId: '004', status: 'Active', lastTriggered: '2024-01-18', nextRun: '2024-01-25', type: 'Compliance Review' }
        ];

        let filtered = [...mockWorkflows];

        if (searchValue) {
            filtered = filtered.filter(item =>
                item.workflowName.toLowerCase().includes(searchValue.toLowerCase()) ||
                item.workflowId.toLowerCase().includes(searchValue.toLowerCase())
            );
        }
        if (statusFilter) {
            filtered = filtered.filter(item => item.status === statusFilter);
        }
        if (typeFilter) {
            filtered = filtered.filter(item => item.type === typeFilter);
        }

        setTotalResults(filtered.length);
        const start = (page - 1) * perPage;
        const end = start + perPage;
        filtered = filtered.slice(start, end);
        setList(filtered);
    }, [page, perPage, searchValue, statusFilter, typeFilter]);

    useEffect(() => {
        setDataTableData(prev => ({
            ...prev,
            rows: list
        }));
    }, [list]);

    function onPageChange(newPage, newPerPage) {
        setPage(newPage);
        setPerPage(newPerPage);
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <VuiBox pt={6} pb={3}>
                <VuiBox mb={3}>
                    <Card>
                        <VuiBox p={3} pl={0} lineHeight={1}>
                            <HitchTypography variant="h5" fontWeight="medium" color="white">
                                Standards Orchestration System
                            </HitchTypography>
                            <HitchTypography variant="button" fontWeight="regular" color="text">
                                Workflow Automation (MOCKED)
                                <VuiButton variant="outlined" color="white" onClick={(e) => navigate('./requirements')}>Requirements</VuiButton>
                            </HitchTypography>
                        </VuiBox>
                        <VuiBox p={3} pl={0}>
                            <div style={{ display: 'flex', gap: '16px', marginBottom: '16px', flexWrap: 'wrap' }}>
                                <TextField 
                                    variant="outlined"
                                    size="small"
                                    label="Search"
                                    value={searchValue}
                                    onChange={(e) => setSearchValue(e.target.value)}
                                />
                                <TextField
                                    select
                                    variant="outlined"
                                    size="small"
                                    label="Status"
                                    value={statusFilter}
                                    onChange={(e) => setStatusFilter(e.target.value)}
                                    style={{ width: '150px' }}
                                >
                                    <MenuItem value="">All</MenuItem>
                                    <MenuItem value="Active">Active</MenuItem>
                                    <MenuItem value="Paused">Paused</MenuItem>
                                    <MenuItem value="Completed">Completed</MenuItem>
                                </TextField>
                                <TextField
                                    select
                                    variant="outlined"
                                    size="small"
                                    label="Type"
                                    value={typeFilter}
                                    onChange={(e) => setTypeFilter(e.target.value)}
                                    style={{ width: '180px' }}
                                >
                                    <MenuItem value="">All</MenuItem>
                                    <MenuItem value="Compliance Review">Compliance Review</MenuItem>
                                    <MenuItem value="Risk Assessment">Risk Assessment</MenuItem>
                                    <MenuItem value="Policy Update">Policy Update</MenuItem>
                                </TextField>
                                <VuiButton variant="outlined" color="white">Create Workflow</VuiButton>
                            </div>
                            <HitchDataTableExternalPagination
                                table={dataTableData}
                                canSearch={false}
                                totalResults={totalResults}
                                onPageChange={onPageChange}
                                paginated={true}
                                page={page}
                            />
                        </VuiBox>
                    </Card>
                </VuiBox>
            </VuiBox>
        </DashboardLayout>
    );
}

export default SosWorkflow;

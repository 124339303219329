
import { createContext, useContext, useReducer, useMemo } from "react";
import PropTypes from "prop-types";

const HitchAIUI = createContext();
HitchAIUI.displayName = "HitchAIUIContext";

function reducer(state, action) {
  const isStateUnchanged = (key, value) => state[key] === value;

  switch (action.type) {
    case "MINI_SIDENAV":
      return isStateUnchanged("miniSidenav", action.value) ? state : { ...state, miniSidenav: action.value };
    case "TRANSPARENT_SIDENAV":
      return isStateUnchanged("transparentSidenav", action.value) ? state : { ...state, transparentSidenav: action.value };
    case "SIDENAV_COLOR":
      return isStateUnchanged("sidenavColor", action.value) ? state : { ...state, sidenavColor: action.value };
    case "TRANSPARENT_NAVBAR":
      return isStateUnchanged("transparentNavbar", action.value) ? state : { ...state, transparentNavbar: action.value };
    case "FIXED_NAVBAR":
      return isStateUnchanged("fixedNavbar", action.value) ? state : { ...state, fixedNavbar: action.value };
    case "OPEN_CONFIGURATOR":
      return isStateUnchanged("openConfigurator", action.value) ? state : { ...state, openConfigurator: action.value };
    case "DIRECTION":
      return isStateUnchanged("direction", action.value) ? state : { ...state, direction: action.value };
    case "LAYOUT":
      return isStateUnchanged("layout", action.value) ? state : { ...state, layout: action.value };
    default:
      console.error("Unhandled action type:", action);
      return state;
  }
}

function HitchAIUIControllerProvider({ children }) {
  const initialState = {
    miniSidenav: false,
    transparentSidenav: false,
    sidenavColor: "info",
    transparentNavbar: true,
    fixedNavbar: true,
    openConfigurator: false,
    direction: "ltr",
    layout: "dashboard",
  };

  const [controller, dispatch] = useReducer(reducer, initialState);

  const contextValue = useMemo(() => [controller, dispatch], [controller]);

  return <HitchAIUI.Provider value={contextValue}>{children}</HitchAIUI.Provider>;
}

function useHitchAIUIController() {
  const context = useContext(HitchAIUI);

  if (!context) {
    throw new Error("useHitchAIUIController should be used inside the HitchAIUIControllerProvider.");
  }

  return context;
}

const setMiniSidenav = (dispatch, value) => dispatch({ type: "MINI_SIDENAV", value });
const setTransparentSidenav = (dispatch, value) => dispatch({ type: "TRANSPARENT_SIDENAV", value });
const setSidenavColor = (dispatch, value) => dispatch({ type: "SIDENAV_COLOR", value });
const setTransparentNavbar = (dispatch, controller, value) => {
  //console.log("Attempting to dispatch TRANSPARENT_NAVBAR:", value);

  if (typeof value !== "boolean") {
    //console.error("Invalid value for TRANSPARENT_NAVBAR:", value);
    return;
  }

  if (controller.transparentNavbar === value) {
    //console.log("Skipping redundant dispatch for TRANSPARENT_NAVBAR:", value);
    return;
  }

  dispatch({ type: "TRANSPARENT_NAVBAR", value });
};



const setFixedNavbar = (dispatch, value) => dispatch({ type: "FIXED_NAVBAR", value });
const setOpenConfigurator = (dispatch, value) => dispatch({ type: "OPEN_CONFIGURATOR", value });
const setDirection = (dispatch, value) => dispatch({ type: "DIRECTION", value });
const setLayout = (dispatch, value) => dispatch({ type: "LAYOUT", value });

export {
  HitchAIUIControllerProvider,
  useHitchAIUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setSidenavColor,
  setTransparentNavbar,
  setFixedNavbar,
  setOpenConfigurator,
  setDirection,
  setLayout,
};

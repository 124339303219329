
import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import VuiBox from "components/VuiBox";
import VuiSwitch from "components/VuiSwitch";
import HitchTypography from "components/HitchTypography";
import { useAdminHubConnections } from "context/HubAdminConnectionProvider";
import { IoShieldHalf, IoBusinessOutline, IoDocument } from 'react-icons/io5'; // Import custom icons

function UserRole({ accountName, roles }) {
  const {
    adminConnection,
    hitchUser,
    isConnectionReady,
  } = useAdminHubConnections();

  // Use state to keep track of role activity
  const [roleStates, setRoleStates] = useState(() =>
    roles.reduce((acc, role) => {
      acc[role.roleName] = role.isActive || false; // Initialize with `false` if `isActive` is undefined
      return acc;
    }, {})
  );

  useEffect(() => {
    // Update roleStates if roles prop changes, ensuring it's always in sync
    setRoleStates(
      roles.reduce((acc, role) => {
        acc[role.roleName] = role.isActive || false; // Ensure default value
        return acc;
      }, {})
    );
  }, [roles]);

  const handleToggle = async (role) => {
    const { roleName, userRoleId, userId } = role; // Extract userId and userRoleId from role object
    const newActiveState = !roleStates[roleName];
    setRoleStates((prevState) => ({
      ...prevState,
      [roleName]: newActiveState,
    }));

    try {
      // Call the SignalR method to update the role status in the backend
      await adminConnection.invoke(
        "SetAccountUserRoleActiveStatus",
        hitchUser?.userId,  // sessionId: Current logged-in user's ID
        userId,             // userId: ID of the user to whom the role belongs
        userRoleId,         // userRoleId: ID of the user role being updated
        newActiveState      // isActive: New active state
      );
      
      // Optionally handle any response from the server if needed
    } catch (error) {
      console.error("Error updating role status:", error);
      // Revert the toggle in case of error
      setRoleStates((prevState) => ({
        ...prevState,
        [roleName]: !newActiveState,
      }));
    }
  };

  return (
    <Card sx={{ minHeight: "150px", p: 3, backgroundColor: "#1e2230", borderRadius: "12px" }}> {/* Adjust card background */}
      <VuiBox display="flex" alignItems="center" mb="16px">
        <IoBusinessOutline size={32} color="#f0f0f0" style={{ marginRight: "8px" }} /> {/* Organization icon with lighter color */}
        <HitchTypography
          variant="h6"
          fontWeight="bold"
          textTransform="capitalize"
          color="white" // Set text color to white
          sx={{ textShadow: "0 0 5px rgba(0, 0, 0, 0.5)" }} // Add subtle text shadow for better readability
        >
          Roles for Organization: {accountName}
        </HitchTypography>
      </VuiBox>
      <VuiBox lineHeight={1.25}>
        {roles.map((role) => (
          <VuiBox 
            key={role.roleName} 
            display="flex" 
            alignItems="center" 
            mb="14px"
            p={1}
            sx={{
              backgroundColor: "rgba(255, 255, 255, 0.05)", // Add a slightly lighter background for each role
              borderRadius: "8px",
            }}
          >
            <VuiSwitch
              color="info"
              checked={roleStates[role.roleName] !== undefined ? roleStates[role.roleName] : false} // Ensure checked is always a boolean
              onChange={() => handleToggle(role)} // Pass the whole role object
            />
            <IoDocument size={20} color="#f0f0f0" style={{ marginLeft: "12px" }} /> {/* Role item icon with lighter color */}
            <HitchTypography
              variant="button"
              fontWeight="regular"
              color="white" // Set text color to white
              ml={2}
              sx={{ textShadow: "0 0 3px rgba(0, 0, 0, 0.5)" }} // Add text shadow for better readability
            >
              {role.roleName}
            </HitchTypography>
          </VuiBox>
        ))}
      </VuiBox>
    </Card>
  );
}

export default UserRole;

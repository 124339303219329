// FILE: /UI\hitch-ai-ui-2\src\examples\Navbars\DefaultNavbar\index.js

import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Container from "@mui/material/Container";
import Icon from "@mui/material/Icon";
import VuiBox from "components/VuiBox";
import HitchTypography from "components/HitchTypography";
import VuiButton from "components/VuiButton";
import DefaultNavbarMobile from "examples/Navbars/DefaultNavbar/DefaultNavbarMobile";
import breakpoints from "assets/theme/base/breakpoints";
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import PagesMenu from "examples/Navbars/DefaultNavbar/Menus/PagesMenu";
import AuthenticationMenu from "examples/Navbars/DefaultNavbar/Menus/AuthenticationMenu";
import EcommerceMenu from "examples/Navbars/DefaultNavbar/Menus/EcommerceMenu";
import ApplicationsMenu from "examples/Navbars/DefaultNavbar/Menus/ApplicationsMenu";
import hitch from "assets/images/hitch.png";
import VuiAvatar from "components/VuiAvatar";

function HomepageNavbar({ routes, transparent = false, light = false, action = false }) {
    const { borderCol } = colors;
    const { borderWidth } = borders;
    const [pagesMenu, setPagesMenu] = useState(false);
    const [authenticationMenu, setAuthenticationMenu] = useState(false);
    const [ecommerceMenu, setEcommerceMenu] = useState(false);
    const [applicationsMenu, setApplicationsMenu] = useState(false);
    const [mobileNavbar, setMobileNavbar] = useState(false);
    const [mobileView, setMobileView] = useState(false);

    const openPagesMenu = ({ currentTarget }) => setPagesMenu(currentTarget);
    const closePagesMenu = () => setPagesMenu(false);
    const openAuthenticationMenu = ({ currentTarget }) => setAuthenticationMenu(currentTarget);
    const closeAuthenticationMenu = () => setAuthenticationMenu(false);
    const openEcommerceMenu = ({ currentTarget }) => setEcommerceMenu(currentTarget);
    const closeEcommerceMenu = () => setEcommerceMenu(false);
    const openApplicationsMenu = ({ currentTarget }) => setApplicationsMenu(currentTarget);
    const closeApplicationsMenu = () => setApplicationsMenu(false);
    const openMobileNavbar = ({ currentTarget }) => setMobileNavbar(currentTarget.parentNode);
    const closeMobileNavbar = () => setMobileNavbar(false);

    useEffect(() => {
        // A function that sets the display state for the DefaultNavbarMobile.
        function displayMobileNavbar() {
            if (window.innerWidth < breakpoints.values.lg) {
                setMobileView(true);
                setMobileNavbar(false);
            } else {
                setMobileView(false);
                setMobileNavbar(false);
            }
        }

        /** 
         The event listener that's calling the displayMobileNavbar function when 
         resizing the window.
        */
        window.addEventListener("resize", displayMobileNavbar);

        // Call the displayMobileNavbar function to set the state with the initial value.
        displayMobileNavbar();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", displayMobileNavbar);
    }, []);

    return (
        <Container>
            <VuiBox
                py={2}
                px={{ xs: transparent ? 4 : 3, sm: transparent ? 2 : 3, lg: transparent ? 0 : 3 }}
                my={2}
                border={transparent ? "unset" : `${borderWidth[1]} solid ${borderCol.navbar}`}
                width="calc(100% - 48px)"
                borderRadius="xl"
                shadow={transparent ? "none" : "md"}
                color={light ? "white" : "dark"}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                position="absolute"
                left="50%"
                zIndex={3}
                maxWidth="1044px"
                sx={({ palette: { gradients }, functions: { linearGradient } }) => ({
                    background: transparent
                        ? "transparent"
                        : linearGradient(gradients.navbar.main, gradients.navbar.state, gradients.navbar.deg),
                    backdropFilter: transparent ? "unset" : "blur(42px)",
                    transform: "translate(-50%, 0px)",
                })}
            >
                <VuiBox 
                    component={Link} 
                    to="/" 
                    py={transparent ? 1.5 : 0.75} 
                    lineHeight={1}
                    display="flex"
                    flexDirection="row" 
                    alignItems="center"
                    justifyContent="start" 
                    gap={2}
                    >
                    <VuiAvatar src={hitch} />
                    <HitchTypography
                        variant="button"
                        textGradient={false}
                        color="white"
                        fontSize={18}
                        letterSpacing={2}
                        fontWeight="medium"
                        sx={{
                            margin: "0 auto",
                        }}
                    >
                        HITCH.AI
                    </HitchTypography>
                </VuiBox>
                <VuiBox color="inherit" display={{ xs: "none", lg: "flex" }} m={0} p={0}>
                    {/* <DefaultNavbarLink
                        name="pages"
                        openHandler={openPagesMenu}
                        closeHandler={closePagesMenu}
                        light={light}
                    />
                    <DefaultNavbarLink
                        name="authentication"
                        openHandler={openAuthenticationMenu}
                        closeHandler={closeAuthenticationMenu}
                        light={light}
                    />

                    <DefaultNavbarLink
                        name="application"
                        openHandler={openApplicationsMenu}
                        closeHandler={closeApplicationsMenu}
                        light={light}
                    />
                    <DefaultNavbarLink
                        name="ecommerce"
                        openHandler={openEcommerceMenu}
                        closeHandler={closeEcommerceMenu}
                        light={light}
                    /> */}
                </VuiBox>
                {action &&
                    (action.type === "internal" ? (
                        <VuiBox display={{ xs: "none", lg: "inline-block" }}>
                            <VuiButton
                                component={Link}
                                to={action.route}
                                variant="gradient"
                                color={action.color ? action.color : "info"}
                                size="small"
                            >
                                {action.label}
                            </VuiButton>
                        </VuiBox>
                    ) : (
                        <VuiBox display={{ xs: "none", lg: "inline-block" }}>
                            <VuiButton
                                minWidth="110px"
                                component="a"
                                href={action.route}
                                target="_blank"
                                rel="noreferrer"
                                color={transparent ? "white" : action.color ? action.color : "info"}
                                sx={({
                                    typography: { size },
                                    functions: { pxToRem },
                                    borders: { borderRadius },
                                }) => ({
                                    fontSize: pxToRem(size.sm),
                                    minWidth: "110px",
                                    color: transparent ? "black !important" : "",
                                    borderRadius: transparent ? borderRadius.xl : "",
                                })}
                            >
                                {action.label}
                            </VuiButton>
                        </VuiBox>
                    ))}
                <VuiBox
                    display={{ xs: "inline-block", lg: "none" }}
                    lineHeight={0}
                    py={1.5}
                    pl={1.5}
                    color="white"
                    sx={{ cursor: "pointer" }}
                    onClick={openMobileNavbar}
                >
                    <Icon fontSize="default">{mobileNavbar ? "close" : "menu"}</Icon>
                </VuiBox>
            </VuiBox>
            <PagesMenu routes={routes} open={pagesMenu} close={closePagesMenu} />
            <AuthenticationMenu
                routes={routes}
                open={authenticationMenu}
                close={closeAuthenticationMenu}
            />
            <EcommerceMenu routes={routes} open={ecommerceMenu} close={closeEcommerceMenu} />
            <ApplicationsMenu routes={routes} open={applicationsMenu} close={closeApplicationsMenu} />
            {mobileView && (
                <>
                <DefaultNavbarMobile routes={routes} open={mobileNavbar} close={closeMobileNavbar} />
                </>
            )}
        </Container>
    );
}

HomepageNavbar.propTypes = {
    routes: PropTypes.arrayOf(PropTypes.object).isRequired,
    transparent: PropTypes.bool,
    light: PropTypes.bool,
    action: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.shape({
            type: PropTypes.oneOf(["external", "internal"]).isRequired,
            route: PropTypes.string.isRequired,
            color: PropTypes.oneOf([
                "primary",
                "secondary",
                "info",
                "success",
                "warning",
                "error",
                "dark",
                "light",
            ]),
            label: PropTypes.string.isRequired,
        }),
    ]),
};

export default HomepageNavbar;

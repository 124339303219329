// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2?src/third/HC5E92791_B9E7_4D7E_AE22_90CCE4EC0B43/GitHubRepositories/GitHubRepository.js

import { Card, Grid, Divider } from "@mui/material";
import HitchDisplayField from "components/HitchDisplayField";
import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";
import HitchTypography from "components/HitchTypography";
import { useHubConnections } from "context/HubConnectionsProvider";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import GitHubFiles from "./GitHubFiles";

function GitHubRepository() {
    const { repoId } = useParams();
    const [repository, setRepository] = useState({});
    const [editMode, setEditMode] = useState(false);
    const navigate = useNavigate();

    const { dashboardConnection, hitchDashCommand } = useHubConnections();

    useEffect(() => {
        if (dashboardConnection) {
            const subscribeToEvents = () => {
                dashboardConnection.on("fe0c2f9b-7418-4b52-b116-bae928764e08", (data) => {
                    setRepository(data);
                    console.log('Repository Data Received:', data);
                });

                hitchDashCommand("github", "repositoriesrepositories", "get", { repoId });
            };

            subscribeToEvents();

            return () => {
                dashboardConnection.off("fe0c2f9b-7418-4b52-b116-bae928764e08");
            };
        }
    }, [dashboardConnection, repoId, hitchDashCommand]);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <VuiBox pt={6} pb={3}>
                <Card>
                    <VuiBox p={3}>
                        <HitchTypography variant="h4" mb={2}>
                            Repository: {repository?.name || "Loading..."}
                        </HitchTypography>
                        <Divider />
                        {!editMode ? (
                            <VuiBox mt={3}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <HitchDisplayField label="Owner" value={repository?.owner?.login} />
                                        <HitchDisplayField
                                            label="Visibility"
                                            value={repository?.visibility ? repository.visibility.toUpperCase() : "N/A"}
                                        />
                                        <HitchDisplayField label="Default Branch" value={repository?.default_branch} />
                                        <HitchDisplayField label="Description" value={repository?.description || "No description"} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <HitchDisplayField label="Forks Count" value={repository?.forks_count} />
                                        <HitchDisplayField label="Stargazers Count" value={repository?.stargazers_count} />
                                        <HitchDisplayField label="Watchers Count" value={repository?.watchers_count} />
                                        <HitchDisplayField label="Size (KB)" value={repository?.size} />
                                    </Grid>
                                </Grid>
                                <Divider sx={{ my: 2 }} />
                                <VuiBox>
                                    <HitchTypography variant="h5" mb={1}>
                                        Actions
                                    </HitchTypography>
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <VuiButton
                                                variant="contained"
                                                color="primary"
                                                onClick={() => console.log("Fetch branches")}
                                            >
                                                View Branches
                                            </VuiButton>
                                        </Grid>
                                        <Grid item>
                                            <VuiButton
                                                variant="contained"
                                                color="secondary"
                                                onClick={() => console.log("View commits")}
                                            >
                                                View Commits
                                            </VuiButton>
                                        </Grid>
                                        <Grid item>
                                            <VuiButton
                                                variant="outlined"
                                                color="error"
                                                onClick={() => console.log("Delete Repository")}
                                            >
                                                Delete Repository
                                            </VuiButton>
                                        </Grid>
                                    </Grid>
                                </VuiBox>
                                <VuiButton
                                    variant="outlined"
                                    color="white"
                                    onClick={() => navigate("/third/hc5e92791_b9e7_4d7e_ae22_90cce4ec0b43/GitHubRepositories")}
                                    sx={{ mt: 2 }}
                                >
                                    Back to Repositories
                                </VuiButton>
                            </VuiBox>
                        ) : (
                            <VuiBox mt={3}>
                                <form onSubmit={() => console.log("Save edits")}>
                                    <HitchFormField
                                        name="name"
                                        label="Repository Name"
                                        defaultValue={repository?.name}
                                        editMode={editMode}
                                    />
                                    <HitchFormField
                                        name="description"
                                        label="Description"
                                        defaultValue={repository?.description}
                                        editMode={editMode}
                                    />
                                    <VuiBox mt={2} display="flex" justifyContent="space-between">
                                        <VuiButton variant="contained" color="primary" type="submit">
                                            Save
                                        </VuiButton>
                                        <VuiButton variant="outlined" color="secondary" onClick={() => setEditMode(false)}>
                                            Cancel
                                        </VuiButton>
                                    </VuiBox>
                                </form>
                            </VuiBox>
                        )}
                    </VuiBox>

                    <GitHubFiles repoId={repoId} />
                </Card>
            </VuiBox>
        </DashboardLayout>
    );
}

export default GitHubRepository;

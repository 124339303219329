
import PropTypes from "prop-types";
import VuiBox from "components/VuiBox";
import HitchTypography from "components/HitchTypography";
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

function TableCell({
  width = "auto",
  align = "left",
  padding = [],
  noBorder = false,
  children
}) {
  const { grey } = colors;
  const { borderWidth } = borders;

  return (
    <VuiBox
      component="th"
      width={width}
      pt={padding[0]}
      pr={padding[1]}
      pb={padding[2]}
      pl={padding[3]}
      textAlign={align}
      borderBottom={noBorder ? 0 : `${borderWidth[1]} solid ${grey[700]}`}
    >
      <HitchTypography component="div" variant="body2" color="text">
        {children}
      </HitchTypography>
    </VuiBox>
  );
}

// Typechecking props for TableCell
TableCell.propTypes = {
  width: PropTypes.string,
  children: PropTypes.node.isRequired,
  align: PropTypes.string,
  padding: PropTypes.arrayOf(PropTypes.number),
  noBorder: PropTypes.bool,
};

export default TableCell;

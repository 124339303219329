// AI HINT:  org: HitchSoftware | repoId: hitch-ai | path: \UI\hitch-ai-ui-2\src\context\OrgConnectionProvider.jsx

import { createContext, useContext, useEffect, useState } from 'react';
import { HubConnectionsProvider, useHubConnections } from './HubConnectionsProvider';
import * as signalR from '@microsoft/signalr';

const OrgConnectionContext = createContext();
const AccountProfile = "5177a0fa-23ca-49cd-9c0e-f9d31490a27c";

export const OrgConnectionProvider = ({ children }) => {
    const [accountId, setAccountId] = useState('');
    const [account, setAccount] = useState(null);
    const [accountConnection, setAccountConnection] = useState(null);
    const [accountConnectionReady, setAccountConnectionReady] = useState(false);
    const { jwtToken, hitchUser } = useHubConnections();

    useEffect(() => {
        if (hitchUser && hitchUser.accountId) {
            if (accountId != hitchUser.accountId) {
                console.log('updating Account Id.');
                setAccountId(hitchUser.accountId);
            }
        }

    }, [hitchUser]);

    function handleConnectionError(error) {
        console.error('error', error);
        if (error?.statusCode === 401 || error?.status === 401 || error.message === 'login_required') {
            console.log('should we redirect???????????');
        }
    }

    useEffect(() => {
        async function initializeConnection() {
            if (!jwtToken || !accountId) {
                return;
            }

            const connection = new signalR.HubConnectionBuilder()
                .withUrl(`${window._env_.HITCH_API}/sessionHub?sessionId=${accountId}`, {
                    accessTokenFactory: () => jwtToken
                })
                .withAutomaticReconnect()
                .build();

            try {
                await connection.start();
                setAccountConnection(connection);
                setAccountConnectionReady(true);
                connection.on(AccountProfile, setAccount);
            } catch (error) {
                handleConnectionError(error);
                setAccountConnectionReady(false);
            }

            return () => {
                if (accountConnection) {
                    accountConnection.off(AccountProfile, setAccount);
                    accountConnection.stop().then(() => console.log('Organization Hub Disconnected'));
                    setAccountConnectionReady(false);
                }
            }
        }
        initializeConnection();

    }, [accountId, jwtToken]);

    function accountCommand(feature, operation, payload = {}, onSuccess, onError) {
        const payloadWithToken = { ...payload };
        if (accountConnection) {
            accountConnection.invoke("HitchCommand", accountId, 'account', feature, operation, payloadWithToken)
                .then(() => {
                    if (onSuccess && typeof onSuccess === 'function') {
                        onSuccess();
                    }
                })
                .catch(error => {
                    if (onError && typeof onError === 'function') {
                        onError(error);
                    } else {
                        console.error("HitchCommand error:", error);
                    }
                });
        } else {
            console.error("Organization connection not established.");
        }
    }

    useEffect(() => {
        if (accountId && accountConnectionReady) {
            accountCommand('profile', 'get', {
                theAccountId: accountId
            });
        }
    }, [accountId, accountConnectionReady]);

    const value = {
        accountId,
        account,
        accountConnection,
        accountConnectionReady,
        accountCommand
    };

    return (
        <OrgConnectionContext.Provider value={value}>
            {children}
        </OrgConnectionContext.Provider>
    );
}

export const useOrgConnection = () => useContext(OrgConnectionContext);

// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/third/H4FED2823_7FDB_41F8_ADAD_A562F4A2E709/index.js

import Card from "@mui/material/Card";
import VuiBox from "components/VuiBox";
import HitchTypography from "components/HitchTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import { useHubConnections } from "context/HubConnectionsProvider";
import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import VuiButton from "components/VuiButton";
import HitchDisplayField from "components/HitchDisplayField";
import { Form, Formik } from "formik";
import HitchSwitch from "components/HitchSwitch";

function H4FED2823_7FDB_41F8_ADAD_A562F4A2E709() {
    const [config, setConfig] = useState({});
    const [editMode, setEditMode] = useState(false);
    const {
        dashboardConnection,
        hitchDashCommand,
        hitchUser
    } = useHubConnections();

    function saveConfig(values) {
        console.log('save', values);

        const theConfiguration = {
            Configuration: {
                serviceId: config.serviceId,
                connectionId: config.connectionId,
                gptModelName: config.gptModelName,
                complianceMonitoring: values.complianceMonitoring
            }
        };

        console.log('Saving the following Configuration Data', theConfiguration);
        hitchDashCommand('sos', 'config', 'update', theConfiguration, () => {
            setEditMode(false);
        });
    }

    useEffect(() => {
        if (dashboardConnection) {
            const subscribeToEvents = () => {
                dashboardConnection.on("1d1ebff6-5808-434d-964b-a1a2c2a9d752", setConfig);
                hitchDashCommand('sos', 'config', 'get', {});
            };

            subscribeToEvents();

            return () => {
                dashboardConnection.off("1d1ebff6-5808-434d-964b-a1a2c2a9d752", setConfig);
            };
        }
    }, [dashboardConnection]);

    useEffect(() => {
        if (config) {
            console.log('the Config', config);
        }
    }, [config]);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <VuiBox pt={6} pb={3}>
                <VuiBox mb={3}>
                    <Card>
                        <VuiBox p={3} pl={0} lineHeight={1}>
                            <HitchTypography variant="h5" fontWeight="medium" color="white">
                                Standards Orchestration System
                            </HitchTypography>
                            <HitchTypography variant="button" fontWeight="regular" color="text">
                                Configuration
                            </HitchTypography>
                        </VuiBox>
                        <VuiBox p={3}>
                            {!editMode ? (
                                <VuiBox mb={2}>
                                    <Grid
                                        item
                                        xs={12}
                                        md={5}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                        }}
                                    >
                                        <VuiButton
                                            variant="outlined"
                                            color="white"
                                            sx={({ palette: { white } }) => ({
                                                border: `1px solid ${white.main}`,
                                                color: white.main,
                                            })}
                                            onClick={() => setEditMode(true)}
                                        >
                                            Edit
                                        </VuiButton>
                                    </Grid>

                                    <HitchDisplayField label="Compliance Monitoring" value={config?.complianceMonitoring} />
                                </VuiBox>
                            ) : (
                                <>
                                    <Formik
                                        initialValues={{
                                            botHcomplianceMonitoringandle: config?.complianceMonitoring || false
                                        }}
                                        onSubmit={(values) => saveConfig(values)}
                                    >
                                        {({ values, setFieldValue, handleSubmit }) => (
                                            <Form onSubmit={handleSubmit}>
                                                <VuiBox>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        md={5}
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent: "flex-end",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <VuiButton
                                                            type="submit"
                                                            variant="contained"
                                                            color="white"
                                                            sx={({ palette: { white } }) => ({
                                                                border: `1px solid ${white.main}`,
                                                            })}
                                                        >
                                                            save
                                                        </VuiButton>
                                                        <VuiButton
                                                            variant="outlined"
                                                            color="white"
                                                            sx={({ palette: { white } }) => ({
                                                                border: `1px solid ${white.main}`,
                                                                color: white.main,
                                                            })}
                                                            onClick={() => setEditMode(false)}
                                                        >
                                                            cancel
                                                        </VuiButton>
                                                    </Grid>

                                                    <HitchSwitch
                                                        label="Compliance Monitoring"
                                                        name="complianceMonitoring"
                                                        onLabel="On"
                                                        offLabel="Off"
                                                        editMode={editMode}
                                                    />

                                                </VuiBox>
                                            </Form>
                                        )}
                                    </Formik>


                                </>
                            )}

                        </VuiBox>
                    </Card>
                </VuiBox>
            </VuiBox>
        </DashboardLayout>
    );
}

export default H4FED2823_7FDB_41F8_ADAD_A562F4A2E709;
// FILE: /UI/hitch-ai-ui-2/src/third/HC5E92791_B9E7_4D7E_AE22_90CCE4EC0B43/Issues/EventsSection.js

import { useState, useEffect } from "react";
import VuiBox from "components/VuiBox";
import HitchTypography from "components/HitchTypography";

function EventsSection({ issueId }) {
    const [events, setEvents] = useState([]);

    useEffect(() => {
        // Fetch events from API
        fetchEvents(issueId).then(setEvents);
    }, [issueId]);

    const fetchEvents = async (issueId) => {
        // Replace with API call
        return [];
    };

    return (
        <VuiBox p={3}>
            <HitchTypography variant="h6">Events</HitchTypography>
            {events.map((event) => (
                <HitchTypography key={event.id} variant="body2">
                    {event.event}: {event.created_at}
                </HitchTypography>
            ))}
        </VuiBox>
    );
}

export default EventsSection;

// FILE: /UI/hitch-ai-ui-2/src/assets/theme/components/appBar.js


export default {
  defaultProps: {
    color: "transparent",
  },

  styleOverrides: {
    root: {
      boxShadow: "none",
    },
  },
};


import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import VuiBox from "components/VuiBox";
import HitchTypography from "components/HitchTypography";
import VuiBadge from "components/VuiBadge";
import VuiButton from "components/VuiButton";
import { Modal, Box, Checkbox, TextField } from "@mui/material";
import { FiPlus, FiX } from "react-icons/fi";

function HitchFormBadgeList({
    label,
    name,
    sourceList = [],
    allowCustom = false,
}) {
    const [modalOpen, setModalOpen] = useState(false);
    const [tempValue, setTempValue] = useState("");
    const [selectedList, setSelectedList] = useState([]);
    const [error, setError] = useState("");

    const { values, setFieldValue } = useFormikContext(); // Access Formik context

    // Initialize selectedList from Formik initial values
    useEffect(() => {
        const initialValue = values[name];
        if (initialValue) {
            setSelectedList(initialValue.split(",").map((item) => item.trim()));
        }
    }, [values, name]);

    // Update Formik value when selectedList changes
    useEffect(() => {
        setFieldValue(name, selectedList.join(","));
    }, [selectedList, name, setFieldValue]);

    const toggleModal = () => {
        setModalOpen((prev) => !prev);
        setTempValue("");
        setError("");
    };

    const addBadge = () => {
        const newValue = tempValue.trim();
        if (!newValue || selectedList.includes(newValue)) {
            setError("Duplicate or empty value not allowed.");
            return;
        }
        setSelectedList((prev) =>
            [...prev, newValue].sort((a, b) => a.localeCompare(b))
        );
        setTempValue("");
        setError("");
    };

    const handleCheckboxChange = (value) => {
        setSelectedList((prev) =>
            prev.includes(value)
                ? prev.filter((item) => item !== value)
                : [...prev, value].sort((a, b) => a.localeCompare(b))
        );
    };

    const removeBadge = (value) => {
        setSelectedList((prev) => prev.filter((item) => item !== value));
    };

    return (
        <VuiBox mb={1.5}>
            <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <HitchTypography
                    component="label"
                    variant="caption"
                    color="white"
                    fontWeight="bold"
                    textTransform="capitalize"
                >
                    {label}
                </HitchTypography>
            </VuiBox>
            <VuiBox display="flex" flexWrap="wrap" gap={1}>
                {selectedList.map((item, index) => (
                    <VuiBadge
                        key={index}
                        variant="contained"
                        size="sm"
                        badgeContent={
                            <span>
                                {item}{" "}
                                <FiX
                                    style={{ cursor: "pointer", marginLeft: "4px" }}
                                    onClick={() => removeBadge(item)}
                                />
                            </span>
                        }
                        circular
                        container
                    />
                ))}
                <VuiBadge
                    variant="contained"
                    size="sm"
                    circular
                    badgeContent={<FiPlus />}
                    onClick={toggleModal}
                    style={{ cursor: "pointer" }}
                />
            </VuiBox>
            <VuiBox mt={0.75}>
                <HitchTypography component="div" variant="caption" color="error">
                    {error && <span>{error}</span>}
                </HitchTypography>
            </VuiBox>

            <Modal open={modalOpen} onClose={toggleModal}>
                <Box
                    p={3}
                    bgcolor="background.paper"
                    borderRadius={2}
                    width={400}
                    mx="auto"
                    mt="15%"
                >
                    {sourceList.length > 0 ? (
                        <>
                            <HitchTypography variant="h6" mb={2}>
                                Select from List
                            </HitchTypography>
                            {sourceList.map((item, index) => (
                                <VuiBox
                                    key={index}
                                    display="flex"
                                    alignItems="center"
                                    mb={1}
                                >
                                    <Checkbox
                                        checked={selectedList.includes(item)}
                                        onChange={() => handleCheckboxChange(item)}
                                    />
                                    <HitchTypography>{item}</HitchTypography>
                                </VuiBox>
                            ))}
                            {allowCustom && (
                                <>
                                    <HitchTypography variant="h6" mt={2} mb={1}>
                                        Add Custom Value
                                    </HitchTypography>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        value={tempValue}
                                        onChange={(e) => setTempValue(e.target.value)}
                                    />
                                    {error && (
                                        <HitchTypography variant="caption" color="error">
                                            {error}
                                        </HitchTypography>
                                    )}
                                    <VuiButton
                                        variant="outlined"
                                        color="primary"
                                        fullWidth
                                        onClick={addBadge}
                                        mt={1}
                                    >
                                        Add
                                    </VuiButton>
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            <HitchTypography variant="h6" mb={2}>
                                Add Value
                            </HitchTypography>
                            <TextField
                                fullWidth
                                variant="outlined"
                                value={tempValue}
                                onChange={(e) => setTempValue(e.target.value)}
                            />
                            {error && (
                                <HitchTypography variant="caption" color="error">
                                    {error}
                                </HitchTypography>
                            )}
                            <VuiButton
                                variant="outlined"
                                color="primary"
                                fullWidth
                                onClick={addBadge}
                                mt={1}
                            >
                                Add
                            </VuiButton>
                        </>
                    )}
                    <VuiButton
                        variant="outlined"
                        color="secondary"
                        fullWidth
                        onClick={toggleModal}
                        mt={2}
                    >
                        Close
                    </VuiButton>
                </Box>
            </Modal>
        </VuiBox>
    );
}

HitchFormBadgeList.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    sourceList: PropTypes.array,
    allowCustom: PropTypes.bool,
};

export default HitchFormBadgeList;


// FILE: /UI/hitch-ai-ui-2/src/components/HitchInputHelperText/index.js

import React from 'react';
import PropTypes from 'prop-types';
import HitchTypography from 'components/HitchTypography';

function HitchInputHelperText({ helperText }) {
  return (
    <HitchTypography variant="caption" color="white">
      {helperText}
    </HitchTypography>
  );
}

// PropTypes for the HitchInputHelperText component
HitchInputHelperText.propTypes = {
  helperText: PropTypes.string,
};

export default HitchInputHelperText;

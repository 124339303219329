import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import HitchTypography from "components/HitchTypography";

// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// ProductPage page components
import ProductImages from "layouts/ecommerce/products/product-page/components/ProductImages";
import ProductInfo from "layouts/ecommerce/products/product-page/components/ProductInfo";

// Data
import dataTableData from "layouts/ecommerce/products/product-page/data/dataTableData";

function ProductPage() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <Card
          sx={({ functions: { linearGradient }, palette: { gradients } }) => ({
            overflow: "visible",
            background: linearGradient(
              gradients.cardDark.main,
              gradients.cardDark.state,
              gradients.cardDark.deg
            ),
          })}
        >
          <VuiBox p={3}>
            <VuiBox mb={5}>
              <HitchTypography variant="lg" fontWeight="medium" color="white">
                Product Details
              </HitchTypography>
            </VuiBox>

            <Grid container spacing={4}>
              <Grid item xs={12} lg={6} xl={5}>
                <ProductImages />
              </Grid>
              <Grid item xs={12} lg={6} sx={{ mx: "auto" }}>
                <ProductInfo />
              </Grid>
            </Grid>

            <VuiBox mt={8} mb={2}>
              <DataTable
                table={dataTableData}
                entriesPerPage={false}
                showTotalEntries={false}
                isSorted={false}
              />
            </VuiBox>
          </VuiBox>
        </Card>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ProductPage;

import { useState } from "react";
import PropTypes from "prop-types";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import VuiBox from "components/VuiBox";
import HitchTypography from "components/HitchTypography";
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';

function SosTodo({ 
  color = "info", 
  title, 
  date, 
  id, 
  company, 
  defaultChecked = false, 
  noDivider = false,
  loadFrame 
}) {
  const { borderWidth } = borders;
  const { info } = colors;
  const [openMenu, setOpenMenu] = useState(null);
  const navigate = useNavigate();

  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(null);

  function viewDetails() {
    navigate(`/third/sos/tasks/${id}`);
  }

  function runCheck() {
    console.log(`Simultaing run check, making up an id to get the modal squared away.  But this isn't written yet.`);
    loadFrame(uuidv4());
  }

  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      keepMounted
    >
      <MenuItem onClick={viewDetails}>View Details</MenuItem>
      <MenuItem onClick={(e) => runCheck()}>Check</MenuItem>
      <MenuItem onClick={handleCloseMenu}>Get Recommendations</MenuItem>
    </Menu>
  );

  return (
    <VuiBox
      component="li"
      width="100%"
      pr={2}
      mb={2}
      borderLeft={`${borderWidth[4]} solid ${colors[color].main}`}
      sx={{ listStyle: "none" }}
    >
      <VuiBox width="100%" pl={1} ml={2}>
        <VuiBox display="flex" alignItems="center">
          <Checkbox
            defaultChecked={defaultChecked}
            sx={{
              color: info.main,
              "&.Mui-checked": {
                color: info.main,
              },
            }}
          />
          <VuiBox ml={0.2} lineHeight={1}>
            <HitchTypography variant="button" color="white" fontWeight="medium">
              {title}
            </HitchTypography>
          </VuiBox>
          <VuiBox ml="auto" color="secondary" pr={3} lineHeight={0}>
            <Icon
              fontSize="default"
              sx={{ cursor: "pointer", color: "#fff" }}
              onClick={handleOpenMenu}
            >
              more_horiz
            </Icon>
          </VuiBox>
          {renderMenu()}
        </VuiBox>
        <VuiBox
          display="flex"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
          mt={2}
          ml={{ xs: 0, md: 3 }}
          pl={0.5}
        >
          <VuiBox lineHeight={1} mb={{ xs: 1, sm: 0 }}>
            <HitchTypography display="block" variant="caption" fontWeight="regular" color="white">
              Date
            </HitchTypography>
            <HitchTypography variant="caption" fontWeight="bold" color="white">
              {date}
            </HitchTypography>
          </VuiBox>
          <VuiBox ml={{ xs: 0, sm: "auto" }} mb={{ xs: 1, sm: 0 }} lineHeight={1}>
            <HitchTypography display="block" variant="caption" fontWeight="regular" color="white">
              Task Identifier
            </HitchTypography>
            <HitchTypography variant="caption" fontWeight="bold" color="white">
              {id}
            </HitchTypography>
          </VuiBox>
          <VuiBox mx={{ xs: 0, sm: "auto" }} lineHeight={1}>
            <HitchTypography display="block" variant="caption" fontWeight="regular" color="white">
              Company
            </HitchTypography>
            <HitchTypography variant="caption" fontWeight="bold" color="white">
              {company}
            </HitchTypography>
          </VuiBox>
        </VuiBox>
      </VuiBox>
      {noDivider ? null : <Divider light sx={{ marginBottom: 0 }} />}
    </VuiBox>
  );
}

// Typechecking props for the Todo
SosTodo.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  defaultChecked: PropTypes.bool,
  noDivider: PropTypes.bool,
};

export default SosTodo;


import { Icon, IconButton, Menu } from "@mui/material";
import VuiBadge from "components/VuiBadge";
import NotificationItem from "examples/Items/NotificationItem";
import {
    navbarIconButton,
} from "hitch/dashboard/styles";
import { useState } from "react";
import team2 from "assets/images/team-2.jpg";
import logoSpotify from "assets/images/small-logos/logo-spotify.svg";

function UserNotifications() {

    const [openMenu, setOpenMenu] = useState(false);
    const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
    const handleCloseMenu = () => setOpenMenu(false);
    const [alerts, setAlerts] = useState([]);

    const renderMenu = () => (
        <Menu
            anchorEl={openMenu}
            anchorReference={null}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            open={Boolean(openMenu)}
            onClose={handleCloseMenu}
            sx={{ mt: 2 }}
        >
            <NotificationItem
                image={<img src={team2} alt="person" />}
                title={["New message", "from Laur"]}
                date="13 minutes ago"
                onClick={handleCloseMenu}
            />
            <NotificationItem
                image={<img src={logoSpotify} alt="person" />}
                title={["New album", "by Travis Scott"]}
                date="1 day"
                onClick={handleCloseMenu}
            />
            <NotificationItem
                color="secondary"
                image={
                    <Icon fontSize="small" sx={{ color: ({ palette: { white } }) => white.main }}>
                        payment
                    </Icon>
                }
                title={["", "Payment successfully completed"]}
                date="2 days"
                onClick={handleCloseMenu}
            />
        </Menu>
    );


    return (
        <>
            <IconButton
                size="small"
                color="white"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
            >
                <VuiBadge
                    color="error"
                    badgeContent={alerts.length} // Show the number of notifications
                    invisible={alerts.length === 0} // Hide badge if no notifications
                >
                    <Icon>notifications</Icon>
                </VuiBadge>
            </IconButton>
            {renderMenu()}
        </>
    );
}

export default UserNotifications;
import { Box, Grid } from "@mui/material";
import VuiAvatar from "components/VuiAvatar";
import HitchTypography from "components/HitchTypography";

function GitHubUser({ user }) {

    return (
        <Grid container>
            <Grid item xs={12}>
                <Box display="flex" alignItems="center" gap={2}>
                    <VuiAvatar
                        alt={user.login}
                        src={user.avatar_url}
                        sx={{ width: 48, height: 48 }}
                    />
                    <Box>
                        <HitchTypography variant="h4" fontWeight="medium">
                            {user.login}
                        </HitchTypography>
                        <HitchTypography variant="body2" color="text.secondary">
                            <a
                                href={user.html_url}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    textDecoration: "none",
                                    color: "#007bff",
                                }}
                            >
                                View Profile
                            </a>
                        </HitchTypography>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
}

export default GitHubUser;

// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/components/SimpleFormField/index.js

import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import VuiBox from "components/VuiBox";
import HitchTypography from "components/HitchTypography";
import VuiInput from "components/VuiInput";

function SimpleFormField({ label, value: propValue, onChange, ...rest }) {
  const [value, setValue] = useState(propValue || "");

  useEffect(() => {
    setValue(propValue || "");
  }, [propValue]);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <VuiBox mb={1.5}>
      <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
        <HitchTypography
          component="label"
          variant="caption"
          color="white"
          fontWeight="bold"
          textTransform="capitalize"
        >
          {label}
        </HitchTypography>
      </VuiBox>
      <VuiInput {...rest} value={value} onChange={handleChange} />
    </VuiBox>
  );
}

// Typechecking props for SimpleFormField
SimpleFormField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

SimpleFormField.defaultProps = {
  value: "",
  onChange: null,
};

export default SimpleFormField;

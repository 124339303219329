import VuiBox from "components/VuiBox";
import HitchTypography from "components/HitchTypography";

function BillingInformation() {
  return (
    <>
      <HitchTypography variant="h6" fontWeight="medium" color="white">
        Billing Information
      </HitchTypography>
      <VuiBox
        component="li"
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        sx={({ functions: { linearGradient }, palette: { gradients } }) => ({
          background: linearGradient(gradients.box.main, gradients.box.state, gradients.box.deg),
        })}
        borderRadius="lg"
        p={3}
        mt={2}
      >
        <VuiBox width="100%" display="flex" flexDirection="column" lineHeight={1}>
          <VuiBox mb={2}>
            <HitchTypography
              variant="button"
              fontWeight="medium"
              textTransform="capitalize"
              color="white"
            >
              Oliver Liam
            </HitchTypography>
          </VuiBox>
          <VuiBox mb={1} lineHeight={0}>
            <HitchTypography variant="caption" color="text">
              Company Name:&nbsp;
              <HitchTypography
                variant="caption"
                fontWeight="medium"
                textTransform="capitalize"
                color="text"
              >
                Viking Burrito
              </HitchTypography>
            </HitchTypography>
          </VuiBox>
          <VuiBox mb={1} lineHeight={0}>
            <HitchTypography variant="caption" color="text">
              Email Address:&nbsp;
              <HitchTypography variant="caption" fontWeight="medium" color="text">
                oliver@burrito.com
              </HitchTypography>
            </HitchTypography>
          </VuiBox>
          <HitchTypography variant="caption" color="text">
            VAT Number:&nbsp;
            <HitchTypography variant="caption" fontWeight="medium" color="text">
              FRB1235476
            </HitchTypography>
          </HitchTypography>
        </VuiBox>
      </VuiBox>
    </>
  );
}

export default BillingInformation;


// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/pages/HoganSmithIntake/index.js

import { useState } from "react";
import VuiBox from "components/VuiBox";
import HitchTypography from "components/HitchTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import GradientBorder from "examples/GradientBorder";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import radialGradient from "assets/theme/functions/radialGradient";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import bgBasic from "assets/images/background-basic-auth.png";

function HoganSmithIntake() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [ssn, setSsn] = useState("");
  const [dateOfTreatment, setDateOfTreatment] = useState("");
  const [providerFirstName, setProviderFirstName] = useState("");
  const [providerLastName, setProviderLastName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleFormReset = () => {
    setFirstName("");
    setLastName("");
    setDateOfBirth("");
    setSsn("");
    setDateOfTreatment("");
    setProviderFirstName("");
    setProviderLastName("");
  };

  const handleSubmit = () => {
    setIsLoading(true);
    // Implement form submission logic here
    // After submission, setIsLoading(false);
  };

  return (
    <BasicLayout
      title="Hogan-Smith Intake"
      description="In order to request your medical records, please provide some information."
      image={bgBasic}
    >
      <GradientBorder borderRadius={borders.borderRadius.form} minWidth="100%" maxWidth="100%">
        <VuiBox
          component="form"
          role="form"
          borderRadius="inherit"
          p="45px"
          sx={({ palette: { secondary } }) => ({
            backgroundColor: secondary.main,
          })}
        >
          <HitchTypography
            color="white"
            fontWeight="bold"
            textAlign="center"
            mb="24px"
            sx={({ typography: { size } }) => ({
              fontSize: size.lg,
            })}
          >
            Your Information
          </HitchTypography>
          
          <VuiBox mb={2}>
            <VuiBox mb={1} ml={0.5}>
              <HitchTypography component="label" variant="button" color="white" fontWeight="medium">
                First Name
              </HitchTypography>
            </VuiBox>
            <GradientBorder
              minWidth="100%"
              borderRadius={borders.borderRadius.lg}
              padding="1px"
              backgroundImage={radialGradient(
                palette.gradients.borderLight.main,
                palette.gradients.borderLight.state,
                palette.gradients.borderLight.angle
              )}
            >
              <VuiInput
                placeholder="First name..."
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                aria-label="First name"
                sx={({ typography: { size } }) => ({
                  fontSize: size.sm,
                })}
              />
            </GradientBorder>
          </VuiBox>

          <VuiBox mb={2}>
            <VuiBox mb={1} ml={0.5}>
              <HitchTypography component="label" variant="button" color="white" fontWeight="medium">
                Last Name
              </HitchTypography>
            </VuiBox>
            <GradientBorder
              minWidth="100%"
              borderRadius={borders.borderRadius.lg}
              padding="1px"
              backgroundImage={radialGradient(
                palette.gradients.borderLight.main,
                palette.gradients.borderLight.state,
                palette.gradients.borderLight.angle
              )}
            >
              <VuiInput
                placeholder="Last name..."
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                aria-label="Last name"
                sx={({ typography: { size } }) => ({
                  fontSize: size.sm,
                })}
              />
            </GradientBorder>
          </VuiBox>

          <VuiBox mb={2}>
            <VuiBox mb={1} ml={0.5}>
              <HitchTypography component="label" variant="button" color="white" fontWeight="medium">
                Date Of Birth
              </HitchTypography>
            </VuiBox>
            <GradientBorder
              minWidth="100%"
              borderRadius={borders.borderRadius.lg}
              padding="1px"
              backgroundImage={radialGradient(
                palette.gradients.borderLight.main,
                palette.gradients.borderLight.state,
                palette.gradients.borderLight.angle
              )}
            >
 
              <VuiInput
                placeholder="05/23/1970"
                type="date"
                value={dateOfBirth}
                onChange={(e) => setDateOfBirth(e.target.value)}
                aria-label="Date of Birth"
                sx={({ typography: { size } }) => ({
                  fontSize: size.sm,
                })}
              />
            </GradientBorder>
          </VuiBox>

          <VuiBox mb={2}>
            <VuiBox mb={1} ml={0.5}>
              <HitchTypography component="label" variant="button" color="white" fontWeight="medium">
                Social Security Number
              </HitchTypography>
            </VuiBox>
            <GradientBorder
              minWidth="100%"
              borderRadius={borders.borderRadius.lg}
              padding="1px"
              backgroundImage={radialGradient(
                palette.gradients.borderLight.main,
                palette.gradients.borderLight.state,
                palette.gradients.borderLight.angle
              )}
            >
              <VuiInput
                placeholder="123-45-6789"
                type="password"
                value={ssn}
                onChange={(e) => setSsn(e.target.value)}
                aria-label="Social Security Number"
                sx={({ typography: { size } }) => ({
                  fontSize: size.sm,
                })}
              />
            </GradientBorder>
          </VuiBox>

          <HitchTypography
            color="white"
            fontWeight="bold"
            textAlign="center"
            mb="24px"
            mt="50px"
            sx={({ typography: { size } }) => ({
              fontSize: size.lg,
            })}
          >
            Your Medical Visit Information
          </HitchTypography>

          <VuiBox mb={2}>
            <VuiBox mb={1} ml={0.5}>
              <HitchTypography component="label" variant="button" color="white" fontWeight="medium">
                Date of Treatment
              </HitchTypography>
            </VuiBox>
            <GradientBorder
              minWidth="100%"
              borderRadius={borders.borderRadius.lg}
              padding="1px"
              backgroundImage={radialGradient(
                palette.gradients.borderLight.main,
                palette.gradients.borderLight.state,
                palette.gradients.borderLight.angle
              )}
            >
              <VuiInput
                placeholder="10/16/2024"
                type="date"
                value={dateOfTreatment}
                onChange={(e) => setDateOfTreatment(e.target.value)}
                aria-label="Date of Treatment"
                sx={({ typography: { size } }) => ({
                  fontSize: size.sm,
                })}
              />
            </GradientBorder>
          </VuiBox>

          <VuiBox mb={2}>
            <VuiBox mb={1} ml={0.5}>
              <HitchTypography component="label" variant="button" color="white" fontWeight="medium">
                Provider's First Name
              </HitchTypography>
            </VuiBox>
            <GradientBorder
              minWidth="100%"
              borderRadius={borders.borderRadius.lg}
              padding="1px"
              backgroundImage={radialGradient(
                palette.gradients.borderLight.main,
                palette.gradients.borderLight.state,
                palette.gradients.borderLight.angle
              )}
            >
              <VuiInput
                placeholder="First name..."
                value={providerFirstName}
                onChange={(e) => setProviderFirstName(e.target.value)}
                aria-label="Provider's First Name"
                sx={({ typography: { size } }) => ({
                  fontSize: size.sm,
                })}
              />
            </GradientBorder>
          </VuiBox>

          <VuiBox mb={2}>
            <VuiBox mb={1} ml={0.5}>
              <HitchTypography component="label" variant="button" color="white" fontWeight="medium">
                Provider's Last Name
              </HitchTypography>
            </VuiBox>
            <GradientBorder
              minWidth="100%"
              borderRadius={borders.borderRadius.lg}
              padding="1px"
              backgroundImage={radialGradient(
                palette.gradients.borderLight.main,
                palette.gradients.borderLight.state,
                palette.gradients.borderLight.angle
              )}
            >
              <VuiInput
                placeholder="Last name..."
                value={providerLastName}
                onChange={(e) => setProviderLastName(e.target.value)}
                aria-label="Provider's Last Name"
                sx={({ typography: { size } }) => ({
                  fontSize: size.sm,
                })}
              />
            </GradientBorder>
          </VuiBox>

          <VuiBox mt={4} mb={1}>
            <VuiButton color="info" fullWidth onClick={handleSubmit} disabled={isLoading}>
              {isLoading ? "Submitting..." : "NEXT"}
            </VuiButton>
          </VuiBox>

          <VuiBox mt={2} mb={1}>
            <VuiButton color="warning" fullWidth onClick={handleFormReset}>
              CLEAR
            </VuiButton>
          </VuiBox>
          
        </VuiBox>
      </GradientBorder>
    </BasicLayout>
  );
}

export default HoganSmithIntake;

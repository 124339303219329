
// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE:/UI/hitch-ai-ui-2/src/hitch/administration/organization/Roles/index.js

import VuiBox from "components/VuiBox";
import HitchTypography from "components/HitchTypography";
import DataTable from "examples/Tables/DataTable";
import VuiButton from "components/VuiButton";
import HitchModal from "components/HitchModal";
import DefaultCell from "layouts/ecommerce/products/product-page/components/DefaultCell";
import { useEffect, useState } from "react";
import { useHubConnections } from "context/HubConnectionsProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { v4 as uuidv4 } from 'uuid';

function Roles() {
    const [dataTable, setDataTable] = useState([]);
    const { dashboardConnection, hitchDashCommand, isConnectionReady, hitchUser, errorSnack, successSnack } = useHubConnections();
    const [showModal, setShowModal] = useState(false);
    const [selectedRole, setSelectedRole] = useState(null);
    const [showRoleModal, setShowRoleModal] = useState(false);
    const [newRoleName, setNewRoleName] = useState("");
    const [nameError, setNameError] = useState("");

    useEffect(() => {
        if (dashboardConnection && isConnectionReady) {
            dashboardConnection.on('59dcc752-3322-4b7d-8090-b482e88e4002', (data) => {
                if (data.StackTraceString) {
                    errorSnack(`Error: ${data.Message}`);
                } else {
                    setDataTable(data);
                }
            });

            hitchDashCommand('account', 'role', 'list', {});

            return () => {
                if (dashboardConnection) {
                    dashboardConnection.off("59dcc752-3322-4b7d-8090-b482e88e4002");
                }
            };
        }
    }, [dashboardConnection, isConnectionReady]);

    const handleRemoveRole = (row) => {
        setSelectedRole({ roleId: row.id, roleName: row.name });
        setShowModal(true);
    };

    const handleModalYes = () => {
        if (selectedRole) {
            deleteRole(selectedRole.roleId);
        }
        setShowModal(false);
    };

    const handleModalNo = () => {
        setShowModal(false);
    };

    const newRole = () => {
        setShowRoleModal(true);
    };

    const handleRoleModalClose = () => {
        setShowRoleModal(false);
        setNewRoleName("");
        setNameError("");
    };

    const handleAddRole = () => {
        if (isRoleNameUnique(newRoleName)) {
            const newRole = {
                id: uuidv4(),
                name: newRoleName,
                accountId: hitchUser.accountId,
                createdBy: hitchUser.id,
                role: 1,
                userRole: [],
            };
            createRole(newRole);
            handleRoleModalClose();
        } else {
            setNameError("Role name must be unique.");
        }
    };

    const isRoleNameUnique = (name) => {
        return !dataTable.some(role => role.name.toLowerCase() === name.toLowerCase());
    };

    const createRole = (roleObject) => {
        if (dashboardConnection && isConnectionReady) {
            hitchDashCommand('account', 'role', 'update', {
                role: roleObject
            });
            successSnack(`Role '${roleObject.name}' added successfully.`);
            setDataTable(prevData => [...prevData, roleObject]);
        }
    };

    const deleteRole = (roleId) => {
        if (dashboardConnection && isConnectionReady) {
            hitchDashCommand('account', 'role', 'delete', {
                roleId: roleId
            });
            successSnack(`Role removed successfully.`);
            setDataTable(prevData => prevData.filter(role => role.id !== roleId));
        }
    };

    function RefreshRoles() {
        hitchDashCommand('account', 'role', 'list', {});
    }

    const columns = [
        { Header: "ROLE NAME", accessor: "name", width: "70%" },
        { Header: "ACTIONS", accessor: "actions", align: "start", width: "30%" },
    ];

    // Modify rows mapping to apply 'key' directly to the component
    const rows = dataTable.map((row) => ({
        key: row.id, // Ensure key is not spread
        name: <DefaultCell key={`name-${row.id}`}>{row.name}</DefaultCell>,
        actions: (
            <VuiButton
                key={`actions-${row.id}`}
                variant="contained"
                color="error"
                onClick={() => handleRemoveRole(row)}
            >
                <FontAwesomeIcon icon={faTrash} />
            </VuiButton>
        ),
    }));

    return (
        <>
            <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="30px">
                <HitchTypography variant="h6" fontWeight="medium" color="white">
                    Roles
                </HitchTypography>
                <VuiButton
                    variant="outlined"
                    color="white"
                    onClick={RefreshRoles}
                    sx={{ minWidth: '120px' }}
                >
                    Refresh
                </VuiButton>
                <VuiButton
                    variant="outlined"
                    color="white"
                    onClick={newRole}
                    sx={{ minWidth: '120px' }}
                >
                    Add Role
                </VuiButton>
            </VuiBox>
            <VuiBox mt={2}>
                <DataTable
                    table={{ columns, rows }}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    isSorted={false}
                    renderRow={(row) => (
                        <div key={row.key}>{/* Render with key directly */}
                            {row.name}
                            {row.actions}
                        </div>
                    )}
                />
            </VuiBox>
            <HitchModal
                open={showModal}
                onClose={handleModalNo}
                title="Confirm Removal"
                question="Are you sure you want to remove this role?"
                yesLabel="Yes"
                noLabel="No"
                onYes={handleModalYes}
                onNo={handleModalNo}
                dismissOnClickOutside={false}
            />
            <HitchModal
                open={showRoleModal}
                onClose={handleRoleModalClose}
                title="Add New Role"
                question=""
                yesLabel="Add"
                noLabel="Cancel"
                onYes={handleAddRole}
                onNo={handleRoleModalClose}
                dismissOnClickOutside={false}
            >
                <VuiBox mb={2}>
                    <input
                        type="text"
                        placeholder="Role Name"
                        value={newRoleName}
                        onChange={(e) => setNewRoleName(e.target.value)}
                        style={{ width: '100%', padding: '10px', marginTop: '10px', borderRadius: '4px', border: '1px solid #ccc' }}
                    />
                    {nameError && (
                        <HitchTypography variant="caption" color="error">
                            {nameError}
                        </HitchTypography>
                    )}
                </VuiBox>
            </HitchModal>
        </>
    );
}

export default Roles;


import VuiBox from "components/VuiBox";
import HitchTypography from "components/HitchTypography";
import { Card, CardContent, Divider } from "@mui/material";
import { useEffect } from "react";
import HitchDisplayField from "components/HitchDisplayField";

function GitHubCheckRunEventViewer({data}) {
    const formatDate = (date) => (date ? new Date(date).toLocaleString() : "N/A");
    return (
        <VuiBox>
            <Divider sx={{ my: 2 }} />

            {/* Event Details */}
            <Card>
                <CardContent>
                    <HitchTypography variant="h5" fontWeight="bold" color="white" gutterBottom>
                        Github Check Run Event
                    </HitchTypography>
                    <HitchDisplayField label="Feature Id" value={data?.featureId} />
                    <HitchDisplayField label="Operation Id" value={data?.operationId} />
                    <HitchDisplayField label="State" value={data?.state} />
                    <HitchDisplayField label="Classification" value={data?.classification} />
                    <HitchDisplayField label="Received At" value={formatDate(data.receivedAt)} />
                    <HitchDisplayField label="Created At" value={formatDate(data.created)} />
                    <HitchDisplayField label="Updated At" value={formatDate(data.updated)} />
                </CardContent>
            </Card>

            <Divider sx={{ my: 2 }} />

            {/* Headers Section */}
            <Card>
                <CardContent>
                    <HitchTypography variant="h5" fontWeight="bold" color="white" gutterBottom>
                        Headers
                    </HitchTypography>
                    <VuiBox
                        sx={{
                            maxHeight: "200px",
                            overflowY: "auto",
                            backgroundColor: "#2e2e2e",
                            padding: "10px",
                            borderRadius: "8px",
                        }}
                    >
                        <pre style={{ color: "white", fontSize: "14px" }}>
                            {JSON.stringify(JSON.parse(data.headers), null, 2)}
                        </pre>
                    </VuiBox>
                </CardContent>
            </Card>

            <Divider sx={{ my: 2 }} />

            {/* Payload Section */}
            <Card>
                <CardContent>
                    <HitchTypography variant="h5" fontWeight="bold" color="white" gutterBottom>
                        Payload
                    </HitchTypography>
                    <VuiBox
                        sx={{
                            maxHeight: "300px",
                            overflowY: "auto",
                            backgroundColor: "#2e2e2e",
                            padding: "10px",
                            borderRadius: "8px",
                        }}
                    >
                        <pre style={{ color: "white", fontSize: "14px" }}>
                            {JSON.stringify(JSON.parse(data.payload), null, 2)}
                        </pre>
                    </VuiBox>
                </CardContent>
            </Card>
        </VuiBox>
    )
}

export default GitHubCheckRunEventViewer;

// FILE: /UI/hitch-ai-ui-2/src/hitch/bot/BotOperations.js

import React, { useEffect } from 'react';
import { Grid, IconButton, Tooltip, Divider, Card } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import VuiButton from "components/VuiButton"; // Importing VuiButton for consistency with the theme
import HitchTypography from "components/HitchTypography"; // Use custom typography
import VuiBox from "components/VuiBox"; // Use custom box component
import { useTheme } from '@mui/material/styles';

function BotOperations({ 
    values,
    handleChange,
    functions,
    setFunctions,
    removeFunction,
    editMode,
    expandedSection,
    setExpandedSection,
    setOpen
}) {
    const theme = useTheme(); // Access current theme 

    useEffect(() => {
        if(functions) {
            console.log('functions', functions);
        }
    }, [functions]);

    const handleAddFunction = () => {
        setOpen(true); // Open the operation selection modal
    };

    const handleRemoveFunction = (functionIndex) => {
        const updatedFunctions = functions.filter((_, index) => index !== functionIndex);
        setFunctions(updatedFunctions); // Remove the function based on index
    };

    return (
        <VuiBox>
            <Card>
                {/* Header for the Operations Section */}
                <VuiBox p={2}>
                    <HitchTypography variant="lg" fontWeight="bold" color="white">
                        Operations
                    </HitchTypography>
                </VuiBox>

                {/* Content inside the Card */}
                <Grid container spacing={3} p={2}>
                    <Grid item xs={12}>
                        {editMode ? (
                            <>
                                <VuiButton 
                                    variant="outlined"
                                    onClick={handleAddFunction}
                                    sx={{ mb: 2 }}
                                    startIcon={<FontAwesomeIcon icon={faPlus} />}
                                    color="primary" // Use primary color for consistency
                                >
                                    Add Operation
                                </VuiButton>
                                {Array.isArray(functions) && functions.length > 0 ? (
                                    functions.map((func, index) => (
                                        <VuiBox key={index} display="flex" alignItems="center" mb={1}>
                                            <Tooltip title={`Service: ${func.service}, Feature: ${func.feature}, Operation: ${func.operation}`}>
                                                <HitchTypography color="white" variant="body2">
                                                    {func?.function?.name}
                                                    {/* {`${func.service.toLowerCase()} / ${func.feature.toLowerCase()} / ${func.operation.toLowerCase()}`} */}
                                                </HitchTypography>
                                            </Tooltip>
                                            <IconButton 
                                                onClick={() => handleRemoveFunction(index)} 
                                                sx={{ marginLeft: 'auto', color: theme.palette.error.main }}
                                            >
                                                <FontAwesomeIcon icon={faTrash} />
                                            </IconButton>
                                        </VuiBox>
                                    ))
                                ) : (
                                    <HitchTypography color="white"> {/* Adjusted color and opacity */}
                                        No operations defined yet.
                                    </HitchTypography>
                                )}
                            </>
                        ) : (
                            <>
                                {Array.isArray(functions) && functions.length > 0 ? (
                                    functions.map((func, index) => (
                                        <VuiBox key={index} display="flex" alignItems="center" mb={1}>
                                            <Tooltip title={`Service: ${func.service}, Feature: ${func.feature}, Operation: ${func.operation}`}>
                                                <HitchTypography color="white" variant="body2">
                                                    {func?.function?.name}
                                                    {/* {`${func.service.toLowerCase()} / ${func.feature.toLowerCase()} / ${func.operation.toLowerCase()}`} */}
                                                </HitchTypography>
                                            </Tooltip>
                                        </VuiBox>
                                    ))
                                ) : (
                                    <HitchTypography sx={{ color: "white", opacity: 0.7 }}> {/* Changed color to white with reduced opacity */}
                                        No operations defined.
                                    </HitchTypography>
                                )}
                            </>
                        )}
                    </Grid>
                </Grid>
            </Card>
        </VuiBox>
    );
}

export default BotOperations;


// FILE: /UI\hitch-ai-ui-2\src\examples\Navbars\DefaultNavbar\DefaultNavbarLink.js

import PropTypes from "prop-types";
import Collapse from "@mui/material/Collapse";
import Icon from "@mui/material/Icon";
import VuiBox from "components/VuiBox";
import HitchTypography from "components/HitchTypography";

function DefaultNavbarLink({
  name,
  openHandler = false,
  closeHandler = false,
  children = false,
  collapseStatus = false,
  light = false,
  ...rest
}) {
  return (
    <>
      <VuiBox
        {...rest}
        mx={1}
        p={1}
        onMouseEnter={children ? undefined : openHandler}
        onMouseLeave={children ? undefined : closeHandler}
        display="flex"
        alignItems="baseline"
        color="white"
        sx={{ cursor: "pointer", userSelect: "none" }}
      >
        <HitchTypography
          variant="button"
          fontWeight="regular"
          textTransform="capitalize"
          color="inherit"
          sx={{ fontWeight: "100%" }}
        >
          {name}
        </HitchTypography>
        <HitchTypography variant="body2" color="white">
          <Icon sx={{ fontWeight: "bold", verticalAlign: "middle" }}>keyboard_arrow_down</Icon>
        </HitchTypography>
      </VuiBox>
      {children && (
        <Collapse in={Boolean(collapseStatus)} timeout="auto" unmountOnExit>
          {children}
        </Collapse>
      )}
    </>
  );
}

// Typechecking props for the DefaultNavbarLink
DefaultNavbarLink.propTypes = {
  name: PropTypes.string.isRequired,
  openHandler: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  closeHandler: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  children: PropTypes.node,
  collapseStatus: PropTypes.bool,
  light: PropTypes.bool,
};

export default DefaultNavbarLink;


// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/hitch/chat/MarkdownRenderer.js

import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw'; // to handle raw HTML inside markdown
import MarkdownComponents from './MarkdownComponents';

function MarkdownRenderer({ content }) {
  return (
    <ReactMarkdown 
      components={MarkdownComponents} 
      rehypePlugins={[rehypeRaw]} // Add this to handle inline HTML
    >
      {content}
    </ReactMarkdown>
  );
}

export default MarkdownRenderer;


// FILE: /UI/hitch-ai-ui-2/src/layouts/ecommerce/orders/order-list/components/IdCell/index.js

import PropTypes from "prop-types";
import Checkbox from "@mui/material/Checkbox";
import VuiBox from "components/VuiBox";
import HitchTypography from "components/HitchTypography";

function IdCell({ id, checked = false }) {
  return (
    <VuiBox display="flex" alignItems="center">
      <Checkbox
        defaultChecked={checked}
        sx={({ palette: { info } }) => ({
          "&.Mui-checked": {
            color: info.main,
          },
        })}
      />
      <VuiBox ml={1}>
        <HitchTypography variant="caption" fontWeight="medium" color="text">
          {id}
        </HitchTypography>
      </VuiBox>
    </VuiBox>
  );
}

// Typechecking props for the IdCell
IdCell.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool,
};

export default IdCell;

import { Card, Grid, Icon, Tooltip } from "@mui/material";
import { FaCog } from "react-icons/fa"; // Import the gear icon from react-icons

function HitchWidgetContainer({ children, id }) {
    const onConfigureClick = () => {
        // Your configuration logic here
        console.log(`Configure clicked for ${id}`);
    };

    return (
        <Card>
            <Grid container>
                <Grid item xs={12}>
                    {children}
                </Grid>
                <Grid item xs={12}>
                    <Tooltip title="Configure" placement="top">
                        <Icon 
                            sx={{ cursor: "pointer", color: "#000", float: "right" }} 
                            onClick={onConfigureClick}
                        >
                            <FaCog />
                        </Icon>
                    </Tooltip>
                </Grid>
            </Grid>
        </Card>
    );
}

export default HitchWidgetContainer;

// FILE: C:\Users\drewj\src\Hitch.AI\UI\hitch-ai-ui-2\src\layouts\authentication\components\BasicLayout\index.js

import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import VuiBox from "components/VuiBox";
import HitchTypography from "components/HitchTypography";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import PageLayout from "examples/LayoutContainers/PageLayout";
import Footer from "layouts/authentication/components/Footer";
import pageRoutes from "page.routes";

function BasicLayout({ title = "", description = "", image, children }) {
  return (
    <PageLayout>
      {/* <DefaultNavbar
        routes={pageRoutes}
        action={{
          type: "external",
          route: "https://creative-tim.com/product/vision-ui-dashboard-pro-react",
          label: "buy now",
        }}
        transparent
        light
      /> */}
      <VuiBox
        display={{ xs: "none", lg: "block" }}
        width={"calc(100% - 2rem)"}
        minHeight="60vh"
        borderRadius="lg"
        mx={2}
        my={2}
        pt={10}
        pb={14}
        sx={{
          backgroundImage: image && `url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      ></VuiBox>
      <VuiBox
        mt={{ xs: 10, lg: -54, xl: -56, xxl: -65 }}
        px={{ xs: 0, lg: 1 }}
        width={{ xs: "100%", lg: "calc(100% - 2rem)" }}
        mx="auto"
      >
        <Grid
          container
          spacing={3}
          mb={{ xs: "30px", lg: "40px", xl: "60px" }}
          justifyContent="center"
          sx={{ textAlign: "center" }}
        >
          <Grid item xs={10} lg={4}>
            <VuiBox mt={6} mb={1}>
              <HitchTypography variant="h1" color="white" fontWeight="bold" mb="20px">
                {title}
              </HitchTypography>
            </VuiBox>
            <VuiBox mb={2} px={{ md: "160px", lg: "0px", xl: "60px", xxl: "140px" }}>
              <HitchTypography variant="body2" color="white" fontWeight="regular">
                {description}
              </HitchTypography>
            </VuiBox>
          </Grid>
        </Grid>
        <VuiBox
          display="flex"
          justifyContent="center"
          mx={{ xs: "auto", lg: "auto" }}
          maxWidth={{ xs: "90%", md: "436px" }}
        >
          {children}
        </VuiBox>
      </VuiBox>
      {/* <Footer full /> */}
    </PageLayout>
  );
}

// Typechecking props for the BasicLayout
BasicLayout.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default BasicLayout;

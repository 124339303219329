
import { forwardRef } from "react";
import PropTypes from "prop-types";
import HitchTypography from "components/HitchTypography";
import VuiProgressRoot from "components/VuiProgress/VuiProgressRoot";

const VuiProgress = forwardRef(({ variant = "contained", color = "info", value = 0, label = false, ...rest }, ref) => (
  <>
    {label && (
      <HitchTypography variant="button" fontWeight="medium" color="text">
        {value}%
      </HitchTypography>
    )}
    <VuiProgressRoot
      {...rest}
      ref={ref}
      variant="determinate"
      value={value}
      ownerState={{ color, value, variant }}
    />
  </>
));

// Typechecking props for the VuiProgress
VuiProgress.propTypes = {
  variant: PropTypes.oneOf(["contained", "gradient"]),
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  value: PropTypes.number,
  label: PropTypes.bool,
};

export default VuiProgress;


// FILE: /UI/hitch-ai-ui-2/src/hitch/plugins/documentRepository/DeleteConfirmModal.jsx

import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

function DeleteConfirmModal({ title, content, labelNo, actionNo, labelYes, actionYes, open, onClose }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={actionNo} color="primary">
          {labelNo}
        </Button>
        <Button onClick={actionYes} color="primary" autoFocus>
          {labelYes}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteConfirmModal;


import React, { useState } from "react";
import { Card, IconButton } from "@mui/material";
import HitchDataTable from "components/HitchDataTable";
import HitchExpressionEditor from "components/HitchExpressionEditor";
import HitchModal from "components/HitchModal";
import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";
import HitchTypography from "components/HitchTypography";
import DeleteIcon from "@mui/icons-material/Delete";
import { v4 as uuidv4 } from "uuid";

function GitHubDependencyProperties({ properties, setProperties }) {
    const [selectedProperty, setSelectedProperty] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [isEditorOpen, setEditorOpen] = useState(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

    const openEditor = (property, index) => {
        setSelectedProperty(property);
        setSelectedIndex(index);
        setEditorOpen(true);
    };

    const closeEditor = () => {
        setEditorOpen(false);
        setSelectedProperty(null);
        setSelectedIndex(null);
    };

    const updateProperty = (name, expression) => {
        setProperties((prev) => {
            const updatedProperties = [...prev];
            updatedProperties[selectedIndex] = { ...selectedProperty, name, expression };
            return updatedProperties;
        });
        closeEditor();
    };

    const handleAddProperty = () => {
        setProperties((prev) => [
            ...prev,
            { id: uuidv4(), name: "New Property", expression: "" },
        ]);
    };

    const handleDeleteProperty = () => {
        setProperties((prev) => prev.filter((_, index) => index !== selectedIndex));
        setDeleteModalOpen(false);
    };

    const openDeleteModal = (index) => {
        setSelectedIndex(index);
        setDeleteModalOpen(true);
    };

    const closeDeleteModal = () => {
        setDeleteModalOpen(false);
        setSelectedIndex(null);
    };

    const columns = [
        { accessor: "name", Header: "Property Name", width: "30%" },
        { accessor: "expression", Header: "Expression", width: "60%" },
        {
            accessor: "delete",
            Header: "Actions",
            width: "10%",
            Cell: ({ row }) => {
                const index = properties.findIndex((p) => p.id === row.id);
                return (
                    <IconButton
                        color="error"
                        onClick={() => openDeleteModal(index)}
                        title="Delete Property"
                    >
                        <DeleteIcon />
                    </IconButton>
                );
            },
        },
    ];

    return (
        <VuiBox mb={3}>
            <Card>
                <VuiBox p={3}>
                    <HitchTypography variant="h6" color="white">
                        Properties
                    </HitchTypography>
                    <VuiButton onClick={handleAddProperty} variant="contained" color="info">
                        Add Property
                    </VuiButton>
                    <HitchDataTable
                        table={{
                            columns,
                            rows: properties,
                        }}
                        onRowClick={(row) => {
                            const index = properties.findIndex((p) => p.id === row.id);
                            if (index !== -1) {
                                openEditor(properties[index], index);
                            }
                        }}
                        canSearch
                    />
                </VuiBox>
            </Card>
            {isEditorOpen && (
                <HitchExpressionEditor
                    property={selectedProperty}
                    onUpdate={updateProperty}
                    onCancel={closeEditor}
                />
            )}
            {isDeleteModalOpen && (
                <HitchModal
                    open={isDeleteModalOpen}
                    onClose={closeDeleteModal}
                    title="Delete Property"
                    question="Are you sure you want to delete this property? This action cannot be undone."
                    yesLabel="Delete"
                    noLabel="Cancel"
                    onYes={handleDeleteProperty}
                    onNo={closeDeleteModal}
                />
            )}
        </VuiBox>
    );
}

export default GitHubDependencyProperties;


import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Menu from "@mui/material/Menu";
import VuiBox from "components/VuiBox";
import DefaultNavbarLink from "examples/Navbars/DefaultNavbar/DefaultNavbarLink";
import colors from "assets/theme/base/colors";
import PagesMenu from "examples/Navbars/DefaultNavbar/Menus/PagesMenu";
import AuthenticationMenu from "examples/Navbars/DefaultNavbar/Menus/AuthenticationMenu";
import ApplicationsMenu from "examples/Navbars/DefaultNavbar/Menus/ApplicationsMenu";
import EcommerceMenu from "examples/Navbars/DefaultNavbar/Menus/EcommerceMenu";
import { useHubConnections } from "context/HubConnectionsProvider";

function DefaultNavbarMobile({ routes, open, close }) {
  const { white } = colors;
  const { width } = open && open.getBoundingClientRect();
  const [openCollapse, setOpenCollapse] = useState(false);
  const [theRoutes, setTheRoutes] = useState([]);

  const handleSepOpenCollapse = (name) =>
    openCollapse === name ? setOpenCollapse(false) : setOpenCollapse(name);

  const renderRoutes = theRoutes.map(({ type, name, iconId, title, collapse, noCollapse, key, href, componentId }) => {
    const IconComponent = resolveIcon(iconId);
    let returnValue;

    if (type === 'collapse') {
      returnValue = href ? (
        <Link href={href} key={key} target='_blank' rel='noreferrer' sx={{ textDecoration: 'none' }}>
          <SidenavCollapse
            color={color}
            name={name}
            icon={IconComponent}
            active={key === collapseName}
            noCollapse={noCollapse}
          />
        </Link>
      ) : (
        <SidenavCollapse
          color={color}
          key={key}
          name={name}
          icon={IconComponent}
          active={key === collapseName}
          open={openCollapse === name}
          onClick={() => (openCollapse === name ? setOpenCollapse(false) : setOpenCollapse(name))}>
          {collapse ? renderCollapse(collapse) : null}
        </SidenavCollapse>
      );
    } else if (type === 'title') {
      returnValue = (
        <HitchTypography
          key={key}
          display='block'
          variant='caption'
          fontWeight='bold'
          color='white'
          textTransform='uppercase'
          pl={3}
          my={3}
          ml={1}>
          {title}
        </HitchTypography>
      );
    } else if (type === 'divider') {
      returnValue = <Divider key={key} />;
    }

    return returnValue;
  });

  const {
    account,
    dashboardConnection,
    forceReconnect,
    hitchDashCommand,
    hitchUser,
    isConnectionReady,
    users
  } = useHubConnections();

  useEffect(() => {
    if (dashboardConnection) {
      const subscribeToEvents = () => {
        dashboardConnection.on("cc367b2f-fc4a-4c2d-8bf0-3beba4af0e69", (data) => {
          if (data === "An Error Has Occurred") {
            console.error("An Error Has Occurred.");
            return;
          }
          setTheRoutes(data);
        });
        hitchDashCommand('account', 'menu', 'user-menu', {});
      };

      subscribeToEvents();
      return () => {
        dashboardConnection.off('cc367b2f-fc4a-4c2d-8bf0-3beba4af0e69');
      };
    }
  }, [dashboardConnection]);

  return (
    <Menu
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      anchorEl={open}
      open={Boolean(open)}
      onClose={close}
      MenuListProps={{ style: { width: `calc(${width}px - 4rem)` } }}
    >
      <VuiBox px={0.5}>
        <DefaultNavbarLink
          name="pages"
          collapseStatus={openCollapse === "pages"}
          onClick={() => handleSepOpenCollapse("pages")}
        >
          <VuiBox maxHeight="16rem" overflow="auto">
            <PagesMenu routes={routes} mobileMenu />
          </VuiBox>
        </DefaultNavbarLink>
        <DefaultNavbarLink
          name="authentication"
          collapseStatus={openCollapse === "authentication"}
          onClick={() => handleSepOpenCollapse("authentication")}
        >
          <VuiBox maxHeight="16rem" overflow="auto">
            <AuthenticationMenu routes={routes} mobileMenu />
          </VuiBox>
        </DefaultNavbarLink>
        <DefaultNavbarLink
          name="applications"
          collapseStatus={openCollapse === "applications"}
          onClick={() => handleSepOpenCollapse("applications")}
        >
          <VuiBox maxHeight="16rem" overflow="auto">
            <ApplicationsMenu routes={routes} mobileMenu />
          </VuiBox>
        </DefaultNavbarLink>
        <DefaultNavbarLink
          name="ecommerce"
          collapseStatus={openCollapse === "ecommerce"}
          onClick={() => handleSepOpenCollapse("ecommerce")}
        >
          <VuiBox maxHeight="16rem" overflow="auto">
            <EcommerceMenu routes={routes} mobileMenu />
          </VuiBox>
        </DefaultNavbarLink>

        {/* TODO: INCORPORATE HITCH MENU HERE */}

        <DefaultNavbarLink
          name="tacos"
          collapseStatus={openCollapse === "tacos"}
          onClick={() => handleSepOpenCollapse("tacos")}
        >
          <VuiBox maxHeight="16rem" overflow="auto">
            <EcommerceMenu routes={theRoutes} mobileMenu />
          </VuiBox>
        </DefaultNavbarLink>

      </VuiBox>
    </Menu>
  );
}

// Typechecking props for the DefaultNavbarMenu
DefaultNavbarMobile.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
  close: PropTypes.oneOfType([PropTypes.func, PropTypes.bool, PropTypes.object]).isRequired,
};

export default DefaultNavbarMobile;

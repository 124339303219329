
// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/third/H1259DF13_46D9_4237_A2F1_6C7AE95AC2E6/index.js

import Card from "@mui/material/Card";
import VuiBox from "components/VuiBox";
import HitchTypography from "components/HitchTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { useHubConnections } from "context/HubConnectionsProvider";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HitchDataTable from "components/HitchDataTable";
import HitchDisplayField from "components/HitchDisplayField";
import { Grid } from "@mui/material";
import VuiButton from "components/VuiButton";
import HitchFormField from "components/HitchFormField";
import { Form, Formik } from "formik";
import HitchSelect from "components/HitchSelect";
import HitchFeatureSelector from "components/HitchFeatureSelector";

function HD8BA1A8E_8733_47BB_A932_62F86E459F66() {

    const [config, setConfig] = useState({});
    const [editMode, setEditMode] = useState(false);
    const [ users, setUsers ] = useState([]);

    const {
        dashboardConnection,
        hitchDashCommand,
        hitchUser
    } = useHubConnections();

    function saveConfig(values) {
        console.log('save', values);

        const theConfiguration = {
            Configuration: {
                serviceId: config.serviceId,
                connectionId: config.connectionId,
                gptModelName: config.gptModelName,
                botHandle: values.botHandle,
                welcomeMessage: values.welcomeMessage,
                assistantId: values.assistantId,
                defaultUserForIntegration: values.defaultUserForIntegration,
            }
        };

        console.log('Saving the following Configuration Data', theConfiguration);
        hitchDashCommand('chat', 'config', 'update', theConfiguration, () => {
            setEditMode(false);
        });
    }

    useEffect(() => {
        if (dashboardConnection) {
            const subscribeToEvents = () => {
                dashboardConnection.on("1d1ebff6-5808-434d-964b-a1a2c2a9d752", setConfig);
                dashboardConnection.on("ec43cfb8-dd4e-49f4-9aff-ae49529ef3e5", setUsers);
                hitchDashCommand('chat', 'config', 'get', {});
                hitchDashCommand('account', 'role', 'user-list', {
                    theAccountId: hitchUser.accountId
                });
            };

            subscribeToEvents();

            return () => {
                dashboardConnection.off("1d1ebff6-5808-434d-964b-a1a2c2a9d752", setConfig);
                dashboardConnection.off("ec43cfb8-dd4e-49f4-9aff-ae49529ef3e5", setUsers);
            };
        }
    }, [dashboardConnection]);

    useEffect(() => {
        if (config) {
            console.log('the Config', config);
        }
    }, [config]);

    useEffect(() => {
        if (users) {
            console.log('the Users', users);
        }
    }, [users]);

    const sortedUsers = [...users].sort((a, b) => a.name.localeCompare(b.name));

    const getUserName = (userId) => {
        const retUser = users.find((usr) => usr.id === userId);
        return retUser ? retUser.name : "N/A";
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <VuiBox pt={6} pb={3}>
                <VuiBox mb={3}>
                    <Card>
                        <VuiBox p={3} pl={0} lineHeight={1}>
                            <HitchTypography variant="h5" fontWeight="medium" color="white">
                                Chat Service
                            </HitchTypography>
                            <HitchTypography variant="button" fontWeight="regular" color="text">
                                Configuration
                            </HitchTypography>
                        </VuiBox>
                        <VuiBox p={3}>
                            {!editMode ? (
                                <VuiBox mb={2}>
                                    <Grid
                                        item
                                        xs={12}
                                        md={5}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                        }}
                                    >
                                        <VuiButton
                                            variant="outlined"
                                            color="white"
                                            sx={({ palette: { white } }) => ({
                                                border: `1px solid ${white.main}`,
                                                color: white.main,
                                            })}
                                            onClick={() => setEditMode(true)}
                                        >
                                            Edit
                                        </VuiButton>
                                    </Grid>

                                    <HitchDisplayField label="Bot Handle" value={config?.botHandle} />
                                    <HitchDisplayField label="Welcome Message" value={config?.welcomeMessage} />
                                    <HitchDisplayField label="Open Ai Assistant Id" value={config?.assistantId} />
                                    <HitchDisplayField label="Default User For Integration" value={getUserName(config?.defaultUserForIntegration)} />
                                </VuiBox>
                            ) : (
                                <>
                                    <Formik
                                        initialValues={{
                                            botHandle: config?.botHandle || "",
                                            welcomeMessage: config?.welcomeMessage || "",
                                            assistantId: config?.assistantId || "",
                                            defaultUserForIntegration: config?.defaultUserForIntegration || "",
                                        }}
                                        onSubmit={(values) => saveConfig(values)}
                                    >
                                        {({ values, setFieldValue, handleSubmit }) => (
                                            <Form onSubmit={handleSubmit}>
                                                <VuiBox>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        md={5}
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent: "flex-end",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <VuiButton
                                                            type="submit"
                                                            variant="contained"
                                                            color="white"
                                                            sx={({ palette: { white } }) => ({
                                                                border: `1px solid ${white.main}`,
                                                            })}
                                                        >
                                                            save
                                                        </VuiButton>
                                                        <VuiButton
                                                            variant="outlined"
                                                            color="white"
                                                            sx={({ palette: { white } }) => ({
                                                                border: `1px solid ${white.main}`,
                                                                color: white.main,
                                                            })}
                                                            onClick={() => setEditMode(false)}
                                                        >
                                                            cancel
                                                        </VuiButton>
                                                    </Grid>

                                                    <HitchFormField
                                                        name="botHandle"
                                                        label="Organization Bot Handle"
                                                        fullWidth
                                                        editMode={editMode}
                                                    />

                                                    <HitchFormField
                                                        name="welcomeMessage"
                                                        label="Welcome Message"
                                                        fullWidth
                                                        editMode={editMode}
                                                    />

                                                    <HitchFormField
                                                        name="assistantId"
                                                        label="OpenAi Assistant Id"
                                                        fullWidth
                                                        editMode={editMode}
                                                    />

                                                    <HitchSelect
                                                        name="defaultUserForIntegration"
                                                        label="Default User Id For Integration"
                                                        options={sortedUsers.map((option) => ({
                                                            label: option.name,
                                                            value: option.id,
                                                        }))}
                                                        placeholder="Select user"
                                                        isClearable
                                                        editMode={editMode}
                                                    />

                                                    <HitchFormField
                                                        name="defaultUserForIntegration"
                                                        label="Default User Id For Integration"
                                                        fullWidth
                                                        editMode={editMode}
                                                    />


                                                </VuiBox>
                                            </Form>
                                        )}
                                    </Formik>


                                </>
                            )}

                        </VuiBox>
                    </Card>
                    <HitchFeatureSelector
                            serviceId={"d8ba1a8e-8733-47bb-a932-62f86e459f66"} />
                </VuiBox>
            </VuiBox>
        </DashboardLayout>
    );
}

export default HD8BA1A8E_8733_47BB_A932_62F86E459F66;
import VuiButton from 'components/VuiButton';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'hitch/dashboard/DashboardNavbar';
import React, { useState, useEffect } from 'react';
import GridLayout from "react-grid-layout";
import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";
import "./index.css";
import { useHubConnections } from 'context/HubConnectionsProvider';
import HitchWidget from 'components/HitchWidget';
import { Card, Grid } from '@mui/material';
import HitchTypography from 'components/HitchTypography';

const HitchMainDashboard = () => {
    const scaffoldData = [
        {
            x: 0,
            y: 0,
            width: 6,
            height: 12,
            type: 'table',
            title: 'Table',
            id: '00000000-6065-45c5-9ede-8cb2c7987eda'
        },
        {
            x: 7,
            y: 0,
            width: 6,
            height: 12,
            type: 'bubble-chart',
            title: 'Bubble Chart',
            id: '10000000-6065-45c5-9ede-8cb2c7987eda'
        },
        {
            x: 4,
            y: 1,
            width: 9,
            height: 12,
            type: 'doughnut-chart',
            title: 'Doughnut Chart',
            id: '20000000-6065-45c5-9ede-8cb2c7987eda'
        },
        {
            x: 4,
            y: 1,
            width: 9,
            height: 12,
            type: 'line-chart',
            title: 'Line Chart',
            id: '30000000-6065-45c5-9ede-8cb2c7987eda'
        },
        {
            x: 4,
            y: 1,
            width: 9,
            height: 12,
            type: 'gradient-line-chart',
            title: 'Gradient Line Chart',
            id: '40000000-6065-45c5-9ede-8cb2c7987eda'
        },
        {
            x: 4,
            y: 1,
            width: 9,
            height: 12,
            type: 'horizontal-bar-chart',
            title: 'Horizontal Bar Chart',
            id: '50000000-6065-45c5-9ede-8cb2c7987eda'
        },
        {
            x: 4,
            y: 1,
            width: 9,
            height: 12,
            type: 'mixed-chart',
            title: 'Mixed Chart',
            id: '60000000-6065-45c5-9ede-8cb2c7987eda'
        },
        {
            x: 4,
            y: 1,
            width: 9,
            height: 12,
            type: 'pie-chart',
            title: 'Pie Chart',
            id: '70000000-6065-45c5-9ede-8cb2c7987eda'
        },
        {
            x: 4,
            y: 1,
            width: 9,
            height: 12,
            type: 'polar-chart',
            title: 'Polar Chart',
            id: '80000000-6065-45c5-9ede-8cb2c7987eda'
        },
        {
            x: 4,
            y: 1,
            width: 9,
            height: 12,
            type: 'vertical-bar-chart',
            title: 'Vertical Bar Chart',
            id: '90000000-6065-45c5-9ede-8cb2c7987eda'
        },
        {
            x: 4,
            y: 1,
            width: 9,
            height: 12,
            type: 'radar-chart',
            title: 'Radar Chart',
            id: 'a0000000-6065-45c5-9ede-8cb2c7987eda'
        },
        {
            x: 4,
            y: 1,
            width: 9,
            height: 12,
            type: 'unsupported',
            title: 'Unsupported',
            id: 'b0000000-6065-45c5-9ede-8cb2c7987eda'
        },
    ];

    const onLayoutChange = (newLayout) => {
        const updatedWidgets = newLayout.map((layoutItem) => {
            const widget = widgets.find(w => w.id === layoutItem.i);
            return {
                ...widget,
                x: layoutItem.x,
                y: layoutItem.y,
                width: layoutItem.w,
                height: layoutItem.h,
            };
        });

        setWidgets(updatedWidgets); // Update local state with new layout

        // Call hitchDashCommand for each widget to save layout
        updatedWidgets.forEach((widget) => {
            const theObj = {
                id: widget.id,
                x: widget.x,
                y: widget.y,
                width: widget.width,
                height: widget.height,
            };
            console.log('saving', theObj);
            //hitchDashCommand('account', 'widget', 'update', theObj);
        });
    };

    const [isEditMode, setIsEditMode] = useState(false);
    const [widgets, setWidgets] = useState(scaffoldData);
    const [widgetTypes, setWidgetTypes] = useState([]);

    const {
        dashboardConnection,
        hitchDashCommand,
        isConnectionReady,
    } = useHubConnections();

    useEffect(() => {
        if (widgets) {
            console.log('widgets', widgets);
        }
    }, [widgets]);

    useEffect(() => {
        if (widgetTypes) {
            console.log('widgetTypes', widgetTypes);
        }
    }, [widgetTypes]);

    useEffect(() => {
        if (dashboardConnection && isConnectionReady) {
            const subscribeToEvents = () => {
                dashboardConnection.on("2731635d-6065-45c5-9ede-8cb2c7987eda", setWidgets);
                dashboardConnection.on("2cb0c53f-8f34-49c1-b8e8-c84f0edb8e02", setWidgetTypes);
                hitchDashCommand('account', 'widget', 'list', {});
                hitchDashCommand('account', 'widget', 'list-types', {});
            };

            subscribeToEvents();
            return () => {
                dashboardConnection.off('2731635d-6065-45c5-9ede-8cb2c7987eda', setWidgets);
                dashboardConnection.off("2cb0c53f-8f34-49c1-b8e8-c84f0edb8e02", setWidgetTypes);
            };
        }
    }, [dashboardConnection, isConnectionReady]);



    const toggleEditMode = () => {
        setIsEditMode(prevMode => !prevMode);
    };

    const layout = widgets.map((widget) => ({
        i: widget.id,
        x: widget.x,
        y: widget.y,
        w: widget.width,
        h: widget.height,
    }));

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <div style={{ padding: '20px' }}>
                <Grid container>
                    <Grid item xs={12} md={9}>
                        <HitchTypography variant="h2">
                            {isEditMode ? 'Edit Mode' : 'Dashboard'}
                        </HitchTypography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <VuiButton
                            color="white"
                            variant="outlined"
                            onClick={toggleEditMode}
                        >
                            {isEditMode ? 'Exit Edit Mode' : 'Edit Dashboard'}
                        </VuiButton>
                        {isEditMode && (
                            <VuiButton
                                color="white"
                                variant="outlined"
                            >
                                Add New Widget
                            </VuiButton>
                        )}
                    </Grid>
                </Grid>
                <div>
                    <GridLayout
                        className="layout"
                        layout={layout}
                        cols={12}
                        rowHeight={30}
                        width={1200}
                        isDraggable={isEditMode}
                        isResizable={isEditMode}
                        onLayoutChange={onLayoutChange}
                    >
                        {/* {widgets?.map((widget) => (
                            <Card component="div" key={widget.id} data-grid={{ x: widget.x, y: widget.y, w: widget.width, h: widget.height }}>
                                <HitchWidget
                                    type={widget.type}
                                    title={widget.title}
                                    data={widget} // Pass any data needed for the widget
                                />
                            </Card>
                        ))} */}
                    </GridLayout>

                    {!isEditMode && <p>Your dashboard widgets will be displayed here.</p>}
                </div>
            </div>
        </DashboardLayout>
    );
};

export default HitchMainDashboard;
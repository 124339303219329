import { Card, Grid, Modal } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";
import HitchTypography from "components/HitchTypography";
import { useEffect, useState } from "react";
import HitchSelectSimple from "components/HitchSelectSimple";
import { Form, Formik } from "formik";
import HitchFormField from "components/HitchFormField";
import { useHubConnections } from "context/HubConnectionsProvider";
import { v4 as uuidv4 } from 'uuid';

function EvidenceControl({ evidence, editMode, parentId }) {
    const [openModal, setOpenModal] = useState(false);
    const handleOpen = () => setOpenModal(true);
    const handleClose = () => setOpenModal(false);
    const [vectorStores, setVectorStores] = useState([]);
    const { dashboardConnection, hitchDashCommand, hitchUser } = useHubConnections();
    const [selectedEvidence, setSelectedEvidence] = useState(null);
    const [theEvidence, setTheEvidence] = useState({});

    function addEvidence() {
        const uid = uuidv4();
        console.log('addEvidence', uid);
        setSelectedEvidence(uid);
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: '#111',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    useEffect(() => {
        console.log('selectedEvidence', selectedEvidence);
        if (selectedEvidence) {
            console.log('open Modal');
            setOpenModal(true);
            hitchDashCommand('sos', 'task-evidence', 'get', { evidenceId: selectedEvidence });
        } else {
            console.log('close Modal');
            setOpenModal(false);
        }
    }, [selectedEvidence]);

    useEffect(() => {
        if (dashboardConnection) {
            dashboardConnection.on("26b4bb89-bf1c-49b7-8d30-bd60bdd22c5d", incomingEvidence);
            dashboardConnection.on("ab183cbd-c1a8-4bf3-a264-c8fa7d58660c", vectorStoresSir);

            hitchDashCommand('document', 'repo', 'list', {});

            return () => {
                dashboardConnection.off("26b4bb89-bf1c-49b7-8d30-bd60bdd22c5d", incomingEvidence);
                dashboardConnection.off("ab183cbd-c1a8-4bf3-a264-c8fa7d58660c", vectorStoresSir);
            }
        }
    }, [dashboardConnection]);

    function vectorStoresSir(data) {
        setVectorStores(data);
    }

    function incomingEvidence(data) {
        if (data === "") {
            console.log('not found');
            setTheEvidence({
                id: selectedEvidence,
                accountId: hitchUser.accountId,
                name: '',
                repositoryId: '',
                documentId: '',
                comments: '',
                created: new Date().toISOString(),
                createdBy: hitchUser.userId,
                updated: new Date().toISOString(),
                updatedBy: hitchUser.userId
            });
        }
        console.log('26b4bb89-bf1c-49b7-8d30-bd60bdd22c5d', data);
    }

    useEffect(() => {
        if (theEvidence) {
            console.log('theEvidence', theEvidence);
        }
    }, [theEvidence]);

    const vectorStoresArray = Array.isArray(vectorStores) ? vectorStores : [];

    function saveEvidence(values) {

        console.log('values', values);

        const newEvidence = {
            id: theEvidence.id,
            accountId: theEvidence.accountId,
            name: values.name,
            repositoryId: values.repositoryId,
            documentId: values.documentId,
            comments: values.comments,
            created: theEvidence.created,
            createdBy: theEvidence.createdBy,
            updated: theEvidence.updated,
            updatedBy: theEvidence.updatedBy,
        }

        console.log('newEvidence', newEvidence);
    }

    return (
        <Card sx={{ p: 3, mt: 3 }}>
            <Grid container>
                <Grid item xs={12} md={9}>
                    <HitchTypography variant="h6">Evidence</HitchTypography>
                </Grid>
                <Grid item xs={12} md={3} alignContent="end" alignItems="end">
                    <VuiButton variant="outlined" color="white" onClick={addEvidence}>
                        Add Evidence
                    </VuiButton>
                </Grid>
            </Grid>

            {evidence?.length > 0 ? (
                evidence.map((child) => (
                    <Card key={child.id} sx={{ mb: 2, p: 2 }}>
                        <HitchTypography variant="subtitle1" color="white">{child.name}</HitchTypography>
                        <HitchTypography color="white">
                            <strong>Evidence:</strong> {child.requirement}
                        </HitchTypography>
                    </Card>
                ))
            ) : (
                <HitchTypography>No Evidence Available</HitchTypography>
            )}

            <Modal
                open={openModal}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <VuiBox sx={style}>
                    <Formik
                        initialValues={{
                            name: theEvidence?.name || "",
                            repositoryId: theEvidence?.repositoryId || "",
                            documentId: theEvidence?.documentId || "",
                            comments: theEvidence?.comments || "",
                        }}
                        onSubmit={(values) => saveEvidence(values)} // Ensure saveEvidence is defined
                    >
                        {({ handleSubmit, setFieldValue, values }) => (
                            <Form onSubmit={handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={12}>
                                        <HitchFormField
                                            name="name"
                                            label="Name"
                                            fullWidth
                                            editMode={true}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                        <HitchFormField
                                            name="comments"
                                            label="Comments"
                                            fullWidth
                                            editMode={true}
                                            multiline
                                            rows={3}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                        <HitchSelectSimple
                                            value={values?.repositoryId}
                                            label="Select a Hitch Document Storage Repository"
                                            options={vectorStoresArray.map((option) => ({
                                                label: option.name,
                                                value: option.id,
                                            }))}
                                            placeholder="Select Storage"
                                            onChange={(e) => setFieldValue('repositoryId', e)}
                                            isClearable
                                            editMode={true}
                                        />
                                    </Grid>

                                    


                                    <Grid item xs={12} md={12}>
                                        <VuiButton type="submit" variant="outlined" color="success" sx={{ mt: 2, m: 1 }}>
                                            Save
                                        </VuiButton>
                                        <VuiButton variant="outlined" color="error" onClick={handleClose} sx={{ mt: 2, m: 1 }}>
                                            Close
                                        </VuiButton>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>

                </VuiBox>
            </Modal>
        </Card>
    );
}

export default EvidenceControl;
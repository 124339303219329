// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/third/sos/tasks/index.js

import Card from "@mui/material/Card";
import VuiBox from "components/VuiBox";
import HitchTypography from "components/HitchTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import HitchDataTableExternalPagination from "components/HitchDataTableExternalPagination";
import { useEffect, useState } from "react";
import { Button, TextField, MenuItem } from "@mui/material";
import { useHubConnections } from "context/HubConnectionsProvider";
import VuiButton from "components/VuiButton";
import { useNavigate } from "react-router-dom";

function SosTasks() {
    const cols = [
        { accessor: 'name', Header: 'Task Name', width: '30%' },
        { accessor: 'id', Header: 'Task ID', width: '10%' },
        { accessor: 'score', Header: 'Current Score', width: '15%' },
        { accessor: 'maxScore', Header: 'Max Score', width: '15%' },
        { accessor: 'requirement', Header: 'Requirement', width: '20%' },
        {
            accessor: 'actions',
            Header: 'Actions',
            width: '10%',
            Cell: ({ row }) => (
                <div style={{ display: 'flex', gap: '8px' }}>
                    <VuiButton variant="outlined" color="info" size="small" onClick={() => handleClick(row)}>View</VuiButton>
                    <VuiButton 
                        variant="outlined" 
                        color="error" 
                        size="small" 
                        disabled={row.original.score === 0}
                    >
                        Archive
                    </VuiButton>
                </div>
            )
        }
    ];

    function handleClick(row) {
        navigate(`./${row.values.id}`);
    }

    const navigate = useNavigate();
    const [list, setList] = useState([]);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalResults, setTotalResults] = useState(0);

    const [searchValue, setSearchValue] = useState('');
    const [statusFilter, setStatusFilter] = useState('');

    const [dataTableData, setDataTableData] = useState({
        columns: cols,
        rows: []
    });

    const { dashboardConnection, hitchDashCommand, isConnectionReady } = useHubConnections();

    useEffect(() => {
        if (dashboardConnection && isConnectionReady) {
            dashboardConnection.on("02c97151-7d0f-487e-8c7e-5f6c206001e0", incomingTasks);
// 02c97151-7d0f-487e-8c7e-5f6c206001e0
            console.log('getting sos/task/list');
            hitchDashCommand('sos', 'task', 'list', {
                page: 1,
                pageSize: 20
            });

            return () => {
                dashboardConnection.off("02c97151-7d0f-487e-8c7e-5f6c206001e0", incomingTasks);
            }
        }
    }, [dashboardConnection, isConnectionReady]);

    function incomingTasks(data) {
        console.log('02c97151-7d0f-487e-8c7e-5f6c206001e0', data);
        setList(data);
    }

    // useEffect(() => {
    //     const mockTasks = [
    //         { name: 'ISO 27001', id: '02c97151-7d0f-487e-8c7e-5f6c206001e0', score: 0.8, maxScore: 1, requirement: 'Information Security Management' }
    //     ];

    //     let filtered = [...mockTasks];

    //     if (searchValue) {
    //         filtered = filtered.filter(item =>
    //             item.name.toLowerCase().includes(searchValue.toLowerCase()) ||
    //             item.id.toLowerCase().includes(searchValue.toLowerCase())
    //         );
    //     }

    //     setTotalResults(filtered.length);
    //     const start = (page - 1) * perPage;
    //     const end = start + perPage;
    //     filtered = filtered.slice(start, end);
    //     setList(filtered);
    // }, [page, perPage, searchValue]);

    useEffect(() => {
        setDataTableData(prev => ({
            ...prev,
            rows: list
        }));
    }, [list]);

    function onPageChange(newPage, newPerPage) {
        setPage(newPage);
        setPerPage(newPerPage);
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <VuiBox pt={6} pb={3}>
                <VuiBox mb={3}>
                    <Card>
                        <VuiBox p={3} pl={0} lineHeight={1}>
                            <HitchTypography variant="h5" fontWeight="medium" color="white">
                                Tasks Management
                            </HitchTypography>
                            <HitchTypography variant="button" fontWeight="regular" color="white">
                                Manage Your Tasks
                                <VuiButton variant="outlined" color="white" onClick={(e) => navigate('./create-task')}>Create Task</VuiButton>
                            </HitchTypography>
                        </VuiBox>
                        <VuiBox p={3} pl={0}>
                            <div style={{ display: 'flex', gap: '16px', marginBottom: '16px', flexWrap: 'wrap' }}>
                                <TextField 
                                    variant="outlined"
                                    size="small"
                                    label="Search"
                                    value={searchValue}
                                    onChange={(e) => setSearchValue(e.target.value)}
                                />
                                <TextField
                                    select
                                    variant="outlined"
                                    size="small"
                                    label="Status"
                                    value={statusFilter}
                                    onChange={(e) => setStatusFilter(e.target.value)}
                                    style={{ width: '150px' }}
                                >
                                    <MenuItem value="">All</MenuItem>
                                    <MenuItem value="Open">Open</MenuItem>
                                    <MenuItem value="Completed">Completed</MenuItem>
                                </TextField>
                            </div>
                            <HitchDataTableExternalPagination
                                table={dataTableData}
                                canSearch={false}
                                totalResults={totalResults}
                                onPageChange={onPageChange}
                                paginated={true}
                                page={page}
                            />
                        </VuiBox>
                    </Card>
                </VuiBox>
            </VuiBox>
        </DashboardLayout>
    );
}

export default SosTasks;
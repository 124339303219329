import VuiBox from "components/VuiBox";
import HitchTypography from "components/HitchTypography";

function OrderSummary() {
  return (
    <>
      <VuiBox mb={2}>
        <HitchTypography variant="h6" color="white" fontWeight="medium">
          Order Summary
        </HitchTypography>
      </VuiBox>
      <VuiBox display="flex" justifyContent="space-between" mb="20px">
        <HitchTypography variant="button" fontWeight="regular" color="text">
          Product Price:
        </HitchTypography>
        <VuiBox>
          <HitchTypography variant="body2" color="white" fontWeight="medium">
            $90
          </HitchTypography>
        </VuiBox>
      </VuiBox>
      <VuiBox display="flex" justifyContent="space-between" mb="20px">
        <HitchTypography variant="button" fontWeight="regular" color="text">
          Delivery:
        </HitchTypography>
        <VuiBox>
          <HitchTypography variant="body2" color="white" fontWeight="medium">
            $14
          </HitchTypography>
        </VuiBox>
      </VuiBox>
      <VuiBox display="flex" justifyContent="space-between" mb="20px">
        <HitchTypography variant="button" fontWeight="regular" color="text">
          Taxes:
        </HitchTypography>
        <VuiBox>
          <HitchTypography variant="body2" color="white" fontWeight="medium">
            $1.95
          </HitchTypography>
        </VuiBox>
      </VuiBox>
      <VuiBox display="flex" justifyContent="space-between" mt={3}>
        <HitchTypography variant="body1" fontWeight="light" color="text">
          Total:
        </HitchTypography>
        <VuiBox>
          <HitchTypography variant="body1" color="white" fontWeight="medium">
            $1.95
          </HitchTypography>
        </VuiBox>
      </VuiBox>
    </>
  );
}

export default OrderSummary;


// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/hitch/chat/ChatMessage.js

import React, { useEffect } from 'react';
import VuiBox from 'components/VuiBox';
import VuiAvatar from 'components/VuiAvatar';
import MarkdownRenderer from './MarkdownRenderer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import MessageUrl from './MessageUrl';
import HitchAvatar from 'components/HitchAvatar';

function ChatMessage({ message, sender, text, avatar }) {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(text).then(() => {

    });
  };

  return (
    <VuiBox
      display="flex"
      flexDirection={sender === 'User' ? 'row-reverse' : 'row'}
      mb={2}
      alignItems="flex-start"  // Align avatar at the top of the message
    >
      <HitchAvatar hitchId={sender} />
      {/* <VuiAvatar src={`${window._env_.HITCH_API}/api/accimage/${sender}.png`} alt={sender} size="md" /> */}
      <VuiBox
        ml={sender === 'User' ? 2 : 0}
        mr={sender === 'User' ? 0 : 2}
        p={2}
        borderRadius="md"
        bgcolor={sender === 'User' ? 'primary.main' : 'secondary.main'}
        maxWidth="60%"
      >
        {(message.contentType === 'string' || message.contentType === 'text') && (
          <MarkdownRenderer content={text} />
        )}
        {message.contentType === 'link' && (
          <MessageUrl message={message} />
        )}
        <FontAwesomeIcon
          icon={faCopy}
          onClick={copyToClipboard}
          style={{ cursor: 'pointer', marginLeft: '8px' }}
          title="Copy to clipboard"
        />

      </VuiBox>
    </VuiBox>
  );
}

export default ChatMessage;

// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/third/HB2AC74EA_B70A_4A7D_829E_163C2EE7AC87/index.js

import Card from "@mui/material/Card";
import VuiBox from "components/VuiBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import HitchTitle from "components/HitchTitle";
import HitchFeatureSelector from "components/HitchFeatureSelector";
import { useEffect, useState } from "react";
import { useHubConnections } from "context/HubConnectionsProvider";
import { Divider, Grid } from "@mui/material";
import HitchFormField from "components/HitchFormField";
import VuiButton from "components/VuiButton";
import HitchDisplayField from "components/HitchDisplayField";
import { Form, Formik } from "formik";
import TokenManager from "hitch/token-manager";

function HB2AC74EA_B70A_4A7D_829E_163C2EE7AC87() {
    const [config, setConfig] = useState({});
    const [editMode, setEditMode] = useState(false);

    const {
        dashboardConnection,
        hitchDashCommand,
        hitchUser
    } = useHubConnections();

    function saveConfig(values) {
        console.log('save', values);

        const theConfiguration = {
            Configuration: {
                serviceId: config.serviceId,
                connectionId: config.connectionId,
                gptModelName: config.gptModelName,
                clientId: values.clientId,
                clientSecret: values.clientSecret,
                redirectUri: values.redirectUri,
                tokenUrl: values.tokenUrl,
                baseUrl: values.baseUrl,
                providerSpecificId: values.providerSpecificId,
                authUrl: values.authUrl
            }
        };

        hitchDashCommand('gcp', 'config', 'update', theConfiguration, () => {
            setEditMode(false);
        });
    }

    useEffect(() => {
        if (dashboardConnection) {
            const subscribeToEvents = () => {
                dashboardConnection.on("517de646-9b96-432e-91c7-f5a79d8d13f7", setConfig);
                hitchDashCommand('gcp', 'config', 'get', {});
            };

            subscribeToEvents();

            return () => {
                dashboardConnection.off("517de646-9b96-432e-91c7-f5a79d8d13f7", setConfig);
            };
        }
    }, [dashboardConnection]);

    useEffect(() => {
        if (config) {
            console.log('config', config);
        }
    }, [config]);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <VuiBox pt={6} pb={3}>
                <VuiBox mb={3}>
                    <Card>
                        <HitchTitle
                            title="GCP Service"
                            subTitle="Configuration" />
                        <VuiBox p={3}>
                            {!editMode ? (
                                <VuiBox mb={2}>
                                    <Grid
                                        item
                                        xs={12}
                                        md={5}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                        }}
                                    >
                                        <VuiButton
                                            variant="outlined"
                                            color="white"
                                            sx={({ palette: { white } }) => ({
                                                border: `1px solid ${white.main}`,
                                                color: white.main,
                                            })}
                                            onClick={() => setEditMode(true)}
                                        >
                                            Edit
                                        </VuiButton>
                                    </Grid>
                                    <HitchDisplayField label="Google Client Id" value={config?.clientId} />
                                    <HitchDisplayField label="Google Client Secret" value={"***"} />
                                    <HitchDisplayField label="Google Redirect Url" value={config?.redirectUri} />
                                    <HitchDisplayField label="Google Token Url" value={config?.tokenUrl} />
                                    <HitchDisplayField label="Google Base Url" value={config?.baseUrl} />
                                    <HitchDisplayField label="Google Provider Specific Id" value={config?.providerSpecificId} />
                                    <HitchDisplayField label="Google Auth Url" value={config?.authUrl} />
                                </VuiBox>
                            ) : (
                                <>
                                    <Formik
                                        initialValues={{
                                            clientId: config?.clientId || "",
                                            clientSecret: config?.clientSecret || "",
                                            redirectUri: config?.redirectUri || "",
                                            tokenUrl: config?.tokenUrl || "",
                                            baseUrl: config?.baseUrl || "",
                                            providerSpecificId: config?.providerSpecificId || "",
                                            authUrl: config?.authUrl || "",
                                        }}
                                        onSubmit={(values) => saveConfig(values)}
                                    >
                                        {({ values, setFieldValue, handleSubmit }) => (
                                            <Form onSubmit={handleSubmit}>
                                                <VuiBox>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        md={5}
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent: "flex-end",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <VuiButton
                                                            type="submit"
                                                            variant="contained"
                                                            color="white"
                                                            sx={({ palette: { white } }) => ({
                                                                border: `1px solid ${white.main}`,
                                                            })}
                                                        >
                                                            save
                                                        </VuiButton>
                                                        <VuiButton
                                                            variant="outlined"
                                                            color="white"
                                                            sx={({ palette: { white } }) => ({
                                                                border: `1px solid ${white.main}`,
                                                                color: white.main,
                                                            })}
                                                            onClick={() => setEditMode(false)}
                                                        >
                                                            cancel
                                                        </VuiButton>
                                                    </Grid>

                                                    <HitchFormField
                                                        name="clientId"
                                                        label="Google Client Id"
                                                        fullWidth
                                                        editMode={editMode}
                                                    />

                                                    <HitchFormField
                                                        name="clientSecret"
                                                        label="Google Client Secret"
                                                        fullWidth
                                                        editMode={editMode}
                                                    />

                                                    <HitchFormField
                                                        name="redirectUri"
                                                        label="Google Redirect Url"
                                                        fullWidth
                                                        editMode={editMode}
                                                    />

                                                    <HitchFormField
                                                        name="tokenUrl"
                                                        label="Google Token Url"
                                                        fullWidth
                                                        editMode={editMode}
                                                    />

                                                    <HitchFormField
                                                        name="baseUrl"
                                                        label="Google Base Url"
                                                        fullWidth
                                                        editMode={editMode}
                                                    />

                                                    <HitchFormField
                                                        name="providerSpecificId"
                                                        label="Google Provider Specific Id"
                                                        fullWidth
                                                        editMode={editMode}
                                                    />

                                                    <HitchFormField
                                                        name="authUrl"
                                                        label="Google Auth Url"
                                                        fullWidth
                                                        editMode={editMode}
                                                    />

                                                </VuiBox>
                                            </Form>
                                        )}
                                    </Formik>


                                </>
                            )}

                        </VuiBox>
                        <Divider />
                        <TokenManager
                            serviceName="gcp"
                            featureName="auth"
                            getConfigHitchId="517de646-9b96-432e-91c7-f5a79d8d13f7"
                            isLoggedInHitchId="9cb77279-a78c-444a-bb81-c1a7fa37615b"
                            startAuthFlowHitchId="c157b943-aa80-4bea-bbde-70709056178e"
                        />
                        <Divider />
                        <HitchFeatureSelector
                            serviceId={"b2ac74ea-b70a-4a7d-829e-163c2ee7ac87"} />
                    </Card>
                </VuiBox>
            </VuiBox>
        </DashboardLayout >
    );
}

export default HB2AC74EA_B70A_4A7D_829E_163C2EE7AC87;

// FILE: /UI/hitch-ai-ui-2/src/components/HitchTextArea/HitchTextAreaRoot.js

import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";

export default styled(InputBase)(({ theme, ownerState }) => {
  const { palette, boxShadows, functions, typography, borders } = theme;
  const { size, error, success, iconDirection, direction, disabled } = ownerState;
  const { inputColors, grey } = palette;
  const { inputBoxShadow } = boxShadows;
  const { pxToRem, boxShadow } = functions;
  const { size: fontSize } = typography;
  const { borderRadius } = borders;

  let borderColorValue = "";

  if (error) {
    borderColorValue = inputColors.error;
  } else if (success) {
    borderColorValue = inputColors.success;
  }

  return {
    backgroundColor: disabled ? `${grey[200]} !important` : inputColors.backgroundColor,
    pointerEvents: disabled ? "none" : "auto",
    color: grey[900], // Set the text color here
    borderRadius: borderRadius.lg,
    border: `0.5px solid ${grey[600]}`,
    ...(size === "small" && { fontSize: fontSize.xs, padding: `${pxToRem(4)} ${pxToRem(12)}` }),
    ...(size === "medium" && { padding: `${pxToRem(8)} ${pxToRem(12)}` }),
    ...(size === "large" && { padding: pxToRem(12) }),
    "&.Mui-focused": {
      borderColor: inputColors.borderColor.focus,
      boxShadow: boxShadow([0, 0], [0, 2], inputColors.boxShadow, 1),
      outline: 0,
    },
    "&.MuiInputBase-multiline": {
      padding: `${pxToRem(10)} ${pxToRem(12)}`,
    },
    "& ::placeholder": {
      color: grey[500], // Ensure placeholder text is visible
    },
  };
});


import { Card, Switch } from "@mui/material";
import HitchDataTable from "components/HitchDataTable";
import HitchTitle from "components/HitchTitle";
import VuiBox from "components/VuiBox";
import { useHubConnections } from "context/HubConnectionsProvider";
import { useEffect, useState } from "react";

function HitchFeatureSelector({ serviceId }) {
    const { dashboardConnection, hitchDashCommand, hitchUser } = useHubConnections();
    const [featureList, setFeatureList] = useState([]);
    const [connection, setConnection] = useState({});

    const cols = [
        { Header: "Active", accessor: "isActive", width: "30%" },
        { Header: "Feature", accessor: "name", align: "start", width: "70%" },
    ];

    const [dataTableData, setDataTableData] = useState({
        columns: cols,
        rows: [],
    });

    useEffect(() => {
        if (featureList) {
            console.log('featureList', featureList);
            setDataTableData((prevState) => ({
                ...prevState,
                rows: featureList.map((feature) => ({
                    ...feature,
                    isActive: (
                        <Switch
                            checked={feature.isActive}
                            onChange={() => handleToggleFeature(feature)}
                            color="primary"
                        />
                    ),
                })),
            }));
        }
    }, [featureList]);

    useEffect(() => {
        if (hitchUser && dashboardConnection && serviceId) {
            dashboardConnection.on("45b5af16-dbca-4776-8567-24d7eac45383", incomingFeatures);
            dashboardConnection.on("ee7b5c3b-3359-4870-8a12-31e3b03901d8", incomingConnection);
            dashboardConnection.on("1d7743d0-7ca6-478c-a0b2-4b93a0c9f7a5", updateAlias);

            hitchDashCommand("account", "connection", "get", {
                theAccountId: hitchUser?.accountId,
                theServiceId: serviceId,
            });

            return () => {
                dashboardConnection.off("45b5af16-dbca-4776-8567-24d7eac45383", incomingFeatures);
                dashboardConnection.off("ee7b5c3b-3359-4870-8a12-31e3b03901d8", incomingConnection);
                dashboardConnection.off("1d7743d0-7ca6-478c-a0b2-4b93a0c9f7a5", updateAlias);
            };
        }
    }, [dashboardConnection, serviceId, hitchUser]);

    useEffect(() => {
        if (connection) {
            hitchDashCommand("account", "connection", "features", {
                theConnectionId: connection.id,
            });
        }
    }, [connection]);

    const handleToggleFeature = (feature) => {
        // Toggle the `isActive` property locally
        const updatedFeatureList = featureList.map((f) =>
            f.id === feature.id ? { ...f, isActive: !f.isActive } : f
        );
        setFeatureList(updatedFeatureList);

        // Send the updated state to the server
        hitchDashCommand("account", "connection", "update-alias", {
            theAliasId: feature.id,
            isActive: !feature.isActive,
        });
    };

    function incomingFeatures(data) {
        setFeatureList(data);
    }

    function incomingConnection(data) {
        setConnection(data);
    }

    function updateAlias(data) {
        console.log('updateAlias', data);
    }

    return (
        <VuiBox pt={6} pb={3}>
            <VuiBox mb={3}>
                <Card>
                    <HitchTitle
                        title={`${connection?.name} Features`}
                        subTitle={`Active Features`}
                    />
                    <HitchDataTable
                        table={dataTableData}
                        entriesPerPage={false}
                        showTotalEntries={false}
                        isSorted={false}
                        renderRow={(row) => (
                            <div key={row.key}>
                                {row.name}
                                {row.avatar}
                            </div>
                        )}
                    />
                </Card>
            </VuiBox>
        </VuiBox>
    );
}

export default HitchFeatureSelector;

import { ExpandMoreTwoTone } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import HitchTypography from "components/HitchTypography";

function HitchAccordion({children, contentId, id, title}) {

    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreTwoTone />}
                aria-controls={contentId}
                id={id}
                sx={{ backgroundColor: '#333333' }} // Header background color
            >
                <HitchTypography component="span">{title}</HitchTypography>
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: '#111111' }}> {/* Body background color */}
                {children}
            </AccordionDetails>
        </Accordion>
    );
}

export default HitchAccordion;
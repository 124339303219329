
// FILE: /UI/hitch-ai-ui-2/src/components/VuiBadge/index.js

import { forwardRef } from "react";
import PropTypes from "prop-types";
import VuiBadgeRoot from "components/VuiBadge/VuiBadgeRoot";

const VuiBadge = forwardRef(
  (
    { 
      color = "info", 
      variant = "gradient", 
      size = "sm", 
      circular = false, 
      indicator = false, 
      border = false, 
      container = false, 
      children = false, 
      ...rest 
    }, ref) => (
    <VuiBadgeRoot
      {...rest}
      ownerState={{ color, variant, size, circular, indicator, border, container, children }}
      ref={ref}
      color="default"
    >
      {children}
    </VuiBadgeRoot>
  )
);

// Typechecking props of the VuiBadge
VuiBadge.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "text",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  variant: PropTypes.oneOf(["gradient", "contained"]),
  size: PropTypes.oneOf(["xs", "sm", "md", "lg"]),
  circular: PropTypes.bool,
  indicator: PropTypes.bool,
  border: PropTypes.bool,
  children: PropTypes.node,
  container: PropTypes.bool,
};

export default VuiBadge;

// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/components/HitchDataTableExternalPagination/index.js


import React, { useMemo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from "react-table";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import VuiBox from "components/VuiBox";
import HitchTypography from "components/HitchTypography";
import VuiPagination from "components/VuiPagination";
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";

function HitchDataTableExternalPagination({
  entriesPerPage = { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
  canSearch = false,
  showTotalEntries = true,
  table,
  pagination = { variant: "gradient", color: "info" },
  isSorted = true,
  noEndBorder = false,
  onRowClick,
  page: externalPage = 1,
  perPage: externalPerPage = 10,
  totalResults = 0,
  onPageChange,
}) {
  const [search, setSearch] = useState("");
  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: externalPage - 1, pageSize: externalPerPage },
      manualPagination: true, // Always manual for external pagination
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    state: { pageSize },
  } = tableInstance;

  const entriesStart = (externalPage - 1) * externalPerPage + 1;
  const entriesEnd = Math.min(entriesStart + externalPerPage - 1, totalResults);
  const totalPages = Math.ceil(totalResults / externalPerPage);

  const onSearchChange = useAsyncDebounce((value) => {
    setSearch(value);
  }, 100);

  return (
    <TableContainer sx={{ boxShadow: "none", maxHeight: "600px", overflowY: "auto" }}>
      {canSearch && (
        <VuiBox
          display="flex"
          p={3}
          pl={0}
          sx={({ breakpoints }) => ({
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          })}
        >
          <VuiBox width="12rem">
            <input
              type="text"
              placeholder="Search..."
              value={search}
              onChange={({ target }) => onSearchChange(target.value)}
              style={{ width: "100%", padding: "8px" }}
            />
          </VuiBox>
        </VuiBox>
      )}
      <Table {...getTableProps()}>
        <VuiBox component="thead">
          {headerGroups.map((headerGroup, index) => (
            <TableRow {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, cindex) => {
                const { key, ...props } = column.getHeaderProps(isSorted && column.getSortByToggleProps());
                return (
                  <DataTableHeadCell
                    key={key}
                    {...props}
                    width={column.width || "auto"}
                    align={column.align || "left"}
                  >
                    {column.render("Header")}
                  </DataTableHeadCell>
                );
              })}
            </TableRow>
          ))}
        </VuiBox>

        <TableBody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row);
            return (
              <TableRow
                {...row.getRowProps()}
                onClick={() => onRowClick && onRowClick(row.original)}
                sx={{ cursor: onRowClick ? "pointer" : "default" }}
                key={index}
              >
                {row.cells.map((cell, index) => (
                  <DataTableBodyCell
                    {...cell.getCellProps()}
                    noBorder={noEndBorder && rows.length - 1 === row.index}
                    align={cell.column.align || "left"}
                    key={cell?.column?.id ?? index}
                  >
                    {cell.render("Cell")}
                  </DataTableBodyCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <VuiBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={3}
      >
        {showTotalEntries && (
          <HitchTypography variant="button" color="white" fontWeight="regular">
            Showing {entriesStart} to {entriesEnd} of {totalResults} entries
          </HitchTypography>
        )}
        {totalPages > 1 && (
          <VuiPagination variant={pagination.variant || "gradient"} color={pagination.color || "info"}>
            {externalPage > 1 && (
              <VuiPagination item onClick={() => onPageChange(externalPage - 1, externalPerPage)}>
                <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
              </VuiPagination>
            )}
            {[...Array(totalPages)].map((_, index) => (
              <VuiPagination
                item
                key={index}
                onClick={() => onPageChange(index + 1, externalPerPage)}
                active={index + 1 === externalPage}
              >
                {index + 1}
              </VuiPagination>
            ))}
            {externalPage < totalPages && (
              <VuiPagination item onClick={() => onPageChange(externalPage + 1, externalPerPage)}>
                <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
              </VuiPagination>
            )}
          </VuiPagination>
        )}
      </VuiBox>
    </TableContainer>
  );
}

HitchDataTableExternalPagination.propTypes = {
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.shape({
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark", "light"]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
  onRowClick: PropTypes.func,
  page: PropTypes.number,
  perPage: PropTypes.number,
  totalResults: PropTypes.number,
  onPageChange: PropTypes.func.isRequired,
};

export default HitchDataTableExternalPagination;
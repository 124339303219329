
// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/components/HitchTextArea/index.js

import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import HitchTextAreaRoot from 'components/HitchTextArea/HitchTextAreaRoot';
import HitchTextAreaWithIconRoot from 'components/HitchTextArea/HitchTextAreaWithIconRoot';
import HitchTextAreaIconBoxRoot from 'components/HitchTextArea/HitchTextAreaIconBoxRoot';
import HitchTextAreaIconRoot from 'components/HitchTextArea/HitchTextAreaIconRoot';
import { useHitchAIUIController } from 'context';
import HitchInputHelperText from 'components/HitchInputHelperText';

const HitchTextArea = forwardRef(
  ({ size = 'medium', icon = { component: false, direction: 'none' }, error = false, success = false, disabled = false, helperText = '', fullWidth = false, ...rest }, ref) => {
    let template;
    const [controller] = useHitchAIUIController();
    const { direction } = controller;
    const iconDirection = icon.direction;

    if (icon.component && icon.direction === 'left') {
      template = (
        <HitchTextAreaWithIconRoot ref={ref} ownerState={{ error, success, disabled, fullWidth }}>
          <HitchTextAreaIconBoxRoot ownerState={{ size }}>
            <HitchTextAreaIconRoot fontSize="small" ownerState={{ size }}>
              {icon.component}
            </HitchTextAreaIconRoot>
          </HitchTextAreaIconBoxRoot>
          <HitchTextAreaRoot {...rest} multiline ownerState={{ size, error, success, iconDirection, direction, disabled, fullWidth }} />
          {helperText && <HitchInputHelperText helperText={helperText} />}
        </HitchTextAreaWithIconRoot>
      );
    } else if (icon.component && icon.direction === 'right') {
      template = (
        <HitchTextAreaWithIconRoot ref={ref} ownerState={{ error, success, disabled, fullWidth }}>
          <HitchTextAreaRoot {...rest} multiline ownerState={{ size, error, success, iconDirection, direction, disabled, fullWidth }} />
          <HitchTextAreaIconBoxRoot ownerState={{ size }}>
            <HitchTextAreaIconRoot fontSize="small" ownerState={{ size }}>
              {icon.component}
            </HitchTextAreaIconRoot>
          </HitchTextAreaIconBoxRoot>
          {helperText && <HitchInputHelperText helperText={helperText} />}
        </HitchTextAreaWithIconRoot>
      );
    } else {
      template = (
        <div>
          <HitchTextAreaRoot {...rest} ref={ref} multiline ownerState={{ size, error, success, disabled, fullWidth }} />
          {helperText && <HitchInputHelperText helperText={helperText} />}
        </div>
      );
    }

    return template;
  }
);

HitchTextArea.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  icon: PropTypes.shape({
    component: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
    direction: PropTypes.oneOf(['none', 'left', 'right']),
  }),
  error: PropTypes.bool,
  success: PropTypes.bool,
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
  fullWidth: PropTypes.bool,
};

export default HitchTextArea;


import { Grid, ListItem, Typography, FormControl, FormControlLabel, TextField, Switch, Box, IconButton, Card, Avatar } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from 'react';
import { faSlash, faSignal } from "@fortawesome/free-solid-svg-icons";
import { camelCase } from 'lodash';
import VuiBox from "components/VuiBox";
import HitchTypography from "components/HitchTypography";
import VuiInput from "components/VuiInput";
import StoreOperationManager from "./StoreOperationManager";
import VuiButton from "components/VuiButton";
import { useHubConnections } from "context/HubConnectionsProvider";
import { useParams } from "react-router-dom";

function GitHubFileSource({ selectedSource, setSelectedSource }) {

    const { repoId } = useParams();

    const [formData, setFormData] = useState({
        id: "3e9b91cc-041a-430b-9b73-9fa795d1f56a",
        accountId: "b287f0f3-cf3e-4e7b-94b1-296dfe0b1f6f",
        created: "2024-05-05T04:51:33.4997037Z",
        createdBy: "eb2d7430-040a-4562-bf92-40990b19ae50",
        name: "Untitled store",
        updated: "2024-05-05T04:51:33.4997037Z",
        updatedBy: "eb2d7430-040a-4562-bf92-40990b19ae50",
        entity: "RepoSource",
        fileType: "files",
        foreignKeyContainer: "",
        pushToSource: true,
        putFileOperation: "f9843b26-7060-4c5f-8aa7-059f28204dd2",
        deleteFileOperation: "aadc4af4-84ae-4cfa-a34f-c703e7f97e14",
        putMetadataOperation: "a0713302-5b2e-47d6-a85c-26858f06c21f",
        deleteMetadataOperation: "5302ded9-602e-4f21-a4d2-42caf22ccc82",
        pullFromSource: false,
        listOperation: null,
        getOperation: null,
        repositoryId: "f4eac604-c688-4131-a03b-7a8bcce28bcc",
    });

    const [connectedOperations, setConnectedOperations] = useState({
        put: false,
        delete: false,
        putMetadata: false,
        deleteMetadata: false,
        list: false,
        get: false,
    });

    const { dashboardConnection, hitchDashCommand } = useHubConnections();

    const [openSelector, setOpenSelector] = useState(false);
    const [currentOperation, setCurrentOperation] = useState('');

    const handleToggleSwitch = (key) => {
        setFormData(prev => ({ ...prev, [key]: !prev[key] }));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleOperationClick = (operation) => {
        // console.log(`${operation} clicked`);
        setCurrentOperation(operation);
        setOpenSelector(true);
        // Implement the logic for the operation
    };

    const camelCaseOperation = (service, feature, operation) => {
        return camelCase(`${service}_${feature}_${operation}`);
    };

    function onSelectedOperation(service, feature, operation) {
        let selection = camelCaseOperation(service, feature, operation);
        // console.log('onSelectedOperation', selection);
        setFormData(prev => ({ ...prev, [currentOperation]: selection }));
        setOpenSelector(false);
    }

    function handleDisconnect(operation) {
        setFormData((prev) => ({ ...prev, [operation]: null }));
    }

    useEffect(() => {
        if (selectedSource) {
            console.log('HA!', selectedSource);
            setFormData(selectedSource);
        }
    }, [selectedSource]);

    function handleSave() {
        if (dashboardConnection) {
            formData.repositoryId = repoId;

            console.log('formData', formData);
            if (selectedSource) {
                hitchDashCommand('document', 'repo-source', 'update', { formData: formData }, () => { });
            } else {
                hitchDashCommand('document', 'repo-source', 'create', { formData: formData }, () => { });
            }
        }
    }

    function doTheDelete() {
        hitchDashCommand('document', 'repo-source', 'delete', { repositoryId: formData.repositoryId, sourceId: formData.id }, () => { });
    }

    function handleCancel() {
        setSelectedSource(null);
        hitchDashCommand('document', 'repo-source', 'list', { RepositoryId: repoId }, () => { });
    }


    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Card sx={{ p: 3 }}>
                    <Grid container alignItems="center">
                        <Avatar src={`${window._env_.HITCH_API}/api/accimage/e59d1ba9-5b71-4f5a-9205-9ce82cbf2dfc.png`} />
                        <HitchTypography variant="h6" sx={{ ml: 2, flexGrow: 1 }}>
                            GitHub Source
                        </HitchTypography>
                        {/* <VuiButton onClick={handlePushNow} color="primary" sx={{ ml: 'auto' }}>
                            Push Now
                            </VuiButton> */}
                    </Grid>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card sx={{ p: 3 }}>
                    <VuiBox mb={2}>
                        <HitchTypography variant="caption" color="white">
                            Id
                        </HitchTypography>
                        <VuiInput
                            name="id"
                            value={formData.id}
                            onChange={handleInputChange}
                            fullWidth
                            readOnly
                        />
                    </VuiBox>
                    <VuiBox mb={2}>
                        <HitchTypography variant="caption" color="white">
                            Name
                        </HitchTypography>
                        <VuiInput
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </VuiBox>
                    <VuiBox mb={2}>
                        <HitchTypography variant="caption" color="white">
                            Foreign Key Container
                        </HitchTypography>
                        <VuiInput
                            name="foreignKeyContainer"
                            value={formData.foreignKeyContainer}
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </VuiBox>
                </Card>

            </Grid>

            <Grid item xs={12}>
                <Card sx={{ p: 3 }}>
                    <StoreOperationManager
                        serviceName="GitHub"
                        connectedOperations={connectedOperations}
                        formData={formData}
                        handleOperationClick={handleOperationClick}
                        handleToggleSwitch={handleToggleSwitch}
                        handleDisconnect={handleDisconnect}
                    />
                </Card>
            </Grid>

            <Grid item xs={12}>
                <Card sx={{ p: 3 }}>
                    <VuiBox display="flex" justifyContent="flex-end" gap={2}>
                        <VuiButton onClick={handleSave} variant="contained" color="primary">
                            Save
                        </VuiButton>
                        <VuiButton onClick={handleCancel} color="primary">
                            Cancel
                        </VuiButton>
                        {selectedSource && (
                            <VuiButton onClick={doTheDelete} color="error">
                                Delete
                            </VuiButton>
                        )}
                    </VuiBox>
                </Card>
            </Grid>

        </Grid>
    );
}

export default GitHubFileSource;

import Card from "@mui/material/Card";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import HitchTypography from "components/HitchTypography";
import VuiButton from "components/VuiButton";

// Images
import image from "assets/images/product-page.png";
function ProductImage() {
  return (
    <Card sx={{ height: "100%", p: "10px" }}>
      <VuiBox p={3}>
        <HitchTypography variant="h5" fontWeight="bold" color="white">
          Product Image
        </HitchTypography>
        <VuiBox
          component="img"
          src={image}
          alt="Product Image"
          borderRadius="lg"
          shadow="lg"
          width="100%"
          my={3}
        />
        <VuiBox display="flex">
          <VuiButton variant="contained" color="info" size="small" sx={{ mr: "8px" }}>
            edit
          </VuiButton>
          <VuiButton variant="outlined" color="white" size="small">
            remove
          </VuiButton>
        </VuiBox>
      </VuiBox>
    </Card>
  );
}

export default ProductImage;

// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/components/HitchBadgeList/index.js


import React from "react";
import VuiBadge from "components/VuiBadge";
import VuiBox from "components/VuiBox";

function HitchBadgeList({ value }) {
    return (
        <VuiBox display="flex" flexWrap="wrap" gap={1}>
            {value.split(",").map((role, index) => (
                <VuiBadge
                    key={index}
                    variant="contained"
                    size="sm"
                    badgeContent={role.trim()}
                    circular
                    container
                />
            ))}
        </VuiBox>
    );
}

export default HitchBadgeList;
